import {Settings} from 'settings/utils';
import {MetricDirection, MetricsByName} from 'toolkit/metrics/types';
import {
  BACKEND_DEFAULT_DIRECTION,
  DEFAULT_DIRECTION,
  filterMetricArguments,
  getDefaultMetricInstanceArgs,
  getDirection,
  getEffectiveComparisonMetrics,
  getEffectiveMetric,
  getMetricsList,
  getPerspective,
  getPerspectiveMetric,
  getStatisticalAggregator,
  getSupportedDollarTypes,
  inOrOutFlowHasPlanArg,
  isEdgeMetric,
  isFlowOrStock,
  isForecastMetric,
  metricListToMetricInstance,
  populateMetricArguments,
  replaceInvalidArgs,
} from 'toolkit/metrics/utils';
import * as Types from 'types';
import {first, last} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {isNonNullish, isTruthy} from 'utils/functions';
import {without} from 'utils/objects';
import {areEquivalentPeriods} from 'widgets/utils';

import {getIntermediateComparisonMetrics} from './comparison-metrics';
import {ComparisonMetricType, MetricInstanceFlags, MetricInstanceProposedData} from './types';

function getMetricFromComparisonMetricType(comparisonMetricType: ComparisonMetricType): string {
  switch (comparisonMetricType) {
    case ComparisonMetricType.ABSOLUTE:
      return 'metric_comps_absolute';
    case ComparisonMetricType.CONTRIBUTION:
      return 'contribution';
    case ComparisonMetricType.PERCENT:
      return 'metric_comps_percent';
    case ComparisonMetricType.NONE:
      throw new Error('No metric for comparison type NONE');
  }
}

function getMetrics(
  availableMetrics: MetricsByName,
  metric: Types.Metric,
  flags: MetricInstanceFlags,
  possibleOptions: MetricInstanceFlags,
  requiredOptions: MetricInstanceFlags,
  statisticalAggregator: string,
  perspective: Types.GraphPerspective,
  direction: MetricDirection
) {
  const getMetricIfFlagSet = (flagName: keyof MetricInstanceFlags, metricName: string) =>
    requiredOptions[flagName] || (flags[flagName] && possibleOptions[flagName])
      ? availableMetrics.get(metricName)
      : null;
  return [
    // Order matters
    getPerspectiveMetric(perspective, availableMetrics),
    flags.compsMetricType === ComparisonMetricType.NONE
      ? null
      : availableMetrics.get(getMetricFromComparisonMetricType(flags.compsMetricType)),
    getMetricIfFlagSet('showAsPercentOfTotal', 'percent_of_total'),
    getMetricIfFlagSet('showAsPercentOfGrouping', 'percent_of_grouping'),
    getMetricIfFlagSet('useGranularity', statisticalAggregator),
    getMetricIfFlagSet('useTimeShift', 'metric_comps_value'),
    getMetricIfFlagSet('showCumulative', 'cumulative'),
    getMetricIfFlagSet('showPerLocation', 'per_store'),
    getMetricIfFlagSet('showErrorMetric', 'forecast_error'),
    getMetricIfFlagSet('useUnitConversion', 'unit_conversion'),
    metric.features.includes(Types.MetricFeature.IS_EDGE) &&
      direction !== BACKEND_DEFAULT_DIRECTION &&
      availableMetrics.get(direction),
    metric,
  ].filter(isTruthy);
}

export function hasMetric(metricInstance: Types.MetricInstance, metricName: string) {
  return !!getMetricsList(metricInstance).find(metric => metric.name === metricName);
}

function getCompsMetricType(metricInstance: Types.MetricInstance): ComparisonMetricType {
  const metricNamesList = getMetricsList(metricInstance).map(metric => metric.name);
  if (metricNamesList.includes('metric_comps_absolute')) {
    return ComparisonMetricType.ABSOLUTE;
  }
  if (metricNamesList.includes('metric_comps_percent')) {
    return ComparisonMetricType.PERCENT;
  }
  if (metricNamesList.includes('contribution')) {
    return ComparisonMetricType.CONTRIBUTION;
  }
  return ComparisonMetricType.NONE;
}

export function getFlagsFromMetric(metric: Types.MetricInstance): MetricInstanceFlags {
  const showErrorMetric = hasMetric(metric, 'forecast_error');
  return {
    compsMetricType: getCompsMetricType(metric),
    showAsPercentOfGrouping: hasMetric(metric, 'percent_of_grouping'),
    showAsPercentOfTotal: hasMetric(metric, 'percent_of_total'),
    showCumulative: hasMetric(metric, 'cumulative'),
    showPerLocation: hasMetric(metric, 'per_store'),
    showErrorMetric,
    useGranularity: metric && !!metric.arguments.granularity && !showErrorMetric,
    useUnitConversion: hasMetric(metric, 'unit_conversion'),
    useTimeShift: hasMetric(metric, 'metric_comps_value'),
  };
}

export function isMetricUnitConvertable(metric: Types.Metric, flags: MetricInstanceFlags) {
  return (
    (metric.features.includes(Types.MetricFeature.IS_UNIT_METRIC) ||
      metric.requiredArguments.includes(Types.MetricArgument.inventoryMeasure)) &&
    !flags.showCumulative
  );
}

export function getPossibleConfigOptions(
  metric: Types.Metric,
  flags: MetricInstanceFlags,
  analysisSettings: Types.VendorAnalysisSettings
): MetricInstanceFlags {
  const flowOrStock = isFlowOrStock(metric);
  return {
    compsMetricType: flags.compsMetricType,
    showAsPercentOfGrouping: flowOrStock && !flags.showAsPercentOfTotal && !flags.showCumulative,
    showAsPercentOfTotal: flowOrStock && !flags.showAsPercentOfGrouping && !flags.showCumulative,
    showCumulative:
      metric.type === 'FLOW' &&
      !flags.showAsPercentOfGrouping &&
      !flags.showAsPercentOfTotal &&
      !flags.useUnitConversion,
    showErrorMetric:
      !flags.showCumulative &&
      !flags.useGranularity &&
      !!isForecastMetric(metric) &&
      metric.forecastedMetrics.length > 0,
    showPerLocation: flowOrStock,
    useGranularity: !flags.showCumulative && !flags.showErrorMetric,
    useUnitConversion:
      isMetricUnitConvertable(metric, flags) &&
      analysisSettings.unitConversionAttributes.length > 0,
    useTimeShift: flags.useTimeShift,
  };
}

export function getRequiredConfigOptions(metric: Types.Metric): MetricInstanceFlags {
  return {
    compsMetricType: ComparisonMetricType.NONE,
    showAsPercentOfGrouping: false,
    showAsPercentOfTotal: false,
    showCumulative: false,
    showErrorMetric: false,
    showPerLocation: false,
    useGranularity: metric.requiredArguments.includes(Types.MetricArgument.granularity),
    useUnitConversion: false,
    useTimeShift: false,
  };
}

export function getModifiedMetricInstance(
  existingMetric: Types.MetricInstance,
  proposedData: MetricInstanceProposedData,
  settings: Settings,
  availableMetrics: MetricsByName,
  currency: Types.CurrencyCode
): Types.MetricInstance {
  const effectiveMetric = assertTruthy(
    proposedData.effectiveMetric ?? getEffectiveMetric(existingMetric)
  );
  const flags = proposedData.flags || getFlagsFromMetric(existingMetric);
  const statisticalAggregator =
    proposedData.statisticalAggregator || getStatisticalAggregator(existingMetric);
  const perspective = proposedData.perspective || getPerspective(existingMetric);
  const direction: MetricDirection =
    proposedData.direction ||
    // Keep direction only when changing between 2 edge metrics.
    (isEdgeMetric(getEffectiveMetric(existingMetric))
      ? getDirection(existingMetric)
      : DEFAULT_DIRECTION);
  const instanceArguments = filterMetricArguments(
    proposedData.instanceArguments || existingMetric.arguments,
    (_, value) => value !== null
  );

  const possibleOptions = getPossibleConfigOptions(
    effectiveMetric,
    flags,
    settings.analysisSettings
  );
  const requiredOptions = getRequiredConfigOptions(effectiveMetric);
  const metrics = getMetrics(
    availableMetrics,
    effectiveMetric,
    flags,
    possibleOptions,
    requiredOptions,
    statisticalAggregator,
    perspective,
    direction
  );
  const metric = last(metrics);
  const isHigherOrderMetric = metrics.length > 1;

  const isComparisonMetricInstance = flags.compsMetricType !== ComparisonMetricType.NONE;
  const isTimeShiftedComparisonMetric = !!(
    isComparisonMetricInstance &&
    instanceArguments.comparisonMetrics &&
    instanceArguments.comparisonMetrics[1].arguments.timeAgo
  );

  const isTimeShifted = flags.useTimeShift || isTimeShiftedComparisonMetric;

  const filterNotApplicableArguments = (arg: Types.MetricArgument) =>
    metric.requiredArguments.includes(arg) ||
    metric.optionalArguments.includes(arg) ||
    (isHigherOrderMetric && arg === Types.MetricArgument.childMetrics) ||
    (isTimeShifted &&
      (arg === Types.MetricArgument.timeAgo ||
        arg === Types.MetricArgument.isTimeAgoCalendarPeriodAligned)) ||
    (isComparisonMetricInstance && arg === Types.MetricArgument.comparisonMetrics) ||
    (flags.useGranularity && arg === Types.MetricArgument.granularity) ||
    (flags.useUnitConversion && arg === Types.MetricArgument.unitConversionAttribute) ||
    (flags.showPerLocation && arg === Types.MetricArgument.storeType) ||
    (flags.showErrorMetric &&
      (arg === Types.MetricArgument.errorMetric ||
        arg === Types.MetricArgument.granularity ||
        arg === Types.MetricArgument.groupings));

  const supportedDollarTypes = getSupportedDollarTypes(metric, direction);
  const dollarType =
    metric.requiredArguments.includes(Types.MetricArgument.dollarType) &&
    isNonNullish(instanceArguments.dollarType) &&
    supportedDollarTypes.includes(instanceArguments.dollarType)
      ? instanceArguments.dollarType
      : first(supportedDollarTypes);

  const unfilteredArgs: Types.MetricArguments = {
    ...getDefaultMetricInstanceArgs(
      settings,
      metrics[0],
      assertTruthy(existingMetric.arguments.period),
      currency,
      direction
    ),
    ...instanceArguments,
    timeAgo: instanceArguments.timeAgo ?? settings.analysisSettings.timeAgo,
    isTimeAgoCalendarPeriodAligned:
      (instanceArguments.timeAgo?.unit === 'YEARS' &&
        instanceArguments.isTimeAgoCalendarPeriodAligned) ??
      false,
    // if the current dollar type isn't supported, select the first supported type instead
    dollarType,
  };

  const args = replaceInvalidArgs(
    effectiveMetric,
    filterMetricArguments(
      {
        ...unfilteredArgs,
      },
      filterNotApplicableArguments
    ),
    settings.analysisSettings
  );

  const proposedMetric = metricListToMetricInstance(metrics, args);
  const updatedProposedMetric = updateArgumentsForComparisonMetricInstances(proposedMetric);
  return updateArgumentsForForecastType(updatedProposedMetric);
}

function updateArgumentsForForecastType(
  metricInstance: Types.MetricInstance
): Types.MetricInstance {
  const metric = assertTruthy(getEffectiveMetric(metricInstance));
  const forecastType: Types.ForecastType | null = metricInstance.arguments.forecastType;

  // When a plan's forecast type is annual growth, there are certain metrics
  // where a particular variant is not supposed in planning: ie forecast_outbound_shipped_units
  // is not in planning while forecast_inbound_shipped_units is. There's currently no good way
  // of distinguishing this on the frontend, so just also keep growth factor if the plan forecast type
  const shouldKeepGrowthFactor =
    forecastType === Types.ForecastType.ANNUAL_GROWTH || forecastType === Types.ForecastType.PLAN;

  return {
    ...metricInstance,
    arguments: {
      ...metricInstance.arguments,
      growthFactor: shouldKeepGrowthFactor ? metricInstance.arguments.growthFactor : null,
      historicalPeriod:
        forecastType === Types.ForecastType.HISTORICAL_AVERAGE ||
        metric.requiredArguments.includes(Types.MetricArgument.historicalPeriod) ||
        metric.optionalArguments.includes(Types.MetricArgument.historicalPeriod)
          ? metricInstance.arguments.historicalPeriod || null
          : null,
      versionRecency: metricInstance.arguments.versionRecency || null,
    },
  };
}

export function updateArgumentsForComparisonMetricInstances(
  metricInstance: Types.MetricInstance
): Types.MetricInstance {
  const metricArguments = populateMetricArguments(metricInstance.arguments);
  if (!metricArguments.comparisonMetrics) {
    return metricInstance;
  }

  const baseMetricInstance = first(metricArguments.comparisonMetrics);
  const comparedToMetricInstance = last(metricArguments.comparisonMetrics);

  const proposedBaseMetrics = [
    ...getIntermediateComparisonMetrics(metricInstance, baseMetricInstance),
    ...getEffectiveComparisonMetrics(baseMetricInstance),
  ];

  const proposedComparisonArguments: Types.MetricArguments = {
    ...baseMetricInstance.arguments,
    ...without(metricArguments, [
      'comparisonMetrics',
      'childMetrics',
      'timeAgo',
      'isTimeAgoCalendarPeriodAligned',
    ]),
  };

  const proposedBaseInstance = updateArgumentsForForecastType(
    metricListToMetricInstance(proposedBaseMetrics, proposedComparisonArguments)
  );

  const proposedComparedToInstance = updateArgumentsForComparedToMetricInstance(
    metricInstance,
    proposedBaseInstance,
    comparedToMetricInstance
  );

  return {
    ...metricInstance,
    arguments: {
      ...metricArguments,
      comparisonMetrics: [proposedBaseInstance, proposedComparedToInstance],
    },
  };
}

export function updateArgumentsForComparedToMetricInstance(
  parentMetricInstance: Types.MetricInstance,
  baseMetricInstance: Types.MetricInstance,
  comparedToMetricInstance: Types.MetricInstance
): Types.MetricInstance {
  const metricArguments = populateMetricArguments(parentMetricInstance.arguments);

  const baseAndComparisonPeriodsAreEqual = areEquivalentPeriods(
    baseMetricInstance.arguments.period!,
    comparedToMetricInstance.arguments.period!
  );
  const proposedComparedToMetrics = [
    ...getIntermediateComparisonMetrics(parentMetricInstance, comparedToMetricInstance),
    ...getEffectiveComparisonMetrics(comparedToMetricInstance),
  ];
  const compareToIsUnitConverted = proposedComparedToMetrics.some(
    metric => metric.name === 'unit_conversion'
  );
  const proposedComparedToArguments: Types.MetricArguments = {
    ...comparedToMetricInstance.arguments,
    // Copy over all metric arguments from the base metric to the comparison metric.
    // Leave out the arguments that we either populate directly below or allow configuring
    // in the compared to metric instance directly (see ComparisonMetricInstanceEditorPopover).
    ...without(metricArguments, [
      'comparisonMetrics',
      'childMetrics',
      'period',
      'timeAgo',
      'isTimeAgoCalendarPeriodAligned',
      'forecastType',
      'forecastComposition',
      'growthFactor',
      'historicalPeriod',
      'versionRecency',
      'stockAggregator',
      'unitConversionAttribute',
      'supplyTargetPeriod',
      'inventoryTypes',
      'inflowTypes',
      'outflowTypes',
    ]),
    period: baseAndComparisonPeriodsAreEqual
      ? baseMetricInstance.arguments.period
      : comparedToMetricInstance.arguments.period,
    // If the base metric has planned inflow or outflow types (for simulated metrics),
    // then we need to keep the forecast type (PLAN) in sync between the two since it
    // cannot be changed
    forecastType: inOrOutFlowHasPlanArg(metricArguments.inflowTypes, metricArguments.outflowTypes)
      ? metricArguments.forecastType
      : (comparedToMetricInstance.arguments.forecastType ?? null),
    dollarType: comparedToMetricInstance.arguments.dollarType || metricArguments.dollarType,
    // If the base metric only exists as a Unit metric and thus doesn't have the inventory measure,
    // we take the inventory measure from the comparison metric.
    inventoryMeasure:
      metricArguments.inventoryMeasure ||
      comparedToMetricInstance.arguments.inventoryMeasure ||
      null,
    unitConversionAttribute: compareToIsUnitConverted
      ? metricArguments.unitConversionAttribute ||
        comparedToMetricInstance.arguments.unitConversionAttribute
      : null,
  };
  return updateArgumentsForForecastType(
    metricListToMetricInstance(proposedComparedToMetrics, proposedComparedToArguments)
  );
}

export function getUnitConvertedMetric(
  settings: Settings,
  availableMetrics: MetricsByName,
  metricInstance: Types.MetricInstance,
  unitConversionAttribute: Types.Attribute | null = null,
  currency: Types.CurrencyCode
) {
  const metricFlags = getFlagsFromMetric(metricInstance);
  if (
    !isMetricUnitConvertable(assertTruthy(getEffectiveMetric(metricInstance)), metricFlags) ||
    (metricInstance.arguments.inventoryMeasure &&
      metricInstance.arguments.inventoryMeasure !== Types.InventoryMeasure.UNITS)
  ) {
    return metricInstance;
  }

  const proposedChanges: MetricInstanceProposedData = {
    flags: {
      ...metricFlags,
      useUnitConversion: !!unitConversionAttribute,
    },
    instanceArguments: {
      ...metricInstance.arguments,
      unitConversionAttribute,
    },
  };

  return getModifiedMetricInstance(
    metricInstance,
    proposedChanges,
    settings,
    availableMetrics,
    currency
  );
}

export function canSelectLag(forecastType: Types.ForecastType | null) {
  return forecastType !== Types.ForecastType.ANNUAL_GROWTH;
}

export enum FormattingRuleRangeValidationResultType {
  all_valid = 'all_valid',
  overlaps_other_ranges = 'overlaps_other_ranges',
  lower_bound_exceeds_upper = 'lower_bound_exceeds_upper',
}

export interface FormattingRuleRangeValidationResult {
  type: FormattingRuleRangeValidationResultType;
  issueIndices: readonly number[];
}

const lowerBoundExceedsUpper = (range: Types.Range) => {
  return (
    isNonNullish(range.lowerBound) &&
    isNonNullish(range.upperBound) &&
    range.lowerBound > range.upperBound
  );
};

export const validateFormattingRuleRanges = (
  rules?: Types.MetricFormattingRules | null
): FormattingRuleRangeValidationResult => {
  if (!isConditionalFormattingActive(rules)) {
    return {type: FormattingRuleRangeValidationResultType.all_valid, issueIndices: []};
  }
  if (rules.ranges.some(lowerBoundExceedsUpper)) {
    return {
      type: FormattingRuleRangeValidationResultType.lower_bound_exceeds_upper,
      issueIndices: rules.ranges.flatMap((range, index) =>
        lowerBoundExceedsUpper(range) ? [index] : []
      ),
    };
  }
  const overlappingIndices = rules.ranges.flatMap((range, index) =>
    rules.ranges.some(
      otherRange =>
        otherRange !== range &&
        (otherRange.lowerBound ?? Number.NEGATIVE_INFINITY) <=
          (range.upperBound ?? Number.POSITIVE_INFINITY) &&
        (otherRange.upperBound ?? Number.POSITIVE_INFINITY) >=
          (range.lowerBound ?? Number.NEGATIVE_INFINITY)
    )
      ? [index]
      : []
  );
  return {
    type:
      overlappingIndices.length > 0
        ? FormattingRuleRangeValidationResultType.overlaps_other_ranges
        : FormattingRuleRangeValidationResultType.all_valid,
    issueIndices: overlappingIndices,
  };
};

export const isConditionalFormattingActive = (
  rules: Types.MetricFormattingRules | null | undefined
): rules is Types.MetricFormattingRules => {
  return (
    isNonNullish(rules) &&
    rules.renderType !== Types.ConditionalMetricFormatRenderType.DEFAULT &&
    rules.ranges.length > 0
  );
};
