import equal from 'fast-deep-equal';
import {List, Map, Set} from 'immutable';

import * as Api from 'api';
import {AnalysisDataActions} from 'redux/actions/analysis';
import {Dispatch} from 'redux/reducers';
import {CurrentUser} from 'redux/reducers/user';
import {AttributesById} from 'toolkit/attributes/types';
import {capitalize} from 'toolkit/format/text';
import {dateGroupingGranularityOrder} from 'toolkit/time/utils';
import {isAtLeast, PUBLIC_VENDOR_ID} from 'toolkit/users/utils';
import {ThinAttributeInstance} from 'toolkit/views/types';
import * as Types from 'types';
import {allEnumValues} from 'types/utils';
import {invalidate, invalidateAllMatchingRequests} from 'utils/api';
import {ascendingBy, ChainableCompareFunction, descendingBy} from 'utils/arrays';
import {assertTruthy, assertNonNullish} from 'utils/assert';
import {isNonNullish} from 'utils/functions';

export const ATTRIBUTE_FILTER_VALUE_LIMIT = 10;
const DEFAULT_HIERARCHY_DISPLAY_NAME_REGEX = /Default \w+(\s\w+)* Hierarchy/;
// Use the same delimiter as pewter Attributes.CONCATENATED_IDENTIFIER_DELIMITER
export const CONCATENATED_IDENTIFIER_DELIMITER = '++';
// Use the same value as pewter Product.UNIT_OF_MEASURE_ATTRIBUTE
export const UNIT_OF_MEASURE_ATTRIBUTE = 'Pack Type';
export const LOADING_DISPLAY_VALUE = 'Loading...';
export const THIN_ATTRIBUTE_PLACEHOLDER_NAME = 'Loading...';
export const UNKNOWN_VALUE_ID = -1;
export const MULTIVALUE_ID = -2;

// TODO: Remove in sc-86307
const BUILTIN_EDITABLE_ATTRIBUTES = Set.of('Is Planned New Product');

// A list of the names of builtin untaggable product, location and transaction attributes
// This should match the static definitions in
// pewter/common/src/main/java/com/alloymetrics/lib/models/Attributes.java
export const BUILTIN_UNTAGGABLE_ATTRIBUTES = Set.of(
  'Product',
  'Alloy Product ID',
  'Alloy Product Identifier',
  'Default Product Hierarchy Placeholder',
  'Partner',
  'Location',
  'Alloy Location',
  'Alloy Location ID',
  'Alloy Location Identifier',
  'Default Location Hierarchy Placeholder',
  'Alloy Segment ID',
  'Alloy Segment'
);

export const EXPANDING_ATTRIBUTES = Set.of(
  'Default Product Hierarchy Placeholder',
  'Default Location Hierarchy Placeholder'
);

export const UNFILTERABLE_ATTRIBUTE_NAMES = Set.of(
  // These attributes are not useful for filtering purposes
  'Address1',
  'Latitude',
  'Longitude',
  'Street Number',
  'Street'
);

export const DISABLE_ATTRIBUTE_LINKS_FOR_ATTRIBUTES = Set.of(
  'On Time Delivery',
  'Transaction Status'
);

export const FILTERS_TO_REMOVE_FOR_ATTRIBUTE_LINKS = Map<Set<string>>({
  'Purchase Order Number': Set.of('Transaction Status'),
});

export type AttributeCategory = 'ALL' | Types.AttributeType;

export function getAttributeOptions(
  attributes: AttributesById,
  sortFunction?: ChainableCompareFunction<Types.Attribute>
) {
  const comparator = sortFunction ?? ascendingBy<Types.Attribute>(attr => attr.name.toLowerCase());
  return attributes.sort(comparator).valueSeq().toArray();
}

export function createAttributeInstance(
  attribute: Types.Attribute,
  graphContext?: Types.GraphContext | null
): Types.AttributeInstance;
export function createAttributeInstance(
  attribute: Types.Attribute | null | undefined,
  graphContext?: Types.GraphContext | null | undefined
): Types.AttributeInstance | null;
export function createAttributeInstance(
  attribute: null | undefined,
  graphContext?: Types.GraphContext | null | undefined
): null;
export function createAttributeInstance(
  attribute: Types.Attribute | null | undefined,
  graphContext: Types.GraphContext | null | undefined = null
): Types.AttributeInstance | null {
  return attribute ? {attribute, graphContext: graphContext ?? null} : null;
}

export function createAttributeInstances(attributesById: AttributesById) {
  return attributesById
    .valueSeq()
    .toArray()
    .map(attr => createAttributeInstance(attr));
}

export function createAttributeValue(
  attribute: Types.Attribute,
  id: number,
  displayValue: string | null
): Types.AttributeValue {
  return {
    attribute,
    derived: false,
    displayValue,
    id,
    value: id,
  };
}

function isDefaultHierarchy(attributeDisplayName: string) {
  return DEFAULT_HIERARCHY_DISPLAY_NAME_REGEX.test(attributeDisplayName);
}

export const compareAttributeDisplayNames = descendingBy(isDefaultHierarchy).thenAscendingBy(
  name => name
);

export function getAttribute(attributes: AttributesById, name: string): Types.Attribute | null {
  return attributes.filter(item => item.name === name).first() ?? null;
}

export function getAttributeDisplayName(attributeInstance: Types.AttributeInstance) {
  if (!attributeInstance.graphContext) {
    return attributeInstance.attribute.name;
  }
  const contextName = capitalize(attributeInstance.graphContext.toLowerCase());
  return `${contextName} ${attributeInstance.attribute.name}`;
}

export function getMappedAttributeName(
  attributeInstance: Types.AttributeInstance,
  attributeMappings: readonly Types.AttributeInstanceMapping[]
) {
  const mappedAttribute = attributeMappings.find(mappedAttribute =>
    isSameAttributeInstance(attributeInstance, mappedAttribute.attribute)
  );
  return mappedAttribute ? mappedAttribute.mappedValue : undefined;
}

export function getMappedGroupingAttributeName(
  groupingAttribute: Types.GroupingAttribute,
  attributeMappings: readonly Types.AttributeInstanceMapping[]
) {
  const mappedAttribute = attributeMappings.find(
    mappedAttribute =>
      groupingAttribute.attribute &&
      isSameAttributeInstance(getAttributeInstance(groupingAttribute), mappedAttribute.attribute)
  );
  return mappedAttribute ? mappedAttribute.mappedValue : undefined;
}

export function getAttributesOfType(allAttributes: AttributesById, type: string): AttributesById {
  return allAttributes.filter(attribute => attribute.type === type);
}

export function getAttributesOfTypes(
  allAttributes: AttributesById,
  types: readonly Types.AttributeType[]
): AttributesById {
  return allAttributes.filter(attribute => types.includes(attribute.type));
}

export function getAttributeValues(
  attribute: Types.Attribute,
  filters: readonly Types.AttributeFilter[]
): Promise<List<Types.AttributeValue>> {
  return Api.Attributes.getAttributeValues({
    attribute,
    filters,
  }).then((values: readonly Types.ThinAttributeValue[]) =>
    List(values).map(thinAttributeValue =>
      assertTruthy(fromThinValue(thinAttributeValue, attribute))
    )
  );
}

export function isBuiltInAttribute(attribute: Types.Attribute) {
  return (attribute.id ?? 1) <= 0;
}

export function getUnknownAttributeValue(attribute: Types.Attribute): Types.AttributeValue {
  return {
    attribute,
    derived: false,
    displayValue: `Unknown ${attribute.name}`,
    id: UNKNOWN_VALUE_ID,
    value: null,
  };
}

export function getAttributesById(attributes: List<Types.Attribute>) {
  return Map(
    attributes.map<[number, Types.Attribute]>(attribute => [assertTruthy(attribute.id), attribute])
  );
}

export const isSameThinAttrValue = (
  left: Types.ThinAttributeValue | null,
  right: Types.ThinAttributeValue | null
) => {
  if (left === right) {
    return true;
  }
  if (left?.displayValue !== right?.displayValue || left?.id !== right?.id) {
    return false;
  }
  return equal(left?.value, right?.value);
};

export const isSameAttribute = (
  left: Types.Attribute | null | undefined,
  right: Types.Attribute | null | undefined
) => {
  if (left === right) {
    return true;
  }
  return (
    equal(left?.id, right?.id) &&
    equal(left?.name, right?.name) &&
    equal(left?.vendorId, right?.vendorId)
  );
};

export const isSameAttributeInstance = (
  left: Types.AttributeInstance | null,
  right: Types.AttributeInstance | null
) => {
  if (left === right) {
    return true;
  }
  return (
    isSameAttribute(left?.attribute, right?.attribute) &&
    equal(left?.graphContext, right?.graphContext)
  );
};

export function fromThinValue(thinValue: null, attribute: Types.Attribute): null;
export function fromThinValue(
  thinValue: Types.ThinAttributeValue,
  attribute: Types.Attribute
): Types.AttributeValue;
export function fromThinValue(
  thinValue: Types.ThinAttributeValue | null,
  attribute: Types.Attribute
): Types.AttributeValue | null;
export function fromThinValue(
  thinValue: Types.ThinAttributeValue | null,
  attribute: Types.Attribute
): Types.AttributeValue | null {
  if (!thinValue) {
    return null;
  }
  const {valueType, ...thinValueFields} = thinValue;
  return {...thinValueFields, attribute, derived: false};
}

export const toThinAttributeValue = (
  attributeValue: Types.AttributeValue
): Types.ThinAttributeValue => {
  return {
    displayValue: attributeValue.displayValue,
    id: attributeValue.id,
    value: attributeValue.value,
    valueType: attributeValue.attribute.valueType,
  };
};

export const isThinAttributeInstance = (attributeInstance: Types.AttributeInstance) =>
  attributeInstance.attribute.name === THIN_ATTRIBUTE_PLACEHOLDER_NAME;

export function toPlanHierarchiesByType(
  demandPlanHierarchy: readonly Types.Attribute[] | null,
  inventoryPlanHierarchy: readonly Types.Attribute[] | null
) {
  const planHierarchies: Array<[Types.AttributeHierarchyType, readonly Types.Attribute[]]> = [
    [Types.AttributeHierarchyType.DEMAND_PLAN, demandPlanHierarchy || []],
    [Types.AttributeHierarchyType.INVENTORY_PLAN, inventoryPlanHierarchy || []],
  ];
  return Map(planHierarchies);
}

export function getIntervalFromGroupings(
  columnPath: List<Types.ThinAttributeValue>
): Types.LocalInterval | null {
  const lastIntervalGrouping = columnPath.findLast(
    attrValue => attrValue.valueType === Types.AttributeValueType.interval
  );
  return lastIntervalGrouping ? lastIntervalGrouping.value : null;
}

export function getOneLessGranularity(granularity: Types.CalendarUnit) {
  switch (granularity) {
    case Types.CalendarUnit.DAYS:
      return Types.CalendarUnit.DAYS;
    case Types.CalendarUnit.WEEKS:
      return Types.CalendarUnit.DAYS;
    case Types.CalendarUnit.MONTHS:
      return Types.CalendarUnit.WEEKS;
    case Types.CalendarUnit.QUARTERS:
      return Types.CalendarUnit.MONTHS;
    case Types.CalendarUnit.SEASONS:
      return Types.CalendarUnit.MONTHS;
    case Types.CalendarUnit.YEARS:
      return Types.CalendarUnit.MONTHS;
  }
}

export function getAttributeNameForGranularity(granularity: Types.CalendarUnit) {
  switch (granularity) {
    case Types.CalendarUnit.DAYS:
      return 'Day';
    case Types.CalendarUnit.WEEKS:
      return 'Week';
    case Types.CalendarUnit.MONTHS:
      return 'Month';
    case Types.CalendarUnit.QUARTERS:
      return 'Quarter';
    case Types.CalendarUnit.SEASONS:
      return 'Calendar Season';
    case Types.CalendarUnit.YEARS:
      return 'Year';
  }
}

const NAME_TO_GRANULARITY: Map<string, Types.CalendarUnit> = Map(
  allEnumValues(Types.CalendarUnit).map(value => [getAttributeNameForGranularity(value), value])
);

export function getGranularityForAttribute(attribute: Types.Attribute): Types.CalendarUnit {
  if (NAME_TO_GRANULARITY.has(attribute.name)) {
    return NAME_TO_GRANULARITY.get(attribute.name)!;
  }
  throw new Error(`Cannot find granularity for attribute ${attribute.name}.`);
}

export function getAttributeColumnIndexForSort(
  rowGroupings: readonly Types.AttributeInstance[]
): number | null {
  if (!rowGroupings.length) {
    return null;
  }

  if (!rowGroupings.some(grouping => grouping.attribute.type === Types.AttributeType.DATE)) {
    return null;
  }

  // return the date grouping index with smallest granularity
  return rowGroupings
    .filter(grouping => grouping.attribute.type === Types.AttributeType.DATE)
    .map(grouping => getGranularityForAttribute(grouping.attribute))
    .reduce((acc, item, currentIndex, values) => {
      const previousValue = values[acc];
      if (dateGroupingGranularityOrder[item] < dateGroupingGranularityOrder[previousValue]) {
        return currentIndex;
      }
      return acc;
    }, 0);
}

export function getAttributeInstance(
  groupingAttribute: Types.GroupingAttribute
): Types.AttributeInstance {
  return {
    attribute: groupingAttribute.attribute!,
    graphContext: groupingAttribute.graphContext ?? null,
  };
}

export function getAttributeInstances(groupingAttributes: readonly Types.GroupingAttribute[]) {
  return groupingAttributes
    .filter(groupingAttribute => !!groupingAttribute.attribute)
    .map(getAttributeInstance);
}

export function getGroupingAttributeType(groupingAttribute: Types.GroupingAttribute) {
  return groupingAttribute.attribute?.type ?? groupingAttribute.placeholderAttributeType!;
}

export function getGroupingAttributeName(groupingAttribute?: Types.GroupingAttribute) {
  return groupingAttribute?.attribute?.name ?? groupingAttribute?.placeholderAttributeName ?? '';
}

export function getGroupingDisplayName(groupingAttribute: Types.GroupingAttribute) {
  return groupingAttribute.attribute
    ? getAttributeDisplayName(getAttributeInstance(groupingAttribute))
    : groupingAttribute.placeholderAttributeName!;
}

export function canUserEditValueForAttribute(
  {user, vendor}: CurrentUser,
  attribute: Types.Attribute,
  supplyingFiletypesLookup: Map<string, Set<string>>,
  identifierAttribute?: Types.Attribute,
  isIdentifierPrimaryIdentifier = true,
  isCrossPartnerEditingEnabled = false
) {
  if (
    BUILTIN_UNTAGGABLE_ATTRIBUTES.includes(attribute.name) ||
    supplyingFiletypesLookup.has(attribute.name)
  ) {
    return false;
  }
  if (!isCrossPartnerEditingEnabled && attribute.type === Types.AttributeType.PRODUCT) {
    if (identifierAttribute?.partnerId === null && attribute.partnerId !== null) {
      // Best practice is to not load partner attributes on vendor products, so block editing them.
      return false;
    } else if (
      identifierAttribute?.partnerId !== attribute.partnerId &&
      !(attribute.matching && attribute.partnerId === null && isIdentifierPrimaryIdentifier)
    ) {
      // Do not allow editing attributes whose partner is different than the identifiying
      // attribute's partner - you can only edit the attributes within that partner's product
      // master. The exception is matching vendor attributes, which can be edited (as long as
      // it satisfies the rest of the checks).
      // Also if the identifier was not a primary identifier, you shouldn't match
      // to vendor products using it, you need to go through a transitive match.
      return false;
    }
  }
  const isEditable =
    BUILTIN_EDITABLE_ATTRIBUTES.includes(attribute.name) || attribute.vendorId === vendor.id;
  return user.role === Types.Role.ROOT || (isEditable && isAtLeast(user.role, Types.Role.ADMIN));
}

export function getFullAttributes(
  attributeQueryResult: Types.SingleAttributeQueryResult
): readonly Types.AttributeValue[] {
  return attributeQueryResult.attributeValues.map((thinAttrValue, index) => {
    const attributeValue: Types.AttributeValue = {
      ...thinAttrValue,
      attribute: attributeQueryResult.attributes[index],
      derived: false,
    };
    return attributeValue;
  });
}

export function isLocationAttribute(attribute: Types.Attribute) {
  return attribute.type === Types.AttributeType.LOCATION;
}

export function isPrivateLocationAttribute(attribute: Types.Attribute) {
  return isLocationAttribute(attribute) && attribute.vendorId !== PUBLIC_VENDOR_ID;
}

export function isPrivateLocationAttributeForVendor(attribute: Types.Attribute, vendorId: number) {
  return isLocationAttribute(attribute) && attribute.vendorId === vendorId;
}

export function isProductAttribute(attribute: Types.Attribute) {
  return attribute.type === Types.AttributeType.PRODUCT;
}

export function getProductIdentifierAttributes(identifiers: Types.ProductIdentifierAttributes) {
  // Return a list where the identifier is first, and, if present, the unit of measure is second
  return [identifiers.identifierAttr, identifiers.unitOfMeasureAttr].filter(isNonNullish);
}

export function isSegmentAttribute(attribute: Types.Attribute) {
  return attribute.type === Types.AttributeType.SEGMENT;
}

export function isPartner(attribute: Types.Attribute) {
  return attribute.name === 'Partner';
}

export function getAttributeValueIds(thinAttributeValues: readonly Types.ThinAttributeValue[]) {
  return List(thinAttributeValues.map(value => assertTruthy(value.id)));
}

export function getAttributeAndValuePairsFromProductAttribute(
  value: string
): ReadonlyArray<[string, string]> {
  const split = value.split(', ');
  const attributeAndValueChunks: string[] = [];
  // eslint-disable-next-line fp/no-let
  let currentIdentifier: string | null = null;
  for (const identifierChunk of split) {
    if (currentIdentifier === null) {
      currentIdentifier = identifierChunk;
      continue;
    }

    // The identifier value may contain ", " - in this case, we distinguish this by checking if the current value also
    // contains the other part of the separator ("=") - if not then we know this is still part of the preceding
    // attribute + value pair.
    if (identifierChunk.includes('=')) {
      attributeAndValueChunks.push(currentIdentifier);
      currentIdentifier = identifierChunk;
    } else {
      currentIdentifier = `${currentIdentifier}, ${identifierChunk}`;
    }
  }
  if (currentIdentifier !== null) {
    attributeAndValueChunks.push(currentIdentifier);
  }
  return attributeAndValueChunks
    .map((keyValue): [string, string] => {
      const [first, ...rest] = keyValue.split('=');
      return [first, rest.join('=')];
    })
    .filter(([attributeName]) => attributeName !== 'Alloy ID');
}

export const toThinAttributeInstance = (
  attributeInstance: Types.AttributeInstance
): ThinAttributeInstance => ({
  graphContext: attributeInstance.graphContext,
  id: assertNonNullish(attributeInstance.attribute.id),
  templateAttributeId: assertNonNullish(attributeInstance.attribute.templateAttributeId),
});

export const isDistinctAttributeValue = (
  attributeValue: Types.ThinAttributeValue | Types.AttributeValue
) => {
  return attributeValue.id !== UNKNOWN_VALUE_ID && attributeValue.id !== MULTIVALUE_ID;
};

// If any attribute is updated, created or deleted, call this to invalidate (or re-fetch) all endpoints that might have
// been affected.
export function invalidateAttributeEndpoints(
  modifiedAttribute: Types.Attribute,
  dispatch: Dispatch,
  currentUser: CurrentUser
) {
  if (modifiedAttribute.type === Types.AttributeType.PRODUCT) {
    // modifiedAttribute is a placeholder Attribute object; invalidateAllMatchingRequests will invalidate this endpoint
    // for all arguments
    invalidateAllMatchingRequests(
      Api.Attributes.getDescriptionAttributeForIdentifier.getResource(modifiedAttribute)
    );
    invalidate(Api.Attributes.getProductMasterIdentifierAttributes.getResource());
    invalidate(Api.Attributes.getAllProductIdentifiers.getResource());
    invalidate(Api.Attributes.getProductIdentifierSpec.getResource());
  }
  invalidate(
    Api.Attributes.getSupplyingFiletypesByAttributeName.getResource(modifiedAttribute.type)
  );
  invalidate(Api.Attributes.getAllAttributes.getResource(currentUser.vendor));
  invalidate(Api.Attributes.getAttributesOfType.getResource(modifiedAttribute.type));
  invalidate(Api.Attributes.getDefaultFilterAttributes.getResource());
  invalidateAllMatchingRequests(Api.Attributes.getAttributesSharingTemplate.getResource(0));
  invalidate(Api.Attributes.getAttribute.getResource(modifiedAttribute.id!));
  // fetchAllGroupings refetches Api.Attributes.getAllPopulatedAttributes, and Api.Attributes.getDefaultAttributes
  // for each AttributeHierarchyType.
  // There is some involved logic to get populated metrics in the backend, so instead of trying to update the cache
  // on the front end, just refresh the redux cache manually.
  dispatch(AnalysisDataActions.fetchAllGroupings());
}
