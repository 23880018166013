/* eslint-disable */

/*
  This file is generated from the OpenAPI specification created from pewter; do not edit it
  directly. To update, run `ore codegen`.
*/

export type ActivationJson = {
  readonly firstName: string | null;
  readonly functionalArea: string | null;
  readonly lastName: string | null;
  readonly password: string | null;
  readonly phoneNumber: string | null;
  readonly token: string;
};

export type ActiveAttributeValuesRequest = {
  readonly attribute: Attribute;
  readonly filters: ReadonlyArray<AttributeFilter>;
};

export type AffectedDeletionMetrics = {
  readonly edgeMetrics: ReadonlyArray<PartnerEdgeMetric>;
  readonly nodeMetrics: ReadonlyArray<PartnerMetric>;
};

export type AllDataRangeResponse = {
  readonly edgeDataRanges: ReadonlyArray<EdgeDataRange>;
  readonly nodeDataRanges: ReadonlyArray<NodeDataRange>;
};

export type ApplicationPackage = {
  readonly archivedAt: string | null;
  readonly description: string;
  readonly id: number | null;
  readonly name: string;
  readonly roles: ReadonlyArray<RbacRole>;
};

export type Attribute = {
  readonly id: number | null;
  readonly matching: boolean;
  readonly name: string;
  readonly partnerId: number | null;
  readonly templateAttributeId: number | null;
  readonly templateAttributeName: string | null;
  readonly type: AttributeType;
  readonly valueType: AttributeValueType;
  readonly vendorId: number;
};

export type AttributeDescription = {
  readonly description: string;
  readonly id: number | null;
  readonly name: string;
};

export type AttributeFilter = {
  readonly attributeInstance: AttributeInstance;
  readonly inclusive: boolean;
  readonly values: ReadonlyArray<AttributeValue>;
};

export type AttributeHierarchyTree = {
  readonly children: ReadonlyArray<AttributeHierarchyTree>;
  readonly value: ThinAttributeValue | null;
};

export enum AttributeHierarchyType {
  DEMAND_PLAN = 'DEMAND_PLAN',
  FORECAST = 'FORECAST',
  INVENTORY_PLAN = 'INVENTORY_PLAN',
  LOCATION = 'LOCATION',
  PRODUCT = 'PRODUCT',
  TRANSACTION_EVENT = 'TRANSACTION_EVENT',
}

export type AttributeInstance = {
  readonly attribute: Attribute;
  readonly graphContext: GraphContext | null;
};

export type AttributeInstanceMapping = {
  readonly attribute: AttributeInstance;
  readonly mappedValue: string;
};

export type AttributeQuery = {
  readonly attributes: ReadonlyArray<Attribute>;
  readonly filters: ReadonlyArray<AttributeFilter>;
  readonly includeAllAssignableValues: boolean;
  readonly includeEmptyItemLocations: boolean;
  readonly includePartnerProducts: boolean;
  readonly includeValueMetadata: boolean;
  readonly onlyShowProductsIdentifiedBy: ProductIdentifierAttributes | null;
};

export type AttributeQueryResult = {
  readonly assignableValues: ReadonlyArray<ReadonlyArray<ThinAttributeValue>>;
  readonly attributeValues: ReadonlyArray<ReadonlyArray<ThinAttributeValue>>;
  readonly attributes: ReadonlyArray<Attribute>;
  readonly itemIds: ReadonlyArray<number>;
  readonly type: AttributeType;
  readonly valueMetadata: {
    [key: string]: {
      [key: string]: AttributeValueMetadata;
    };
  };
};

export enum AttributeType {
  DATE = 'DATE',
  LOCATION = 'LOCATION',
  PRODUCT = 'PRODUCT',
  SEGMENT = 'SEGMENT',
  TRANSACTION_EVENT = 'TRANSACTION_EVENT',
  VIRTUAL = 'VIRTUAL',
}

export type AttributeUrlLink = {
  readonly id: number | null;
  readonly sourceViewId: number;
  readonly type: 'ATTRIBUTE_URL';
  readonly attributeType: AttributeType;
  readonly url: string;
};

export type AttributeValue = {
  readonly attribute: Attribute;
  readonly derived: boolean;
  readonly displayValue: string | null;
  readonly id: number | null;
  readonly value: any | null;
};

export type AttributeValueColor = {
  readonly attributeId: number;
  readonly attributeValueId: number;
  readonly color: string;
};

export type AttributeValueMetadata = {
  readonly derived: boolean;
};

export enum AttributeValueType {
  date = 'date',
  float = 'float',
  integer = 'integer',
  interval = 'interval',
  string = 'string',
}

export type AttributeValuesByProductQuery = {
  readonly attribute: Attribute | null;
  readonly productIds: ReadonlyArray<number>;
};

export type AttributeValuesUpdateResult = {
  readonly changedItemIds: ReadonlyArray<number>;
  readonly values: {
    [key: string]: ReadonlyArray<AttributeValue>;
  };
};

export type AttributeViewLink = {
  readonly id: number | null;
  readonly sourceViewId: number;
  readonly type: 'ATTRIBUTE';
  readonly attributeType: AttributeType;
  readonly targetViewId: number;
};

export type AttributesByIdentifierQuery = {
  readonly attributes: ReadonlyArray<Attribute>;
  readonly identifierAttribute: Attribute;
};

export type AugmentationContext = {
  readonly allowedMultiCurrencyMetrics: ReadonlyArray<Metric>;
  readonly dataGapsByMetric: {
    [key: string]: ReadonlyArray<LocalInterval>;
  };
  readonly defaultArguments: MetricArguments;
  readonly existingIntervalsByMetric: {
    [key: string]: SeriesMetadata;
  };
  readonly interval: LocalInterval;
  readonly originalIntervalsByMetric: {
    [key: string]: SeriesMetadata;
  };
  readonly partnerId: number;
  readonly productLocationSnapshotMetrics: ReadonlyArray<Metric>;
  readonly vendorId: number;
};

export type AugmentedDataResponse = {
  readonly augmented: {
    [key: string]: {
      [key: string]: TimeSeriesVersions;
    };
  };
  readonly original: {
    [key: string]: {
      [key: string]: TimeSeries;
    };
  };
};

export type AugmentedDatasetResponse = {
  readonly context: AugmentationContext;
  readonly outputData: ReadonlyArray<ProductLocationAugmentationOutputData>;
};

export type AugmentedEdgeDataRequest = {
  readonly destinationLocationId: number;
  readonly destinationPartnerId: number;
  readonly interval: LocalInterval;
  readonly originLocationId: number;
  readonly originPartnerId: number;
  readonly productId: number;
};

export type AugmentedNodeDataRequest = {
  readonly interval: LocalInterval;
  readonly locationId: number;
  readonly partnerId: number;
  readonly productId: number;
};

export type AugmentedNodeDatasetRequest = {
  readonly interval: LocalInterval;
  readonly maxProductId: number;
  readonly minProductId: number;
  readonly partnerId: number;
};

export type AutoCompleteRequest = {
  readonly excludedAttributeIds: ReadonlyArray<number>;
  readonly includedAttributeIds: ReadonlyArray<number>;
  readonly limit: number;
  readonly query: string;
};

export type AutoCompletionResult = {
  readonly isComplete: boolean;
  readonly query: string;
  readonly values: ReadonlyArray<AttributeValue>;
};

export type AvailableData = {
  readonly dataGaps: ReadonlyArray<LocalInterval>;
  readonly dataRange: LocalInterval;
  readonly mappings: ReadonlyArray<GroupedMetricFieldMapping>;
  readonly metricId: number;
  readonly partnerIdentifier: PartnerIdentifier;
};

export type AzureContainerInfo = {
  readonly containerName: string;
  readonly exists: boolean;
  readonly storageAccount: string;
  readonly url: string;
};

export enum AzureRegion {
  AUSTRALIA_CENTRAL = 'AUSTRALIA_CENTRAL',
  AUSTRALIA_EAST = 'AUSTRALIA_EAST',
  AUSTRALIA_SOUTHEAST = 'AUSTRALIA_SOUTHEAST',
  BRAZIL_SOUTH = 'BRAZIL_SOUTH',
  CANADA_CENTRAL = 'CANADA_CENTRAL',
  CANADA_EAST = 'CANADA_EAST',
  CENTRAL_INDIA = 'CENTRAL_INDIA',
  CENTRAL_US = 'CENTRAL_US',
  EAST_ASIA = 'EAST_ASIA',
  EAST_US = 'EAST_US',
  EAST_US_2 = 'EAST_US_2',
  FRANCE_CENTRAL = 'FRANCE_CENTRAL',
  GERMANY_WEST_CENTRAL = 'GERMANY_WEST_CENTRAL',
  ISRAEL_CENTRAL = 'ISRAEL_CENTRAL',
  ITALY_NORTH = 'ITALY_NORTH',
  JAPAN_EAST = 'JAPAN_EAST',
  JAPAN_WEST = 'JAPAN_WEST',
  KOREA_CENTRAL = 'KOREA_CENTRAL',
  KOREA_SOUTH = 'KOREA_SOUTH',
  MEXICO_CENTRAL = 'MEXICO_CENTRAL',
  NEW_ZEALAND_NORTH = 'NEW_ZEALAND_NORTH',
  NORTH_CENTRAL_US = 'NORTH_CENTRAL_US',
  NORTH_EUROPE = 'NORTH_EUROPE',
  NORWAY_EAST = 'NORWAY_EAST',
  POLAND_CENTRAL = 'POLAND_CENTRAL',
  QATAR_CENTRAL = 'QATAR_CENTRAL',
  SOUTHEAST_ASIA = 'SOUTHEAST_ASIA',
  SOUTH_AFRICA_NORTH = 'SOUTH_AFRICA_NORTH',
  SOUTH_CENTRAL_US = 'SOUTH_CENTRAL_US',
  SOUTH_INDIA = 'SOUTH_INDIA',
  SPAIN_CENTRAL = 'SPAIN_CENTRAL',
  SWEDEN_CENTRAL = 'SWEDEN_CENTRAL',
  SWITZERLAND_NORTH = 'SWITZERLAND_NORTH',
  UAE_NORTH = 'UAE_NORTH',
  UK_SOUTH = 'UK_SOUTH',
  UK_WEST = 'UK_WEST',
  WEST_CENTRAL_US = 'WEST_CENTRAL_US',
  WEST_EUROPE = 'WEST_EUROPE',
  WEST_INDIA = 'WEST_INDIA',
  WEST_US = 'WEST_US',
  WEST_US_2 = 'WEST_US_2',
  WEST_US_3 = 'WEST_US_3',
}

export type AzureStorageDestination = {
  readonly deliveryChannel: DeliveryChannel;
  readonly id: number | null;
  readonly isActive: boolean;
  readonly modifierId: number;
  readonly name: string;
  readonly ownerId: number;
  readonly shareLevel: ShareLevel;
  readonly syncFrequencyConfig: SyncFrequencyConfig;
  readonly vendorId: number;
  readonly type: 'AZURE_STORAGE';
  readonly azureRegion: AzureRegion;
  readonly emailAddresses: ReadonlyArray<string>;
};

export type BigQueryExportConfiguration = {
  readonly deliveryChannel: DeliveryChannel;
  readonly id: number | null;
  readonly isActive: boolean;
  readonly modifierId: number;
  readonly name: string;
  readonly ownerId: number;
  readonly shareLevel: ShareLevel;
  readonly syncFrequencyConfig: SyncFrequencyConfig;
  readonly vendorId: number;
  readonly type: 'BIGQUERY';
  readonly dataset: string | null;
  readonly encoding: ColumnNameEncoding;
  readonly project: string | null;
  readonly serviceAccounts: ReadonlyArray<string>;
};

export type BigQuerySlowestQueryStats = {
  readonly bytesProcessed: number | null;
  readonly executionTimeMs: number | null;
  readonly jobId: string;
  readonly queryInfoType: string | null;
  readonly recordsAfterBiggestStage: number | null;
  readonly recordsBeforeBiggestStage: number | null;
  readonly recordsReturned: number | null;
  readonly slotTimeMs: number | null;
  readonly tables: ReadonlyArray<string>;
  readonly totalPartitionsProcessed: number | null;
  readonly waitTimeMs: number | null;
};

export type BigQueryStats = {
  readonly cachedSubrequestCount: number | null;
  readonly fetchedIntoTrie: string | null;
  readonly lastQueryEndTime: string | null;
  readonly maxNumberOfDaysPerQuery: number | null;
  readonly maxQueryDurationMs: number | null;
  readonly maxSubRequestDurationMs: number | null;
  readonly queryCacheHits: number | null;
  readonly queryCount: number | null;
  readonly queryStarted: string | null;
  readonly slowestQueryStats: BigQuerySlowestQueryStats | null;
  readonly subRequestCount: number | null;
  readonly totalBytesProcessed: number | null;
  readonly totalQueryDurationMs: number | null;
  readonly totalSubRequestDurationMs: number | null;
};

export type BodyPart = {
  readonly contentDisposition: ContentDisposition;
  readonly entity: any;
  readonly headers: {
    [key: string]: ReadonlyArray<string>;
  };
  readonly mediaType: {
    readonly parameters: {
      [key: string]: string;
    };
    readonly subtype: string;
    readonly type: string;
    readonly wildcardSubtype: boolean;
    readonly wildcardType: boolean;
  };
  readonly messageBodyWorkers: MessageBodyWorkers;
  readonly parameterizedHeaders: {
    [key: string]: ReadonlyArray<ParameterizedHeader>;
  };
  readonly parent: MultiPart;
  readonly providers: any;
};

export enum BqTableType {
  DATA = 'DATA',
  EDGE_DATA = 'EDGE_DATA',
  TRANSACTION = 'TRANSACTION',
}

export type BrowserNavigationTiming = {
  readonly connectEnd: number | null;
  readonly connectStart: number | null;
  readonly decodedBodySize: number | null;
  readonly domComplete: number | null;
  readonly domContentLoadedEventEnd: number | null;
  readonly domContentLoadedEventStart: number | null;
  readonly domInteractive: number | null;
  readonly encodedBodySize: number | null;
  readonly fetchStart: number | null;
  readonly loadEventEnd: number | null;
  readonly loadEventStart: number | null;
  readonly requestStart: number | null;
  readonly responseEnd: number | null;
  readonly responseStart: number | null;
  readonly startTime: number | null;
  readonly type: string | null;
};

export type BulkAggregationRequest = {
  readonly edgePartnerIds: ReadonlyArray<OriginDestinationPartner> | null;
  readonly interval: LocalInterval | null;
  readonly nodePartnerIds: ReadonlyArray<number> | null;
  readonly scheduledTime: string | null;
  readonly vendorIds: ReadonlyArray<number> | null;
};

export enum CacheInitialState {
  DEDUPLICATED = 'DEDUPLICATED',
  HIT = 'HIT',
  MISS = 'MISS',
}

export enum Cadence {
  DAILY = 'DAILY',
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  MONTHLY = 'MONTHLY',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  UNKNOWN = 'UNKNOWN',
  WEDNESDAY = 'WEDNESDAY',
}

export type Calculation = {
  readonly discount: number;
  readonly originalPrice: number | null;
  readonly quantity: number;
  readonly type: Type;
};

export type CalendarEvent = {
  readonly baselineMetricArguments: MetricArguments | null;
  readonly createdAt: string | null;
  readonly details: ReadonlyArray<CalendarEventDetail>;
  readonly discountAmount: number | null;
  readonly discountType: DiscountType | null;
  readonly eventAnalysis: EventAnalysis | null;
  readonly eventLevelFilters: ReadonlyArray<AttributeFilter>;
  readonly expectedSalesRelativeLift: number | null;
  readonly externalId: string | null;
  readonly externalSource: number | null;
  readonly fixedCosts: number | null;
  readonly id: number | null;
  readonly interval: LocalInterval;
  readonly lastUpdatedAt: string | null;
  readonly lastUpdatedBy: number | null;
  readonly name: string;
  readonly note: string;
  readonly target: SalesTargetValue | null;
  readonly type: CalendarEventType;
};

export type CalendarEventDetail = {
  readonly attributeValue: AttributeValue;
  readonly discountAmount: number | null;
  readonly discountType: DiscountType;
  readonly originalPrice: number | null;
  readonly target: SalesTargetValue | null;
};

export type CalendarEventResult = {
  readonly events: ReadonlyArray<CalendarEvent>;
  readonly intervals: {
    [key: string]: ReadonlyArray<LocalInterval>;
  };
};

export enum CalendarEventType {
  EXTERNAL_FACTORS_COMPETITIVE_NEW_PRODUCT = 'EXTERNAL_FACTORS_COMPETITIVE_NEW_PRODUCT',
  EXTERNAL_FACTORS_COMPETITIVE_PROMOTION = 'EXTERNAL_FACTORS_COMPETITIVE_PROMOTION',
  EXTERNAL_FACTORS_SUPPLY_CHAIN_DISRUPTION = 'EXTERNAL_FACTORS_SUPPLY_CHAIN_DISRUPTION',
  EXTERNAL_FACTORS_WEATHER = 'EXTERNAL_FACTORS_WEATHER',
  MARKETING_ADVERTISEMENT = 'MARKETING_ADVERTISEMENT',
  MARKETING_CIRCULAR = 'MARKETING_CIRCULAR',
  MARKETING_DIRECT_MAIL = 'MARKETING_DIRECT_MAIL',
  MARKETING_EMAIL_CAMPAIGN = 'MARKETING_EMAIL_CAMPAIGN',
  MARKETING_SOCIAL = 'MARKETING_SOCIAL',
  MARKETING_TV = 'MARKETING_TV',
  MERCHANDISING_IN_STORE_DISPLAY = 'MERCHANDISING_IN_STORE_DISPLAY',
  MERCHANDISING_IN_STORE_VISIT = 'MERCHANDISING_IN_STORE_VISIT',
  MERCHANDISING_PACKAGED_BUNDLE = 'MERCHANDISING_PACKAGED_BUNDLE',
  OTHER = 'OTHER',
  PRICE_BUY_X_GET_Y_FREE = 'PRICE_BUY_X_GET_Y_FREE',
  PRICE_DISCOUNT = 'PRICE_DISCOUNT',
  PRICE_REBATE = 'PRICE_REBATE',
  PRODUCT_INTRO_END_OF_LIFE = 'PRODUCT_INTRO_END_OF_LIFE',
  PRODUCT_INTRO_LOCATION_EXPANSION = 'PRODUCT_INTRO_LOCATION_EXPANSION',
  PRODUCT_INTRO_NPI = 'PRODUCT_INTRO_NPI',
  PRODUCT_INTRO_PARTNER_EXPANSION = 'PRODUCT_INTRO_PARTNER_EXPANSION',
  PRODUCT_INTRO_TRANSITION = 'PRODUCT_INTRO_TRANSITION',
}

export type CalendarEventsRequest = {
  readonly calendar: RetailCalendarEnum;
  readonly calendarGranularities: ReadonlyArray<CalendarUnit> | null;
  readonly evaluationDate: string | null;
  readonly eventFilter: EventFilter | null;
  readonly filters: ReadonlyArray<AttributeFilter>;
  readonly period: DatePeriod | null;
};

export type CalendarPreviewRequest = {
  readonly calendar: RetailCalendarEnum;
  readonly evaluationDate: string;
  readonly period: DatePeriod;
};

export type CalendarProperties = {
  readonly defaultDisplayName: string;
  readonly displayName: string;
  readonly name: RetailCalendarEnum;
  readonly startOfWeekIndex: number;
};

export enum CalendarUnit {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  QUARTERS = 'QUARTERS',
  SEASONS = 'SEASONS',
  WEEKS = 'WEEKS',
  YEARS = 'YEARS',
}

export type ChecksumRequest = {
  readonly interval: LocalInterval;
  readonly metricIds: ReadonlyArray<number> | null;
  readonly partnerIds: ReadonlyArray<number> | null;
};

export type ClassificationPattern = {
  readonly filetype: string | null;
  readonly id: number | null;
  readonly pattern: string;
  readonly status: FileStatusEnum;
  readonly target: ClassificationPatternTarget;
};

export enum ClassificationPatternTarget {
  FILENAME = 'FILENAME',
  SENDER_EMAIL = 'SENDER_EMAIL',
}

export enum Color {
  BLUE = 'BLUE',
  CYAN = 'CYAN',
  GREEN = 'GREEN',
  INDIGO = 'INDIGO',
  ORANGE = 'ORANGE',
  PURPLE = 'PURPLE',
  RED = 'RED',
  YELLOW = 'YELLOW',
}

export enum ColumnNameEncoding {
  AVRO = 'AVRO',
  BIGQUERY_FLEXIBLE = 'BIGQUERY_FLEXIBLE',
}

export type CompletedPlanSpreadsheetMaterializationRequest = {
  readonly scheduledTime: string;
};

export type ComplexDatePeriod = {
  readonly type: 'complex';
  readonly endPeriod: SimpleDatePeriod;
  readonly startPeriod: SimpleDatePeriod;
};

export type ComputeDevOptions = {
  readonly useBigQueryCache: boolean;
  readonly useComputeCache: boolean;
};

export enum ComputeMethod {
  COMPUTE = 'COMPUTE',
  TOKEN = 'TOKEN',
}

export type ComputeOptions = {
  readonly allowIncompleteDisaggregation: boolean;
  readonly batch: boolean;
  readonly filterFulfillmentMethods: boolean;
  readonly includeEmptyDates: boolean;
  readonly includeTargetMetadata: boolean;
};

export type ComputeRequest = {
  readonly calendar: RetailCalendarEnum;
  readonly columnGroupings: ReadonlyArray<AttributeInstance>;
  readonly computeEvaluationDateTime: string | null;
  readonly devOptions: ComputeDevOptions | null;
  readonly evaluationDate: string;
  readonly filters: ReadonlyArray<AttributeFilter>;
  readonly metricFilterGroupings: ReadonlyArray<AttributeInstance>;
  readonly metricFilters: ReadonlyArray<ReadonlyArray<MetricFilter>>;
  readonly metrics: ReadonlyArray<MetricInstance>;
  readonly options: ComputeOptions | null;
  readonly postComputeFilters: ReadonlyArray<AttributeFilter>;
  readonly removeDoubleCounting: boolean;
  readonly requestId: string;
  readonly rowGroupings: ReadonlyArray<AttributeInstance>;
  readonly rowLimit: RowLimit | null;
  readonly type: ComputeRequestType;
};

export type ComputeRequestExtendedStats = {
  readonly bigQueryMaxNumberOfDaysPerQuery: number | null;
  readonly bigQueryQueryCount: number | null;
  readonly bigQuerySlowestQueryStats: BigQuerySlowestQueryStats | null;
  readonly bigQueryTotalFetchToTrieTimeMs: number | null;
  readonly bigQueryTotalQueryExecutionTimeMs: number | null;
  readonly cachingHash: string | null;
  readonly cachingVersionHash: string | null;
  readonly collectResultsDurationMs: number | null;
  readonly fetchMetricSnapshotDisaggregablesDurationMs: number | null;
  readonly getItemValueKeysDurationMs: number | null;
  readonly groupingAndMetricCount: number | null;
  readonly resultRowCount: number | null;
  readonly resultToBucketDurationMs: number | null;
  readonly totalDisaggregationDurationMs: number | null;
  readonly unversionedCachingHash: string | null;
};

export type ComputeRequestStats = {
  readonly bigQuery: BigQueryStats | null;
  readonly bootTime: string | null;
  readonly cacheInitialState: CacheInitialState | null;
  readonly computeTimeMs: number | null;
  readonly context: any | null;
  readonly disaggregation: DisaggregationStats | null;
  readonly extendedStats: ComputeRequestExtendedStats | null;
  readonly hasNonZeroData: boolean | null;
  readonly instanceNames: ReadonlyArray<string>;
  readonly intervalDays: number | null;
  readonly locationRollupViewUsed: boolean | null;
  readonly method: ComputeMethod;
  readonly metricCount: number | null;
  readonly nonRollupViewUsed: boolean | null;
  readonly payloadHash: string | null;
  readonly productRollupViewUsed: boolean | null;
  readonly requestComputed: string | null;
  readonly requestCreated: string;
  readonly requestFinished: string | null;
  readonly requestId: string;
  readonly requestResultReceived: string | null;
  readonly requestStarted: string | null;
  readonly requesterId: number | null;
  readonly resultSlice: number | null;
  readonly source: string;
  readonly terminatedAt: string | null;
  readonly terminatedBy: string | null;
  readonly terminatedByExceptionName: string | null;
  readonly token: string | null;
  readonly totalSlotTimeMs: number | null;
  readonly totalTimeMs: number | null;
  readonly traceId: string | null;
  readonly vendorId: number;
  readonly waitTimeMs: number | null;
};

export enum ComputeRequestType {
  DEFAULT = 'DEFAULT',
  FLAT = 'FLAT',
  PLAN = 'PLAN',
}

export type ComputeResult = {
  readonly data: ThinComputeResultRow;
  readonly totalRowCount: number;
};

export enum ConditionalMetricFormatRenderType {
  BACKGROUND_COLOR = 'BACKGROUND_COLOR',
  DEFAULT = 'DEFAULT',
  LABEL = 'LABEL',
  TEXT_COLOR = 'TEXT_COLOR',
}

export type ConnectionTestResult = {
  readonly extraData: any | null;
  readonly resultCode: ConnectionTestResultCode;
};

export enum ConnectionTestResultCode {
  CANNOT_DELETE = 'CANNOT_DELETE',
  CONNECTION_TIMEOUT = 'CONNECTION_TIMEOUT',
  FAILED_TO_PARSE_HOST = 'FAILED_TO_PARSE_HOST',
  FILE_INVALID_PATH = 'FILE_INVALID_PATH',
  FILE_PERMISSION_DENIED = 'FILE_PERMISSION_DENIED',
  FILE_READ_ISSUE = 'FILE_READ_ISSUE',
  FILE_TRANSFER_ISSUE = 'FILE_TRANSFER_ISSUE',
  HOST_CONTAINS_SCHEME = 'HOST_CONTAINS_SCHEME',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  LOCAL_HOST_ADDRESS = 'LOCAL_HOST_ADDRESS',
  NO_HOST = 'NO_HOST',
  OK = 'OK',
  UNKNOWN_CONNECTION_ISSUE = 'UNKNOWN_CONNECTION_ISSUE',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  UNKNOWN_HOST = 'UNKNOWN_HOST',
}

export type ContentDisposition = {
  readonly creationDate: string;
  readonly fileName: string;
  readonly modificationDate: string;
  readonly parameters: {
    [key: string]: string;
  };
  readonly readDate: string;
  readonly size: number;
  readonly type: string;
};

export type ConversionRateItem = {
  readonly id: number | null;
  readonly rate: number;
  readonly startDate: string;
  readonly toCurrency: CurrencyCode;
  readonly vendorId: number;
};

export type CostAnalysis = {
  readonly breakdown: ReadonlyArray<LineItem>;
  readonly partnersProvideCompleteSalesData: boolean;
  readonly total: number;
};

export type CreateCredentialRequest = {
  readonly credentialGroupName: string;
  readonly credentialSetName: string | null;
  readonly email: string;
  readonly requestType: CredentialsRequestType;
};

export type CreateDataSourceRequest = {
  readonly credentialSetName: string;
  readonly credentialSource: CredentialSource | null;
  readonly dataSourceId: number;
  readonly emailToSendCredentialRequestTo: string | null;
  readonly historyToPull: DatePeriod | null;
  readonly ownerId: number | null;
  readonly providedCredentials: {
    [key: string]: CredentialValue;
  } | null;
  readonly scraperInstanceIds: ReadonlyArray<number> | null;
  readonly slackRecipient: string | null;
  readonly startPeriodicRuns: boolean;
};

export type CreateDataSourceResponse = {
  readonly credentialRequest: CredentialRequest | null;
  readonly scheduledFeeds: ReadonlyArray<ScheduledFeed>;
  readonly scheduledRuns: ReadonlyArray<ScraperRun>;
  readonly usedExistingCredential: boolean;
  readonly vendorDataSource: VendorDataSource;
};

export type CreateExternalCredentialsOwnerRequest = {
  readonly emailToSendCredentialRequestTo: string;
};

export type CreateSelfServeDataSourceRequest = {
  readonly integrationId: number;
  readonly type: DataSourceType;
};

export type CredentialField = {
  readonly description: string | null;
  readonly displayName: string;
  readonly isAdminSetting: boolean;
  readonly isAuthUrlParam: boolean;
  readonly isEditable: boolean;
  readonly isMultiline: boolean;
  readonly isOptional: boolean;
  readonly isSecure: boolean;
  readonly name: string;
  readonly type: CredentialFieldType;
};

export enum CredentialFieldType {
  list = 'list',
  map = 'map',
  string = 'string',
}

export type CredentialJson = {
  readonly email: string;
  readonly password: string | null;
};

export type CredentialRequest = {
  readonly completedAt: string | null;
  readonly credentialGroupId: number;
  readonly credentialGroupName: string | null;
  readonly credentialSetName: string | null;
  readonly email: string;
  readonly expiresAt: string;
  readonly id: number;
  readonly issuedAt: string;
  readonly requestType: CredentialsRequestType;
  readonly requesterId: number | null;
  readonly sendGridCode: number | null;
  readonly sendGridResponse: string | null;
  readonly vendorId: number;
};

export enum CredentialSource {
  ALLOY_USER = 'ALLOY_USER',
  ALREADY_PROVIDED = 'ALREADY_PROVIDED',
  EXISTING_CREDENTIAL = 'EXISTING_CREDENTIAL',
  EXTERNAL_EMAIL = 'EXTERNAL_EMAIL',
  SELF_SERVE = 'SELF_SERVE',
}

export type CredentialToken = {
  readonly expires: string;
  readonly id: string;
  readonly issuedInitially: string;
  readonly type: JwtTokenType;
};

export enum CredentialType {
  SERVICE_ACCOUNT = 'SERVICE_ACCOUNT',
  USER = 'USER',
}

export type CredentialValue = {
  readonly value: any | null;
};

export type CredentialsGroup = {
  readonly description: string;
  readonly displayName: string;
  readonly expirationPolicyDays: number | null;
  readonly fields: ReadonlyArray<CredentialField>;
  readonly id: number | null;
  readonly name: string;
  readonly oauthRequired: boolean;
  readonly resetUrl: string | null;
};

export type CredentialsGroupAndValues = {
  readonly group: CredentialsGroup;
  readonly values: VendorCredentials;
};

export enum CredentialsRequestType {
  ACTIVATE = 'ACTIVATE',
  EXPIRATION_WARNING = 'EXPIRATION_WARNING',
  MANUAL_UPDATE = 'MANUAL_UPDATE',
  UPDATE = 'UPDATE',
  UPDATE_PENDING = 'UPDATE_PENDING',
  UPDATE_REMINDER = 'UPDATE_REMINDER',
}

export type CrossVendorAttributeInfo = {
  readonly attributeDescription: AttributeDescription | null;
  readonly filetypes: ReadonlyArray<string>;
  readonly isPublic: boolean;
  readonly name: string;
  readonly types: ReadonlyArray<AttributeType>;
  readonly vendors: ReadonlyArray<Vendor>;
};

export type CurrencyMetricValueMetadata = {
  readonly type: 'CURRENCY';
  readonly severity: MetricValueSeverity;
  readonly value: ReadonlyArray<CurrencyCode>;
};

export enum CurrencyCode {
  AED = 'AED',
  AS_REPORTED = 'AS_REPORTED',
  AUD = 'AUD',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CZK = 'CZK',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  IDR = 'IDR',
  ILS = 'ILS',
  JPY = 'JPY',
  KRW = 'KRW',
  MXN = 'MXN',
  MYR = 'MYR',
  NOK = 'NOK',
  NZD = 'NZD',
  PEN = 'PEN',
  PHP = 'PHP',
  PLN = 'PLN',
  QAR = 'QAR',
  SAR = 'SAR',
  SEK = 'SEK',
  SGD = 'SGD',
  THB = 'THB',
  TRY = 'TRY',
  TWD = 'TWD',
  UAH = 'UAH',
  USD = 'USD',
  VND = 'VND',
  ZAR = 'ZAR',
}

export type CurrentUserData = {
  readonly permissionKeys: ReadonlyArray<string>;
  readonly user: User;
  readonly userInfo: UserInfo;
};

export type DailyDataStatus = {
  readonly data: ReadonlyArray<PartnerIdentifierMetric>;
  readonly date: string;
  readonly latestFailedOrInProgressScraperRun: ScraperRunWithRetry | null;
  readonly latestFileStatus: FileDataStatus | null;
  readonly status: DataStatusV2;
  readonly summaryDisplayStatus: DataStatusV2;
};

export type DashboardCreationRequest = {
  readonly vendorDataSourceId: number;
};

export type DataAsOfMetricValueMetadata = {
  readonly type: 'DATA_AS_OF';
  readonly severity: MetricValueSeverity;
  readonly value: string;
};

export type DataCadence = {
  readonly cadence: Cadence;
  readonly metricId: number | null;
  readonly partnerIdentifier: PartnerIdentifier;
  readonly vendorDataSourceFiletypeId: number;
};

export type DataCenterSummary = {
  readonly partnerId: number;
  readonly vendorDataSourceFiletypeIdsByTab: {
    [key: string]: ReadonlyArray<number>;
  };
};

export type DataDeletion = {
  readonly accountId: number | null;
  readonly displayMessage: string | null;
  readonly error: string | null;
  readonly id: number;
  readonly jobId: string | null;
  readonly notes: string | null;
  readonly request: DataDeletionRequest;
  readonly timeCompleted: string | null;
  readonly timeStarted: string;
};

export type DataDeletionRequest = {
  readonly dataSpecification: DataSpecification;
  readonly deleteAllDerived: boolean;
  readonly filesToAbort: ReadonlyArray<number>;
  readonly filesToIgnore: ReadonlyArray<number>;
  readonly filesToReload: ReadonlyArray<number>;
  readonly notes: string | null;
  readonly zeroDowntimeDeletion: boolean;
};

export type DataIntegration = {
  readonly description: string | null;
  readonly exportImages: boolean;
  readonly id: number | null;
  readonly integrationType: DataIntegrationType;
  readonly isCustom: boolean;
  readonly isEarlyAccess: boolean;
  readonly isPublic: boolean;
  readonly logo: string | null;
  readonly marketingName: string;
  readonly name: string;
  readonly portalName: string | null;
  readonly portalUrl: string | null;
  readonly screenshot: string | null;
  readonly slug: string;
  readonly types: {
    [key: string]: DataIntegrationDetail;
  };
};

export type DataIntegrationDetail = {
  readonly credentialGroupId: number | null;
  readonly selfServeSupported: boolean;
};

export enum DataIntegrationType {
  DISTRIBUTOR = 'DISTRIBUTOR',
  ECOMMERCE_PLATFORM = 'ECOMMERCE_PLATFORM',
  ERP = 'ERP',
  OTHER = 'OTHER',
  RETAILER = 'RETAILER',
  THIRD_PARTY_LOGISTICS = 'THIRD_PARTY_LOGISTICS',
}

export type DataIssue = {
  readonly dataIssueId: number;
  readonly vendorId: number | null;
};

export type DataRange = EdgeDataRange | NodeDataRange | PendingEdgeDataRange | PendingNodeDataRange;

export type DataSource = {
  readonly credentialGroupId: number | null;
  readonly id: number | null;
  readonly integrationId: number;
  readonly name: string;
  readonly selfServeSupported: boolean;
  readonly type: DataSourceType;
};

export type DataSourceContact = {
  readonly accountId: number;
  readonly type: DataSourceContactType;
  readonly vendorDataSourceId: number;
};

export enum DataSourceContactType {
  CREDENTIAL_OWNER = 'CREDENTIAL_OWNER',
  DELIVERY_OWNER = 'DELIVERY_OWNER',
}

export enum DataSourceType {
  DIRECT = 'DIRECT',
  EDI = 'EDI',
  FORWARD = 'FORWARD',
  RPA = 'RPA',
  UNKNOWN = 'UNKNOWN',
}

export type DataSpecification = {
  readonly interval: LocalInterval;
  readonly partnerEdgeMetrics: ReadonlyArray<PartnerEdgeMetric>;
  readonly partnerMetrics: ReadonlyArray<PartnerMetric>;
};

export enum DataStatusContentType {
  FILE = 'FILE',
  SCRAPER = 'SCRAPER',
}

export type DataStatusOptionalPartnersRequest = {
  readonly partnerIds: ReadonlyArray<number> | null;
};

export type DataStatusPartnerTabDateRangeRequest = {
  readonly dataStatusTabType: DataStatusTabType;
  readonly dateRange: LocalInterval;
  readonly partnerId: number | null;
};

export type DataStatusPartnerTabDateRequest = {
  readonly dataStatusTabType: DataStatusTabType;
  readonly date: string;
  readonly partnerId: number | null;
};

export type DataStatusPartnerTabVdsfDateRangeRequest = {
  readonly dataRangeByVendorDataSourceFiletypeId: {
    [key: string]: LocalInterval;
  };
  readonly dataStatusTabType: DataStatusTabType;
  readonly partnerId: number | null;
};

export type DataStatusPartnersTabRequest = {
  readonly dataStatusTabType: DataStatusTabType;
  readonly partnerIds: ReadonlyArray<number> | null;
};

export type DataStatusResponse = {
  readonly allFileStatuses: ReadonlyArray<FileDataStatus>;
  readonly dailyStatuses: ReadonlyArray<DailyDataStatus>;
  readonly missingDataStatusContent: ReadonlyArray<MissingDataStatusContent>;
  readonly scraperStatuses: ReadonlyArray<ScraperRunWithRetry>;
};

export enum DataStatusTabType {
  FORECAST = 'FORECAST',
  INVENTORY = 'INVENTORY',
  ORDERS = 'ORDERS',
  OTHER = 'OTHER',
  POS = 'POS',
}

export enum DataStatusV2 {
  DISCONNECTED = 'DISCONNECTED',
  EXPECTED = 'EXPECTED',
  FAILED = 'FAILED',
  FORWARD_FILE_FAILED = 'FORWARD_FILE_FAILED',
  FORWARD_FILE_MISSING = 'FORWARD_FILE_MISSING',
  IN_PROGRESS = 'IN_PROGRESS',
  NO_STATUS = 'NO_STATUS',
  SCRAPER_FAILED = 'SCRAPER_FAILED',
  SUCCESS = 'SUCCESS',
}

export type DataWarehouseDestination =
  | AzureStorageDestination
  | BigQueryExportConfiguration
  | DatabricksDataWarehouseDestination
  | RedshiftDataWarehouseDestination
  | SnowflakeDataWarehouseDestination;

export type DataWarehouseSyncStatus = {
  readonly currentErrors: ReadonlyArray<string>;
  readonly destinationId: number;
  readonly latestAttemptedTime: string | null;
  readonly latestSucceededTime: string | null;
};

export enum DataWarehouseType {
  AZURE_STORAGE = 'AZURE_STORAGE',
  BIGQUERY = 'BIGQUERY',
  DATABRICKS = 'DATABRICKS',
  REDSHIFT = 'REDSHIFT',
  SNOWFLAKE = 'SNOWFLAKE',
}

export type DataWarehouseValidationRequest = {
  readonly interval: LocalInterval;
  readonly partnerId: number | null;
  readonly warehouseTypes: ReadonlyArray<DataWarehouseType>;
};

export type DatabricksDataWarehouseDestination = {
  readonly deliveryChannel: DeliveryChannel;
  readonly id: number | null;
  readonly isActive: boolean;
  readonly modifierId: number;
  readonly name: string;
  readonly ownerId: number;
  readonly shareLevel: ShareLevel;
  readonly syncFrequencyConfig: SyncFrequencyConfig;
  readonly vendorId: number;
  readonly type: 'DATABRICKS';
  readonly databricksSharingIdentifier: string;
};

export type DatePeriod =
  | ComplexDatePeriod
  | FixedDatePeriod
  | FixedToNowDatePeriod
  | FutureDatePeriod
  | LastNDatePeriod
  | NextNDatePeriod
  | NowToFixedDatePeriod
  | PreviousDatePeriod
  | ToGoDatePeriod
  | ToDateDatePeriod
  | ToDateWeekendDatePeriod
  | TrailingDatePeriod;

export type DateValues = {
  readonly interval: ThinAttributeValue;
  readonly metricValues: ReadonlyArray<number>;
};

export enum DayOfWeek {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

export type DeleteTransactionRequest = {
  readonly ingestionIds: ReadonlyArray<number>;
};

export type DeleteUserRequest = {
  readonly emailToDelete: string;
  readonly newOwner: string | null;
};

export enum DeliveryChannel {
  AMAZON_S3 = 'AMAZON_S3',
  AZURE_STORAGE = 'AZURE_STORAGE',
  AZURE_STORAGE_EXPORT = 'AZURE_STORAGE_EXPORT',
  BIGQUERY = 'BIGQUERY',
  DATABRICKS = 'DATABRICKS',
  EMAIL = 'EMAIL',
  GCS = 'GCS',
  REDSHIFT = 'REDSHIFT',
  SFTP = 'SFTP',
  SNOWFLAKE = 'SNOWFLAKE',
}

export type DemandPlanSettings = {
  readonly adjustmentGranularity: CalendarUnit;
  readonly adjustmentMode: PlanAdjustmentMode;
  readonly attributes: ReadonlyArray<Attribute>;
  readonly calendar: RetailCalendarEnum;
  readonly computationFilters: ReadonlyArray<AttributeFilter>;
  readonly demandSourceKeyAttribute: Attribute;
  readonly forecastType: ForecastType;
  readonly frozenWindowWeeks: number;
  readonly granularity: CalendarUnit;
  readonly growthFactor: GrowthFactor;
  readonly historicalPeriod: SimplePeriod;
  readonly isDirectImportEnabled: boolean;
  readonly isPlanGenerationEnabled: boolean;
  readonly isVmiEnabled: boolean;
  readonly materializationPartitionParallelism: number;
  readonly unitConversionAttribute: Attribute | null;
  readonly vendorId: number;
  readonly visibilityPeriod: DatePeriod;
};

export type DiagnosticsRequest = {
  readonly calendar: RetailCalendarEnum;
  readonly evaluationDate: string;
  readonly filters: ReadonlyArray<AttributeFilter>;
  readonly unit: CalendarUnit;
};

export type DiagnosticsSettings = {
  readonly active: boolean;
  readonly scheduledTime: string;
  readonly vendorId: number;
};

export type DisaggregationStats = {
  readonly aggregateWeightsCacheMissesCount: number | null;
  readonly aggregateWeightsCacheMissesDurationMs: number | null;
  readonly count: number | null;
  readonly disaggregatorApplyCacheMissesCount: number | null;
  readonly disaggregatorApplyCacheMissesDurationMs: number | null;
  readonly disaggregatorApplyCount: number | null;
  readonly queryTransformAdditionalGroupingsCount: number | null;
  readonly queryTransformAllDisaggregablesCount: number | null;
  readonly queryTransformCount: number | null;
  readonly queryTransformDisaggregablesNeedingComputationCount: number | null;
  readonly queryTransformDisaggregablesNeedingPreComputationCount: number | null;
  readonly queryTransformDisaggregablesToDisaggregateCount: number | null;
  readonly queryTransformRequiredMetricsCount: number | null;
  readonly stagesCount: number | null;
  readonly totalDurationMs: number | null;
  readonly totalQueryTransformDurationMs: number | null;
  readonly totalTrieTransformDurationMs: number | null;
  readonly trieCreationCount: number | null;
  readonly trieItemCount: number | null;
  readonly valueIdTrieGroupingsCount: number | null;
};

export type DiscontinuedSku = {
  readonly attributeValues: ReadonlyArray<AttributeValue>;
  readonly startDate: string;
};

export enum DiscountType {
  ABSOLUTE = 'ABSOLUTE',
  RELATIVE = 'RELATIVE',
}

export type DisplayTransactionEvent = {
  readonly attributes: {
    [key: string]: ThinAttributeValue;
  };
  readonly datetime: string;
  readonly destination: ThinAttributeValue | null;
  readonly destinationPartner: ThinAttributeValue | null;
  readonly dollars: number | null;
  readonly ingestionId: number;
  readonly origin: ThinAttributeValue | null;
  readonly originPartner: ThinAttributeValue | null;
  readonly product: ThinAttributeValue;
  readonly status: TransactionStatus;
  readonly type: TransactionType;
  readonly units: number | null;
};

export enum DollarType {
  MANUFACTURING_COST = 'MANUFACTURING_COST',
  RETAIL_PRICE = 'RETAIL_PRICE',
  SELL_IN_PRICE = 'SELL_IN_PRICE',
}

export type DoubleCountingPartner = {
  readonly locationPartner: number;
  readonly productPartner: number;
};

export type EdgeDataDeletionRequest = {
  readonly destinationPartnerId: number | null;
  readonly interval: LocalInterval | null;
  readonly metricIds: ReadonlyArray<number> | null;
  readonly originPartnerId: number | null;
  readonly vendorId: number;
};

export type EdgeDataRange = {
  readonly original: boolean;
  readonly type: 'edge';
  readonly dataInterval: LocalInterval;
  readonly dataSourceType: EdgeDataSourceType;
  readonly destinationPartnerId: number | null;
  readonly intervalType: IntervalType;
  readonly lastUpdated: string | null;
  readonly metric: Metric;
  readonly metricId: number;
  readonly originPartnerId: number | null;
  readonly vendorId: number;
};

export enum EdgeDataSourceType {
  COMPUTED = 'COMPUTED',
  HISTORY = 'HISTORY',
  TRANSACTION = 'TRANSACTION',
}

export type EdgeRequest = {
  readonly destinationPartnerId: number | null;
  readonly interval: LocalInterval | null;
  readonly originPartnerId: number | null;
  readonly scheduledTime: string | null;
};

export type EdiChannel = {
  readonly id: number | null;
  readonly partnerEdiId: string;
  readonly partnerId: number;
  readonly unsupportedMessageType: ReadonlyArray<string>;
  readonly vendorEdiId: string;
  readonly vendorId: number;
};

export type EmailNotificationDeliveryChannel = {
  readonly type: 'EMAIL';
  readonly ccAccountIds: ReadonlyArray<UserId>;
};

export type EmailAllowlistAddress = {
  readonly address: string;
  readonly id: number | null;
  readonly vendorId: number;
};

export type EmailAllowlistHost = {
  readonly host: string;
  readonly id: number | null;
  readonly vendorId: number;
};

export type EmailSubscriptionUpdate = {
  readonly emailSubscriptionIdsToDelete: ReadonlyArray<number>;
  readonly emailSubscriptionsToSave: ReadonlyArray<EmailWidgetSubscription>;
};

export type EmailWidgetSubscription = {
  readonly type: 'EMAIL';
  readonly ccRecipients: ReadonlyArray<UserId>;
  readonly deliveryChannel: DeliveryChannel;
  readonly filetype: ExportFormat;
  readonly frequency: SubscriptionFrequency;
  readonly id: number | null;
  readonly includeAttachment: boolean;
  readonly modifierId: number;
  readonly name: string;
  readonly ownerId: number;
  readonly scheduledTime: string;
  readonly showMetricsAsRows: boolean;
  readonly tags: ReadonlyArray<string>;
  readonly toRecipients: ReadonlyArray<UserId>;
  readonly viewId: number | null;
  readonly viewSlug: string | null;
  readonly widgetId: number;
};

export enum EntitlementState {
  ENTITLEMENT_ACTIVATION_REQUESTED = 'ENTITLEMENT_ACTIVATION_REQUESTED',
  ENTITLEMENT_ACTIVE = 'ENTITLEMENT_ACTIVE',
  ENTITLEMENT_CANCELLED = 'ENTITLEMENT_CANCELLED',
  ENTITLEMENT_PENDING_CANCELLATION = 'ENTITLEMENT_PENDING_CANCELLATION',
  ENTITLEMENT_PENDING_PLAN_CHANGE_APPROVAL = 'ENTITLEMENT_PENDING_PLAN_CHANGE_APPROVAL',
  ENTITLEMENT_STATE_UNSPECIFIED = 'ENTITLEMENT_STATE_UNSPECIFIED',
  ENTITLEMENT_SUSPENDED = 'ENTITLEMENT_SUSPENDED',
}

export type Environment = {
  readonly environmentName: string;
  readonly gcpProject: string;
  readonly heapAppId: string;
  readonly intercomAppId: string;
  readonly mapboxToken: string;
  readonly mapboxUser: string;
  readonly sentryDsnAmalgam: string;
  readonly subEnvironmentName: string;
  readonly version: string;
  readonly zendeskWidgetKey: string;
};

export enum ErrorCode {
  BLOCKED = 'BLOCKED',
  EXPORT_ONLY = 'EXPORT_ONLY',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  TOO_MUCH_DATA = 'TOO_MUCH_DATA',
  UNKNOWN = 'UNKNOWN',
}

export type ErrorInfo = {
  readonly exceptionMessage: string | null;
  readonly format: string | null;
  readonly message: string | null;
  readonly stack: string | null;
};

export type ErrorMessage = {
  readonly code: string;
  readonly detail: string;
  readonly title: string;
};

export enum ErrorMetric {
  MAPE = 'MAPE',
  MEAN_ERROR = 'MEAN_ERROR',
  MEAN_PERCENTAGE_ERROR = 'MEAN_PERCENTAGE_ERROR',
  ROLLING_WAPE = 'ROLLING_WAPE',
  TRACKING_SIGNAL = 'TRACKING_SIGNAL',
  WAPE = 'WAPE',
}

export type ErrorResponse = {
  readonly errorCode: ErrorCode | null;
  readonly errors: ReadonlyArray<ErrorMessage> | null;
  readonly httpStatusCode: number;
  readonly isLoggingEnabled: boolean;
  readonly message: string | null;
  readonly stacktrace: string | null;
};

export type EventAnalysis = {
  readonly calculatedAt: string;
  readonly costAnalysis: CostAnalysis | null;
  readonly detailsAnalysis: ReadonlyArray<EventDetailAnalysis> | null;
  readonly inStockPercent: number | null;
  readonly liftAnalysis: LiftAnalysis;
  readonly performanceAnalysis: PerformanceAnalysis | null;
  readonly storeComplianceAnalysis: StoreComplianceAnalysis | null;
};

export type EventAnalysisPage = {
  readonly event: CalendarEvent;
  readonly salesBaselineMetricInstance: MetricInstance;
  readonly salesMetricInstance: MetricInstance;
  readonly salesUnitsBaselineMetricInstance: MetricInstance;
  readonly salesUnitsMetricInstance: MetricInstance;
};

export type EventDetailAnalysis = {
  readonly attributeValue: AttributeValue;
  readonly eventAnalysis: EventAnalysis;
};

export type EventFilter = {
  readonly maxExclusiveEnd: string | null;
  readonly maxStart: string | null;
  readonly minExclusiveEnd: string | null;
  readonly minStart: string | null;
  readonly searchQuery: string | null;
};

export type EventsTemplateRequest = {
  readonly detailAttribute: Attribute | null;
  readonly granularityAttributes: ReadonlyArray<Attribute>;
};

export type ExactValuesQuery = {
  readonly attribute: Attribute;
  readonly filters: ReadonlyArray<AttributeFilter>;
  readonly values: ReadonlyArray<string>;
};

export type Experiment = {
  readonly attribute: VirtualAttribute;
  readonly groupings: ReadonlyArray<AttributeInstance>;
  readonly id: number | null;
  readonly lastModifiedAt: string | null;
  readonly lastModifiedBy: number | null;
  readonly metrics: ReadonlyArray<MetricInstance>;
  readonly name: string;
  readonly ownerId: number | null;
  readonly postPeriodEndDate: string;
  readonly postPeriodStartDate: string;
  readonly shareLevel: ShareLevel;
  readonly slug: string | null;
  readonly tags: ReadonlyArray<string>;
};

export type ExportError = {
  readonly description: string | null;
  readonly type: ExportErrorType;
};

export enum ExportErrorType {
  ABORTED = 'ABORTED',
  CONNECTION_ISSUE = 'CONNECTION_ISSUE',
  INCOMPLETE_DATA = 'INCOMPLETE_DATA',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  SERVER_ERROR = 'SERVER_ERROR',
  TOO_MUCH_DATA = 'TOO_MUCH_DATA',
  UNKNOWN = 'UNKNOWN',
}

export enum ExportFormat {
  CSV = 'CSV',
  EXCEL = 'EXCEL',
}

export type ExportFormatTemplate = {
  readonly attributeMappings: ReadonlyArray<AttributeInstanceMapping>;
  readonly dateFormat: string | null;
  readonly fileIdentifier: string | null;
  readonly id: number | null;
  readonly includeHeader: boolean;
  readonly includeTimePeriodInHeader: boolean;
  readonly includeTotalsRow: boolean;
  readonly isVendorDefault: boolean;
  readonly lastModifiedAt: string | null;
  readonly metricMappings: ReadonlyArray<MetricInstanceMapping>;
  readonly name: string;
  readonly replaceNulls: boolean;
  readonly vendorId: number;
};

export type ExternalStorageWidgetSubscription = {
  readonly type: 'EXTERNAL_STORAGE';
  readonly calendar: RetailCalendarEnum | null;
  readonly credentialSetName: string | null;
  readonly currency: CurrencyCode;
  readonly deliveryChannel: DeliveryChannel;
  readonly destinationPath: string | null;
  readonly exportFormatId: number | null;
  readonly filetype: ExportFormat;
  readonly frequency: SubscriptionFrequency;
  readonly id: number | null;
  readonly isActive: boolean;
  readonly modifierId: number;
  readonly name: string;
  readonly ownerId: number;
  readonly scheduledTime: string;
  readonly shareLevel: ShareLevel;
  readonly tags: ReadonlyArray<string>;
  readonly unitConversionAttribute: Attribute | null;
  readonly vendorId: number;
  readonly viewId: number | null;
  readonly weekFormat: WeekFormat | null;
  readonly widget: Widget;
  readonly widgetId: number | null;
};

export type ExtractionRequest = {
  readonly extractor: Extractors.ExtractorDefinition;
  readonly files: ReadonlyArray<FileReference>;
};

export type ExtractorDefinition = {
  readonly archivedAt: string | null;
  readonly config: JSONB;
  readonly createdAt: string | null;
  readonly createdBy: number | null;
  readonly displayName: string;
  readonly filetype: string | null;
  readonly id: number | null;
  readonly releaseHistory: ReadonlyArray<ExtractorDefinitionRelease> | null;
  readonly version: number | null;
};

export type ExtractorDefinitionRelease = {
  readonly definitionId: number;
  readonly discontinuedAt: string | null;
  readonly discontinuedBy: number | null;
  readonly id: number | null;
  readonly notes: string | null;
  readonly releasedAt: string | null;
  readonly releasedBy: number | null;
  readonly supersededBy: number | null;
  readonly version: number;
};

export enum FactorType {
  INSTOCK_PERCENT_CHANGE = 'INSTOCK_PERCENT_CHANGE',
  LOCATION_COUNT_CHANGE = 'LOCATION_COUNT_CHANGE',
  MIX_SHIFT = 'MIX_SHIFT',
  PRICE_CHANGE = 'PRICE_CHANGE',
  PROMOTIONS = 'PROMOTIONS',
  SALES_PERFORMANCE = 'SALES_PERFORMANCE',
}

export type Feature = {
  readonly createdAt: string;
  readonly createdBy: number;
  readonly description: string;
  readonly externalUrl: string;
  readonly id: number | null;
  readonly name: string;
  readonly updatedAt: string;
  readonly updatedBy: number;
};

export type FeedAndRuns = {
  readonly feed: ScheduledFeed;
  readonly runs: ReadonlyArray<ScraperRun>;
};

export type FeedAndStatus = {
  readonly feed: ScheduledFeed;
  readonly scraperInstance: ScraperInstance;
  readonly status: ScraperStatus;
};

export type FeedIssueResponse = {
  readonly dataIssue: DataIssue;
  readonly type: 'feed';
  readonly feedAndStatus: FeedAndStatus;
};

export type Field = {
  readonly existingValue: string | null;
  readonly headerContext: GraphContext | null;
  readonly headerName: string;
  readonly originalValue: string | null;
  readonly value: string;
};

export type FieldDefinition = {
  readonly sourceType: FieldDefinitionType;
  readonly value: string;
};

export enum FieldDefinitionType {
  COLUMN_INDEX = 'COLUMN_INDEX',
  COLUMN_NAME = 'COLUMN_NAME',
  CRITERIA_BASED = 'CRITERIA_BASED',
  GENERATED_COLUMN = 'GENERATED_COLUMN',
  UNKNOWN = 'UNKNOWN',
}

export type FieldMapping = {
  readonly field: string;
  readonly fieldDefinitions: ReadonlyArray<FieldDefinition>;
};

export type FileAndVersion = {
  readonly fileId: number;
  readonly version: number | null;
};

export type FileDataStatus = {
  readonly dataStatus: FileDataStatusEnum;
  readonly endDate: string;
  readonly fileId: number;
  readonly fileName: string;
  readonly flushStatus: JobStatus | null;
  readonly isPendingFlush: boolean;
  readonly latestFileStatus: FileStatus;
  readonly latestVersionReceivedStatus: FileStatus;
  readonly partnerIdentifierMetricIntervals: ReadonlyArray<PartnerIdentifierMetricInterval>;
  readonly processedFileDetails: ProcessedFileDetails | null;
  readonly scraperRunId: number | null;
  readonly startDate: string;
};

export enum FileDataStatusEnum {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  MISSING = 'MISSING',
  SUCCESS = 'SUCCESS',
}

export enum FileExceptionCode {
  ALLOY_FILE_SUBSCRIBER_ERROR = 'ALLOY_FILE_SUBSCRIBER_ERROR',
  AMBIGUOUS_COLUMN = 'AMBIGUOUS_COLUMN',
  DATA_SUBSCRIBER_ERROR = 'DATA_SUBSCRIBER_ERROR',
  DUPLICATE_KEY = 'DUPLICATE_KEY',
  FILE_CLASSIFICATION_ERROR = 'FILE_CLASSIFICATION_ERROR',
  FILE_FORMATTING_ERROR = 'FILE_FORMATTING_ERROR',
  INCOMPLETE_MAPPING = 'INCOMPLETE_MAPPING',
  INCORRECT_DAY_OF_WEEK = 'INCORRECT_DAY_OF_WEEK',
  INVALID_ATTRIBUTE_VALUE = 'INVALID_ATTRIBUTE_VALUE',
  INVALID_ATTRIBUTE_VALUE_TYPE = 'INVALID_ATTRIBUTE_VALUE_TYPE',
  INVALID_DATE = 'INVALID_DATE',
  INVALID_FILE_STRUCTURE = 'INVALID_FILE_STRUCTURE',
  INVALID_METRIC_VALUE = 'INVALID_METRIC_VALUE',
  INVALID_ORIGINAL_TRANSITION = 'INVALID_ORIGINAL_TRANSITION',
  INVALID_UNIT_OF_MEASURE = 'INVALID_UNIT_OF_MEASURE',
  INVALID_VALUE = 'INVALID_VALUE',
  MISSING_COLUMN = 'MISSING_COLUMN',
  NO_DATA = 'NO_DATA',
  NO_PARTNER = 'NO_PARTNER',
  NO_PRODUCT = 'NO_PRODUCT',
  UNKNOWN_PARTNER = 'UNKNOWN_PARTNER',
  UNSUPPORTED_EDI_MESSAGE_TYPE = 'UNSUPPORTED_EDI_MESSAGE_TYPE',
}

export type FileIssueResponse = {
  readonly dataIssue: DataIssue;
  readonly type: 'file';
  readonly file: FileMetadata;
};

export type FileMetadata = {
  readonly filetype: string | null;
  readonly filetypeDisplayName: string | null;
  readonly id: number;
  readonly name: string;
  readonly parentFileId: number | null;
  readonly statuses: ReadonlyArray<FileStatus>;
  readonly subPath: string;
  readonly vendorDataSourceId: number | null;
  readonly vendorId: number | null;
  readonly versions: ReadonlyArray<FileVersion>;
};

export type FileQuery = {
  readonly augmentWithChildFiles: boolean;
  readonly endDate: string | null;
  readonly fileIds: ReadonlyArray<number> | null;
  readonly includeGeneratedFiles: boolean;
  readonly includeUnknownVendor: boolean;
  readonly limit: number | null;
  readonly matchFiletypeOrDataSource: boolean;
  readonly path: string | null;
  readonly paths: ReadonlyArray<string> | null;
  readonly sources: ReadonlyArray<FileSourceEnum>;
  readonly startDate: string | null;
  readonly statuses: ReadonlyArray<FileStatusEnum> | null;
  readonly textQuery: string | null;
  readonly types: ReadonlyArray<string> | null;
  readonly vendorDataSourceIds: ReadonlyArray<number> | null;
  readonly vendorIds: ReadonlyArray<number> | null;
};

export type FileReceiptStatusRequest = {
  readonly dataStatusTabType: DataStatusTabType;
  readonly partnerId: number | null;
  readonly receivedAtRange: LocalInterval;
  readonly zoneId: string;
};

export type FileReference = {
  readonly url: string;
};

export type FileRequest = {
  readonly fileId: number;
  readonly version: number | null;
};

export enum FileSourceEnum {
  AS2 = 'AS2',
  AWS = 'AWS',
  AZURE = 'AZURE',
  BOX = 'BOX',
  EMAIL = 'EMAIL',
  GENERATED = 'GENERATED',
  GOOGLE_STORAGE = 'GOOGLE_STORAGE',
  IMPORTER = 'IMPORTER',
  INCOMM = 'INCOMM',
  RPA = 'RPA',
  SFTP = 'SFTP',
  UNKNOWN = 'UNKNOWN',
  UPLOADER = 'UPLOADER',
}

export type FileStatus = {
  readonly dataIssueId: number | null;
  readonly exceptionCode: FileExceptionCode | null;
  readonly fileId: number;
  readonly fileVersion: number | null;
  readonly importIssueCounts: {
    [key: string]: number;
  } | null;
  readonly instanceName: string | null;
  readonly isCustomerFacing: boolean;
  readonly isHistorical: boolean | null;
  readonly message: string | null;
  readonly sentryEventIds: ReadonlyArray<string>;
  readonly sentryProject: string | null;
  readonly status: FileStatusEnum;
  readonly traceParent: string | null;
  readonly transitionedAt: string | null;
};

export enum FileStatusEnum {
  ABORTED = 'ABORTED',
  BOTS_FORWARDED = 'BOTS_FORWARDED',
  BOTS_TRANSLATED = 'BOTS_TRANSLATED',
  CLASSIFIED = 'CLASSIFIED',
  CREATED = 'CREATED',
  EXTRACTED = 'EXTRACTED',
  FAILURE = 'FAILURE',
  IMPORTED = 'IMPORTED',
  IMPORTING = 'IMPORTING',
  IN_PROGRESS = 'IN_PROGRESS',
  MISSING_EXTRACTOR = 'MISSING_EXTRACTOR',
  NEW = 'NEW',
  PARTIALLY_IMPORTED = 'PARTIALLY_IMPORTED',
  PENDING_IMPORT = 'PENDING_IMPORT',
  UNCLASSIFIED = 'UNCLASSIFIED',
  UNKNOWN = 'UNKNOWN',
  UNSUPPORTED = 'UNSUPPORTED',
}

export type FileTransitionRequest = {
  readonly fileIds: ReadonlyArray<number>;
  readonly message: string | null;
  readonly newFiletype: string | null;
  readonly status: FileStatusEnum;
  readonly transitionedAt: string | null;
};

export type FileTypeDataSummary = {
  readonly edgeRanges: ReadonlyArray<EdgeDataRange>;
  readonly filetype: string;
  readonly nodeRanges: ReadonlyArray<NodeDataRange>;
};

export type FileTypeMetadata = {
  readonly contentDisplayName: string;
  readonly dataSourceName: string | null;
  readonly deprecatedAt: string | null;
  readonly displayName: string;
  readonly fieldMap: ReadonlyArray<FieldMapping>;
  readonly isArchived: boolean;
  readonly isPublic: boolean;
  readonly name: string;
  readonly notes: string | null;
};

export type FileVersion = {
  readonly fileId: number;
  readonly md5Hash: string | null;
  readonly runId: number | null;
  readonly size: number | null;
  readonly source: FileSourceEnum;
  readonly version: number | null;
};

export type Filename = {
  readonly filename: string;
};

export type FilteredValuesQuery = {
  readonly attributeInstance: AttributeInstance;
  readonly crossTypeFilter: boolean | null;
  readonly filterText: string | null;
  readonly filters: ReadonlyArray<AttributeFilter>;
  readonly includeEmptyItemLocations: boolean;
  readonly limit: number;
  readonly offset: number;
  readonly removeDoubleCountingPartners: boolean;
  readonly sortOrder: SortOrder;
};

export type FixedDatePeriod = {
  readonly type: 'fixed';
  readonly end: string;
  readonly start: string;
};

export type FixedToNowDatePeriod = {
  readonly type: 'fixed_to_now';
  readonly start: string;
};

export type ForceIntervalTypeRequest = {
  readonly dayOfWeek: DayOfWeek | null;
  readonly intervalType: IntervalType;
  readonly metricIds: ReadonlyArray<number>;
  readonly partnerId: number;
};

export type ForecastDatePeriod =
  // This type is not user-facing.
  never;

export enum ForecastComposition {
  BASELINE = 'BASELINE',
  BASELINE_WITH_ADJUSTMENTS = 'BASELINE_WITH_ADJUSTMENTS',
  EVENT_LIFT = 'EVENT_LIFT',
  TOTAL = 'TOTAL',
}

export type ForecastConfig = {
  readonly active: boolean;
  readonly defaultScheduledTime: string;
  readonly forceForecastGranularity: boolean;
  readonly frequency: string;
  readonly granularity: ReadonlyArray<Attribute>;
  readonly id: number | null;
  readonly metrics: ReadonlyArray<Metric>;
  readonly params: any;
  readonly scheduledDayOfWeek: DayOfWeek;
  readonly vendorId: number;
  readonly weekOfMonthIndex: number | null;
};

export type ForecastRun = {
  readonly exceptionCode: string | null;
  readonly forecastConfigId: number;
  readonly id: number | null;
  readonly message: string | null;
  readonly metricId: number;
  readonly paramOverrides: string;
  readonly runDuration: number | null;
  readonly runTime: string | null;
  readonly scheduledTime: string;
  readonly status: ForecastRunStatusEnum;
  readonly vendorName: string | null;
};

export type ForecastRunFiles = {
  readonly fileId: number;
  readonly fileVersion: number;
  readonly filename: string;
};

export type ForecastRunStatus = {
  readonly exceptionCode: string | null;
  readonly message: string | null;
  readonly runId: number;
  readonly status: ForecastRunStatusEnum;
  readonly transitionedAt: string | null;
};

export enum ForecastRunStatusEnum {
  ABORTED = 'ABORTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  SCHEDULED = 'SCHEDULED',
}

export enum ForecastType {
  ALLOY = 'ALLOY',
  ANNUAL_GROWTH = 'ANNUAL_GROWTH',
  AOP = 'AOP',
  CONSENSUS = 'CONSENSUS',
  ENSEMBLE = 'ENSEMBLE',
  ETS = 'ETS',
  EXPERIMENTAL = 'EXPERIMENTAL',
  EXPERIMENTAL_TWO = 'EXPERIMENTAL_TWO',
  FIELD = 'FIELD',
  HISTORICAL_AVERAGE = 'HISTORICAL_AVERAGE',
  PARTNER = 'PARTNER',
  PLAN = 'PLAN',
  PROPHET = 'PROPHET',
  SEASONAL_HISTORICAL_AVERAGE = 'SEASONAL_HISTORICAL_AVERAGE',
  THETA = 'THETA',
  USAGE = 'USAGE',
  VENDOR = 'VENDOR',
}

export type FormDataBodyPart = {
  readonly contentDisposition: ContentDisposition;
  readonly entity: any;
  readonly formDataContentDisposition: FormDataContentDisposition;
  readonly headers: {
    [key: string]: ReadonlyArray<string>;
  };
  readonly mediaType: {
    readonly parameters: {
      [key: string]: string;
    };
    readonly subtype: string;
    readonly type: string;
    readonly wildcardSubtype: boolean;
    readonly wildcardType: boolean;
  };
  readonly messageBodyWorkers: MessageBodyWorkers;
  readonly name: string;
  readonly parameterizedHeaders: {
    [key: string]: ReadonlyArray<ParameterizedHeader>;
  };
  readonly parent: MultiPart;
  readonly providers: any;
  readonly simple: boolean;
  readonly value: string;
};

export type FormDataContentDisposition = {
  readonly creationDate: string;
  readonly fileName: string;
  readonly modificationDate: string;
  readonly name: string;
  readonly parameters: {
    [key: string]: string;
  };
  readonly readDate: string;
  readonly size: number;
  readonly type: string;
};

export type FullVendorDataIntegration = {
  readonly countries: ReadonlyArray<string>;
  readonly frequencyByFiletype: {
    [key: string]: ReadonlyArray<Cadence>;
  };
  readonly groupedFieldMappings: ReadonlyArray<GroupedFieldMapping>;
  readonly intervalTypesByFiletype: {
    [key: string]: ReadonlyArray<IntervalType>;
  };
  readonly vendorIntegration: VendorDataIntegration;
};

export type FutureDatePeriod = {
  readonly type: 'future';
  readonly amount: number;
  readonly unit: CalendarUnit;
};

export type GcsBucketInfo = {
  readonly bucketName: string;
  readonly exists: boolean;
  readonly url: string;
};

export type GenerateProductAttributeDerivationRegressionResultMessage = {
  readonly jobId: string;
  readonly message: string;
};

export type Grant = {
  readonly grantedAt: string;
  readonly grantedByUserId: number;
  readonly role: RbacRole;
};

export type GrantDefinition = {
  readonly roleId: number;
  readonly userId: number;
};

export enum GraphContext {
  DESTINATION = 'DESTINATION',
  DOWNSTREAM = 'DOWNSTREAM',
  ORIGIN = 'ORIGIN',
  UPSTREAM = 'UPSTREAM',
}

export type GraphEdge = {
  readonly destinationId: number;
  readonly originId: number;
  readonly productId: number | null;
};

export enum GraphPerspective {
  AT_LOCATION = 'AT_LOCATION',
  DOWNSTREAM = 'DOWNSTREAM',
  UPSTREAM = 'UPSTREAM',
}

export type Group = {
  readonly creationSource: GroupCreationSource;
  readonly filters: ReadonlyArray<AttributeFilter>;
  readonly id: number | null;
  readonly metricFilterGrouping: Attribute | null;
  readonly metricFilters: ReadonlyArray<MetricFilter>;
  readonly name: string;
  readonly partnerId: number | null;
};

export enum GroupCreationSource {
  DEFAULT_ATTRIBUTE_VALUE = 'DEFAULT_ATTRIBUTE_VALUE',
  EXPERIMENT_CONVERSE = 'EXPERIMENT_CONVERSE',
  FILE_IMPORTED = 'FILE_IMPORTED',
  MANUALLY_CREATED = 'MANUALLY_CREATED',
  SHIPPING_NETWORK = 'SHIPPING_NETWORK',
}

export type GroupedAttributeFieldMapping = {
  readonly displayName: string;
  readonly fieldDefinitions: ReadonlyArray<FieldDefinition>;
  readonly fieldGroup: string;
  readonly filetype: string;
  readonly type: 'ATTRIBUTE';
  readonly attributeDescription: AttributeDescription | null;
  readonly attributeType: AttributeType | null;
};

export type GroupedFieldMapping = GroupedAttributeFieldMapping | GroupedMetricFieldMapping;

export type GroupedMetricFieldMapping = {
  readonly displayName: string;
  readonly fieldDefinitions: ReadonlyArray<FieldDefinition>;
  readonly fieldGroup: string;
  readonly filetype: string;
  readonly type: 'METRIC';
  readonly currency: CurrencyCode | null;
  readonly metric: Metric;
};

export type GroupingAttribute = {
  readonly attribute?: Attribute | null;
  readonly graphContext?: GraphContext | null;
  readonly placeholderAttributeName?: string | null;
  readonly placeholderAttributeType?: AttributeType | null;
};

export type GrowthFactor = {
  readonly value: number;
};

export type HistoricalDatePeriod =
  // This type is not user-facing.
  never;

export type HistoricalStartingDatePeriod =
  // This type is not user-facing.
  never;

export type ImportHeader = {
  readonly graphContext: GraphContext | null;
  readonly isBuiltIn: boolean;
  readonly isDefaultAttribute: boolean;
  readonly isIdentifier: boolean;
  readonly isMetric: boolean;
  readonly isStarting: boolean;
  readonly itemType: ItemType | null;
  readonly name: string;
};

export type ImportIssue = {
  readonly description: string;
  readonly lineNumber: number;
  readonly severity: ImportIssueSeverity;
  readonly type: ImportIssueType;
};

export enum ImportIssueSeverity {
  ERROR = 'ERROR',
  LINE_ERROR = 'LINE_ERROR',
  USER_WARNING = 'USER_WARNING',
  WARNING = 'WARNING',
}

export enum ImportIssueType {
  CANNOT_ADD_PUBLIC_PRODUCT = 'CANNOT_ADD_PUBLIC_PRODUCT',
  CONCATENATED_IDENTIFIER_VALUE_MISMATCH = 'CONCATENATED_IDENTIFIER_VALUE_MISMATCH',
  CURRENCY_NOT_ENABLED = 'CURRENCY_NOT_ENABLED',
  DATA_QUALITY_EXCEPTION = 'DATA_QUALITY_EXCEPTION',
  DATE_OUT_OF_SCOPE = 'DATE_OUT_OF_SCOPE',
  DIRECT_IMPORT_TO_PARAMETERIZED_METRIC = 'DIRECT_IMPORT_TO_PARAMETERIZED_METRIC',
  DISALLOWED_TRANSACTION_IDENTIFIERS = 'DISALLOWED_TRANSACTION_IDENTIFIERS',
  DUPLICATED_HEADER = 'DUPLICATED_HEADER',
  DUPLICATED_ITEM = 'DUPLICATED_ITEM',
  DUPLICATED_TRANSACTION_IDENTIFIERS = 'DUPLICATED_TRANSACTION_IDENTIFIERS',
  EMPTY_GEOCODING_RESULT = 'EMPTY_GEOCODING_RESULT',
  EXTRA_ATTRIBUTE_VALUE = 'EXTRA_ATTRIBUTE_VALUE',
  FIELDS_WLL_BE_IGNORED = 'FIELDS_WLL_BE_IGNORED',
  FILE_PARTITION_TOO_LARGE = 'FILE_PARTITION_TOO_LARGE',
  FILE_TOO_LARGE = 'FILE_TOO_LARGE',
  GEOCODING_FAILURE = 'GEOCODING_FAILURE',
  IDENTIFIERS_NOT_SUPPORTED_IN_SNAPSHOTS = 'IDENTIFIERS_NOT_SUPPORTED_IN_SNAPSHOTS',
  INCOMPATIBLE_WITH_PLAN_GRANULARITY = 'INCOMPATIBLE_WITH_PLAN_GRANULARITY',
  INCONSISTENT_DOW = 'INCONSISTENT_DOW',
  INCONSISTENT_ORIGINAL_DERIVED = 'INCONSISTENT_ORIGINAL_DERIVED',
  INCORRECTLY_ORDERED_CONCATENATED_IDENTIFIERS = 'INCORRECTLY_ORDERED_CONCATENATED_IDENTIFIERS',
  INSUFFICIENT_PERMISSIONS = 'INSUFFICIENT_PERMISSIONS',
  INTERNAL_WARNING = 'INTERNAL_WARNING',
  INVALID_BUILTIN_UNTAGGABLE = 'INVALID_BUILTIN_UNTAGGABLE',
  INVALID_DATE = 'INVALID_DATE',
  INVALID_DIRECTED_IDENTIFIERS = 'INVALID_DIRECTED_IDENTIFIERS',
  INVALID_ECOMMERCE_MIGRATION_STATE = 'INVALID_ECOMMERCE_MIGRATION_STATE',
  INVALID_EVENT = 'INVALID_EVENT',
  INVALID_FILE = 'INVALID_FILE',
  INVALID_FILENAME_VENDOR = 'INVALID_FILENAME_VENDOR',
  INVALID_FILE_PARTNER_SCOPE = 'INVALID_FILE_PARTNER_SCOPE',
  INVALID_FILE_TYPE = 'INVALID_FILE_TYPE',
  INVALID_FROM_CURRENCY = 'INVALID_FROM_CURRENCY',
  INVALID_GTIN_IDENTIFIER_VALUE = 'INVALID_GTIN_IDENTIFIER_VALUE',
  INVALID_GTIN_LENGTH = 'INVALID_GTIN_LENGTH',
  INVALID_HEADER = 'INVALID_HEADER',
  INVALID_LOCATION_IDENTIFIER = 'INVALID_LOCATION_IDENTIFIER',
  INVALID_METRIC_HEADER = 'INVALID_METRIC_HEADER',
  INVALID_METRIC_VALUE = 'INVALID_METRIC_VALUE',
  INVALID_MULTI_IDENTIFIER_PRODUCT = 'INVALID_MULTI_IDENTIFIER_PRODUCT',
  INVALID_PARTNER_VALUE = 'INVALID_PARTNER_VALUE',
  INVALID_POSTAL_VALUE = 'INVALID_POSTAL_VALUE',
  INVALID_PREFIXED_GTIN_IDENTIFIER_VALUE = 'INVALID_PREFIXED_GTIN_IDENTIFIER_VALUE',
  INVALID_PRODUCT_ATTRIBUTES = 'INVALID_PRODUCT_ATTRIBUTES',
  INVALID_RECORD_DIRECTED_FIELDS = 'INVALID_RECORD_DIRECTED_FIELDS',
  INVALID_RECORD_FORBIDDEN_FIELD = 'INVALID_RECORD_FORBIDDEN_FIELD',
  INVALID_RECORD_MISSING_PAIRED_FIELD = 'INVALID_RECORD_MISSING_PAIRED_FIELD',
  INVALID_RECORD_MIXED_FIELDS = 'INVALID_RECORD_MIXED_FIELDS',
  INVALID_RECORD_NO_MATCH = 'INVALID_RECORD_NO_MATCH',
  INVALID_RECORD_UNDIRECTED_FIELDS = 'INVALID_RECORD_UNDIRECTED_FIELDS',
  INVALID_SNAPSHOT_METRIC = 'INVALID_SNAPSHOT_METRIC',
  INVALID_STATUS_OVERRIDE = 'INVALID_STATUS_OVERRIDE',
  INVALID_VALUE = 'INVALID_VALUE',
  INVALID_VALUE_EXPECTED_NON_DECIMAL = 'INVALID_VALUE_EXPECTED_NON_DECIMAL',
  INVALID_VALUE_TYPE = 'INVALID_VALUE_TYPE',
  MANAGING_CURRENCY_RATES_NOT_ENABLED = 'MANAGING_CURRENCY_RATES_NOT_ENABLED',
  MAX_RETRIES_EXCEEDED = 'MAX_RETRIES_EXCEEDED',
  MISSING_ATTRIBUTE = 'MISSING_ATTRIBUTE',
  MISSING_CURRENCY_FIELD = 'MISSING_CURRENCY_FIELD',
  MISSING_DATE_FOR_VERSIONED_METRIC_FIELD = 'MISSING_DATE_FOR_VERSIONED_METRIC_FIELD',
  MISSING_FIELD = 'MISSING_FIELD',
  MISSING_GEOCODING_FIELDS = 'MISSING_GEOCODING_FIELDS',
  MISSING_HEADER = 'MISSING_HEADER',
  MISSING_IDENTIFIER = 'MISSING_IDENTIFIER',
  MISSING_REQUIRED_TRANSACTION_ORIGIN_OR_DESTINATION_FIELD = 'MISSING_REQUIRED_TRANSACTION_ORIGIN_OR_DESTINATION_FIELD',
  MISSING_TRANSACTION_METRIC_FIELD = 'MISSING_TRANSACTION_METRIC_FIELD',
  MIXED_IDENTIFIERS_ATTRIBUTES = 'MIXED_IDENTIFIERS_ATTRIBUTES',
  MIXED_INTERVAL_TYPE = 'MIXED_INTERVAL_TYPE',
  MIXED_ITEM_TYPES_MASTER_RECORD = 'MIXED_ITEM_TYPES_MASTER_RECORD',
  MIXED_PARTNER_ATTRIBUTES = 'MIXED_PARTNER_ATTRIBUTES',
  MIXED_TRANSACTION_TYPE = 'MIXED_TRANSACTION_TYPE',
  MULTI_IDENTIFIER_PRODUCT_NON_IDENTIFIER_ATTRIBUTE_WARNING = 'MULTI_IDENTIFIER_PRODUCT_NON_IDENTIFIER_ATTRIBUTE_WARNING',
  MUST_MATCH_PLAN_GRANULARITY = 'MUST_MATCH_PLAN_GRANULARITY',
  NEW_ATTRIBUTES_WILL_BE_CREATED = 'NEW_ATTRIBUTES_WILL_BE_CREATED',
  NEW_ATTRIBUTE_FORBIDDEN = 'NEW_ATTRIBUTE_FORBIDDEN',
  NEW_ATTRIBUTE_NAME_SAME_ALPHA_NUMERIC = 'NEW_ATTRIBUTE_NAME_SAME_ALPHA_NUMERIC',
  NEW_ATTRIBUTE_NAME_SIMILAR_TO_EXISTING = 'NEW_ATTRIBUTE_NAME_SIMILAR_TO_EXISTING',
  NON_TAGGABLE_ATTRIBUTES = 'NON_TAGGABLE_ATTRIBUTES',
  NO_RECORDS_FOUND = 'NO_RECORDS_FOUND',
  NO_VENDOR_IDENTIFIER_ATTRIBUTE_DEFINED = 'NO_VENDOR_IDENTIFIER_ATTRIBUTE_DEFINED',
  OVERLAPPING_CONVERSION_RATES = 'OVERLAPPING_CONVERSION_RATES',
  OVERLAPPING_PRODUCT_LOCATION_SNAPSHOT = 'OVERLAPPING_PRODUCT_LOCATION_SNAPSHOT',
  OVERLAPPING_SNAPSHOT = 'OVERLAPPING_SNAPSHOT',
  PRODUCT_COLLISION_ERROR = 'PRODUCT_COLLISION_ERROR',
  PRODUCT_COLLISION_WARNING = 'PRODUCT_COLLISION_WARNING',
  PUBSUB_EXCEPTION = 'PUBSUB_EXCEPTION',
  RECORD_TYPE_FORBIDDEN = 'RECORD_TYPE_FORBIDDEN',
  RECORD_TYPE_NOT_SUPPORTED = 'RECORD_TYPE_NOT_SUPPORTED',
  RESTATEMENTS_CLASH_VPM_DATE_SCHEDULED_FEED = 'RESTATEMENTS_CLASH_VPM_DATE_SCHEDULED_FEED',
  RESTATEMENTS_CLASH_VPM_DATE_VENDOR_DATA_SOURCE = 'RESTATEMENTS_CLASH_VPM_DATE_VENDOR_DATA_SOURCE',
  SNAPSHOT_CONFLICT = 'SNAPSHOT_CONFLICT',
  TOO_MANY_GEOCODING_RESULTS = 'TOO_MANY_GEOCODING_RESULTS',
  TOO_MANY_LINE_ERRORS_OR_WARNINGS = 'TOO_MANY_LINE_ERRORS_OR_WARNINGS',
  TOO_MANY_PRODUCT_MATCHES_CREATED = 'TOO_MANY_PRODUCT_MATCHES_CREATED',
  UNHANDLED_EXCEPTION = 'UNHANDLED_EXCEPTION',
  UNKNOWN_LOCATION = 'UNKNOWN_LOCATION',
  UNKNOWN_LOCATION_WARNING = 'UNKNOWN_LOCATION_WARNING',
  UNKNOWN_PARTNER = 'UNKNOWN_PARTNER',
  UNKNOWN_PRODUCT = 'UNKNOWN_PRODUCT',
  UNSUPPORTED_CURRENCY = 'UNSUPPORTED_CURRENCY',
  WRONG_FIELD_COUNT = 'WRONG_FIELD_COUNT',
}

export type ImportPayload = {
  readonly headers: ReadonlyArray<ImportHeader>;
  readonly records: ReadonlyArray<ImportRecord>;
};

export type ImportRecord = {
  readonly fields: ReadonlyArray<Field>;
  readonly lineNumber: number;
  readonly segmentIdentifiers: {
    [key: string]: string;
  };
  readonly type: RecordType;
};

export type ImportResult = {
  readonly isImportable: boolean;
  readonly issues: ReadonlyArray<ImportIssue>;
  readonly payload: ImportPayload | null;
};

export type InAppNotificationDeliveryChannel = {
  readonly type: 'IN_APP';
};

export enum InflowType {
  FORECASTED_ORDERS = 'FORECASTED_ORDERS',
  FORECASTED_RECEIPTS = 'FORECASTED_RECEIPTS',
  FORECASTED_SHIPMENTS = 'FORECASTED_SHIPMENTS',
  LATE_REQUESTED_RECEIPTS = 'LATE_REQUESTED_RECEIPTS',
  LATE_SCHEDULED_RECEIPTS = 'LATE_SCHEDULED_RECEIPTS',
  PLANNED_RECEIPTS = 'PLANNED_RECEIPTS',
  PLANNED_SHIPMENTS = 'PLANNED_SHIPMENTS',
  PROJECTED_RECEIPTS_IN_TRANSIT = 'PROJECTED_RECEIPTS_IN_TRANSIT',
  REQUESTED_RECEIPTS = 'REQUESTED_RECEIPTS',
  REQUESTED_RECEIPTS_IN_TRANSIT = 'REQUESTED_RECEIPTS_IN_TRANSIT',
  REQUESTED_RECEIPTS_ON_ORDER = 'REQUESTED_RECEIPTS_ON_ORDER',
  SCHEDULED_RECEIPTS = 'SCHEDULED_RECEIPTS',
  SCHEDULED_RECEIPTS_IN_TRANSIT = 'SCHEDULED_RECEIPTS_IN_TRANSIT',
  SCHEDULED_RECEIPTS_ON_ORDER = 'SCHEDULED_RECEIPTS_ON_ORDER',
  SCHEDULED_SHIPMENTS = 'SCHEDULED_SHIPMENTS',
}

export enum InformationType {
  B2B = 'B2B',
  B2C = 'B2C',
  B2G = 'B2G',
  UNKNOWN = 'UNKNOWN',
}

export type IngestionTransactionLineItem = {
  readonly attributes: {
    [key: string]: string;
  };
  readonly currency: CurrencyCode;
  readonly date: string;
  readonly derivationId: number | null;
  readonly destinationId: number | null;
  readonly destinationPartnerId: number | null;
  readonly dollars: number | null;
  readonly fileId: number | null;
  readonly id: number;
  readonly identifiers: {
    [key: string]: string;
  };
  readonly originId: number | null;
  readonly originPartnerId: number | null;
  readonly partnerId: number;
  readonly productId: number | null;
  readonly type: TransactionType | null;
  readonly units: number | null;
  readonly vendorId: number;
};

export type Insight = {
  readonly change: number;
  readonly eventId: number | null;
  readonly factorType: FactorType;
  readonly id: number | null;
  readonly importance: number;
  readonly isPositive: boolean;
  readonly segments: ReadonlyArray<InsightSegment>;
  readonly significance: number;
  readonly sliceInfo: SliceInfo;
};

export type InsightMetricInstanceConfig = {
  readonly metricInstance: MetricInstance;
  readonly useReverseColors?: boolean | null;
};

export type InsightSegment = {
  readonly type: InsightSegmentType;
  readonly value: string | null;
};

export enum InsightSegmentType {
  BOLD_TEXT = 'BOLD_TEXT',
  CATEGORY_VALUE = 'CATEGORY_VALUE',
  CHANNEL_VALUE = 'CHANNEL_VALUE',
  PARTNER_VALUE = 'PARTNER_VALUE',
  PRODUCT_VALUE = 'PRODUCT_VALUE',
  TEXT = 'TEXT',
  VALUE = 'VALUE',
}

export type IntervalDisplayNameRequest = {
  readonly calendar: RetailCalendarEnum;
  readonly evaluationDate: string;
  readonly isTimeAgoCalendarPeriodAligned: boolean | null;
  readonly periods: ReadonlyArray<DatePeriod>;
  readonly timeAgo: SimplePeriod | null;
};

export enum IntervalType {
  DAILY = 'DAILY',
  MIXED = 'MIXED',
  WEEKLY = 'WEEKLY',
}

export enum InventoryMeasure {
  COST = 'COST',
  RETAIL = 'RETAIL',
  UNITS = 'UNITS',
}

export type InventoryPlanSettings = {
  readonly adjustmentGranularity: CalendarUnit;
  readonly attributes: ReadonlyArray<Attribute>;
  readonly calendar: RetailCalendarEnum;
  readonly computationFilters: ReadonlyArray<AttributeFilter>;
  readonly granularity: CalendarUnit;
  readonly isFastSyncEnabled: boolean;
  readonly isPlanGenerationEnabled: boolean;
  readonly materializationPartitionParallelism: number;
  readonly orderFrequencyWeeks: number | null;
  readonly supplierAttributeId: number | null;
  readonly unitConversionAttribute: Attribute | null;
  readonly vendorId: number;
  readonly visibilityPeriod: DatePeriod;
};

export enum InventoryType {
  ALLOCATED = 'ALLOCATED',
  AVAILABLE = 'AVAILABLE',
  EXPECTED_ON_HAND = 'EXPECTED_ON_HAND',
  INTERNAL_ON_ORDER = 'INTERNAL_ON_ORDER',
  IN_TRANSIT = 'IN_TRANSIT',
  ON_HAND = 'ON_HAND',
  ON_ORDER = 'ON_ORDER',
}

export type IssueResponse = FeedIssueResponse | FileIssueResponse;

export enum ItemType {
  LOCATION = 'LOCATION',
  PRODUCT = 'PRODUCT',
  SEGMENT = 'SEGMENT',
  TRANSACTION_EVENT = 'TRANSACTION_EVENT',
  VIRTUAL_ATTRIBUTE = 'VIRTUAL_ATTRIBUTE',
}

export type JSONB = any;

export type Job = {
  readonly createdAt: string | null;
  readonly id: string;
  readonly instanceName: string | null;
  readonly lastUpdated: string | null;
  readonly priority: JobPriority;
  readonly scheduledTime: string | null;
  readonly schedulerRank: JobSchedulerRank | null;
  readonly slackRecipient: string | null;
  readonly status: JobStatusEnum | null;
  readonly stringifiedMetadata: string;
  readonly trace: string | null;
  readonly type: JobType;
  readonly vendorId: number;
};

export type JobLock = {
  readonly createdAt: string | null;
  readonly createdBy: number | null;
  readonly id: number | null;
  readonly metadata: string | null;
  readonly reason: string | null;
  readonly type: JobType;
  readonly updatedAt: string | null;
  readonly vendorId: number | null;
};

export enum JobPriority {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export type JobQuery = {
  readonly limit: number | null;
  readonly since: string | null;
  readonly statuses: ReadonlyArray<JobStatusEnum>;
  readonly types: ReadonlyArray<JobType>;
  readonly vendorIds: ReadonlyArray<number>;
};

export type JobSchedulerRank = {
  readonly isBlocked: boolean;
  readonly rank: number;
};

export type JobStatus = {
  readonly jobId: string;
  readonly status: JobStatusEnum;
  readonly transitionedAt: string | null;
};

export enum JobStatusEnum {
  ABORTED = 'ABORTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',
}

export enum JobType {
  APPLY_PLAN_CHANGES = 'APPLY_PLAN_CHANGES',
  AUGMENT_TRANSACTION_EVENTS = 'AUGMENT_TRANSACTION_EVENTS',
  BIGQUERY_CHECKSUM = 'BIGQUERY_CHECKSUM',
  BIGQUERY_CURRENCY_CONVERSION_RATE_EXPORT = 'BIGQUERY_CURRENCY_CONVERSION_RATE_EXPORT',
  BIGQUERY_CURRENCY_METRIC_EXPORT = 'BIGQUERY_CURRENCY_METRIC_EXPORT',
  BIGQUERY_GRAPH_PATH_EXPORT = 'BIGQUERY_GRAPH_PATH_EXPORT',
  BIGQUERY_LOCATION_EXPORT = 'BIGQUERY_LOCATION_EXPORT',
  BIGQUERY_METRIC_SNAPSHOT_EXPORT = 'BIGQUERY_METRIC_SNAPSHOT_EXPORT',
  BIGQUERY_PRODUCT_EXPORT = 'BIGQUERY_PRODUCT_EXPORT',
  BIGQUERY_RECREATE_ROLLUP_VIEWS = 'BIGQUERY_RECREATE_ROLLUP_VIEWS',
  BIGQUERY_REFRESH_LOCATION_TABLE = 'BIGQUERY_REFRESH_LOCATION_TABLE',
  BIGQUERY_SEGMENT_EXPORT = 'BIGQUERY_SEGMENT_EXPORT',
  BIGQUERY_TRANSACTION_DUPLICATE_CHECK = 'BIGQUERY_TRANSACTION_DUPLICATE_CHECK',
  BULK_CHANGE_ALLOY_EMPLOYEE_ROLES = 'BULK_CHANGE_ALLOY_EMPLOYEE_ROLES',
  CLEAR_WALMART_RECOMMENDATIONS_CACHE = 'CLEAR_WALMART_RECOMMENDATIONS_CACHE',
  COMPUTE_HISTORICAL_SALES_DIAGNOSTICS = 'COMPUTE_HISTORICAL_SALES_DIAGNOSTICS',
  COMPUTE_LOCATION_TYPE_FIELD_MAPPINGS = 'COMPUTE_LOCATION_TYPE_FIELD_MAPPINGS',
  COPY_RAW_NODE_DATA = 'COPY_RAW_NODE_DATA',
  COPY_RAW_NODE_DATA_FOR_VENDOR_PARTNER = 'COPY_RAW_NODE_DATA_FOR_VENDOR_PARTNER',
  CREATE_PLAN = 'CREATE_PLAN',
  DELETE_DATA = 'DELETE_DATA',
  DELETE_EDGE_AGGREGATIONS = 'DELETE_EDGE_AGGREGATIONS',
  DELETE_MATERIALIZED_METRICS = 'DELETE_MATERIALIZED_METRICS',
  DELETE_NODE_AGGREGATIONS = 'DELETE_NODE_AGGREGATIONS',
  DELIVER_NOTIFICATIONS = 'DELIVER_NOTIFICATIONS',
  DERIVE_LOCATION_ATTRIBUTES = 'DERIVE_LOCATION_ATTRIBUTES',
  DERIVE_PRODUCT_ATTRIBUTES = 'DERIVE_PRODUCT_ATTRIBUTES',
  DERIVE_SEGMENT_ATTRIBUTES = 'DERIVE_SEGMENT_ATTRIBUTES',
  DERIVE_SHIPPING_LANES = 'DERIVE_SHIPPING_LANES',
  DERIVE_TRANSACTION_EVENTS = 'DERIVE_TRANSACTION_EVENTS',
  DERIVE_TRANSACTION_EVENT_ATTRIBUTES = 'DERIVE_TRANSACTION_EVENT_ATTRIBUTES',
  ENABLE_ECOMMERCE_MIGRATION_FOR_VENDOR = 'ENABLE_ECOMMERCE_MIGRATION_FOR_VENDOR',
  EVENT_ANALYSIS = 'EVENT_ANALYSIS',
  EXTERNAL_BIGQUERY_EXPORT = 'EXTERNAL_BIGQUERY_EXPORT',
  EXTERNAL_DATAWAREHOUSE_SYNC = 'EXTERNAL_DATAWAREHOUSE_SYNC',
  FLUSH_EDGE_DATA = 'FLUSH_EDGE_DATA',
  FLUSH_JOB_DATA = 'FLUSH_JOB_DATA',
  FLUSH_NODE_DATA = 'FLUSH_NODE_DATA',
  FORCE_INTERVAL_TYPE = 'FORCE_INTERVAL_TYPE',
  FULFILL_SUBSCRIPTIONS = 'FULFILL_SUBSCRIPTIONS',
  GENERATE_PRODUCT_ATTRIBUTE_DERIVATION_REGRESSION_CSV = 'GENERATE_PRODUCT_ATTRIBUTE_DERIVATION_REGRESSION_CSV',
  MATERIALIZE_COMPLETED_PLANS = 'MATERIALIZE_COMPLETED_PLANS',
  MATERIALIZE_METRIC_SNAPSHOTS = 'MATERIALIZE_METRIC_SNAPSHOTS',
  MATERIALIZE_NPI = 'MATERIALIZE_NPI',
  MATERIALIZE_PLAN_SPREADSHEET = 'MATERIALIZE_PLAN_SPREADSHEET',
  MATERIALIZE_VIRTUAL_ATTRIBUTES = 'MATERIALIZE_VIRTUAL_ATTRIBUTES',
  MOVE_HISTORICAL_PENDING_RANGES = 'MOVE_HISTORICAL_PENDING_RANGES',
  NOOP = 'NOOP',
  PARTITIONING_CLUSTERING_BIGQUERY_MIGRATION = 'PARTITIONING_CLUSTERING_BIGQUERY_MIGRATION',
  POPULATE_DEMAND_INVENTORY_PLANNING_CONNECTED_PATHS = 'POPULATE_DEMAND_INVENTORY_PLANNING_CONNECTED_PATHS',
  POPULATE_EDGES = 'POPULATE_EDGES',
  POPULATE_PRODUCT_LOCATIONS = 'POPULATE_PRODUCT_LOCATIONS',
  REBUILD_PLANS = 'REBUILD_PLANS',
  REBUILD_TRANSACTION_PARTNER_EDGES = 'REBUILD_TRANSACTION_PARTNER_EDGES',
  RELEASE_PREP = 'RELEASE_PREP',
  RESCHEDULE_SUBSCRIPTIONS = 'RESCHEDULE_SUBSCRIPTIONS',
  RESOLVE_DATA_ISSUE_NOTIFICATIONS = 'RESOLVE_DATA_ISSUE_NOTIFICATIONS',
  RUN_ATTRIBUTE_VALUE_PRECACHER_FOR_VENDOR = 'RUN_ATTRIBUTE_VALUE_PRECACHER_FOR_VENDOR',
  RUN_SALES_DIAGNOSTICS = 'RUN_SALES_DIAGNOSTICS',
  RUN_VIEW_PRECACHER_FOR_VENDOR = 'RUN_VIEW_PRECACHER_FOR_VENDOR',
  RUN_WALMART_RECOMMENDATIONS_PRECACHER = 'RUN_WALMART_RECOMMENDATIONS_PRECACHER',
  SUMMARIZE_DATA_FROM_DATA_SOURCE = 'SUMMARIZE_DATA_FROM_DATA_SOURCE',
  UNKNOWN = 'UNKNOWN',
  UPDATE_EDGE_AGGREGATIONS = 'UPDATE_EDGE_AGGREGATIONS',
  UPDATE_EDGE_AGGREGATIONS_FOR_VENDOR_PARTNER = 'UPDATE_EDGE_AGGREGATIONS_FOR_VENDOR_PARTNER',
  UPDATE_EPOCH = 'UPDATE_EPOCH',
  UPDATE_ITEM_EXCLUSIONS = 'UPDATE_ITEM_EXCLUSIONS',
  UPDATE_NODE_AGGREGATIONS = 'UPDATE_NODE_AGGREGATIONS',
  UPDATE_NODE_AGGREGATIONS_FOR_VENDOR_PARTNER = 'UPDATE_NODE_AGGREGATIONS_FOR_VENDOR_PARTNER',
  VALIDATE_DATAWAREHOUSE_DATA = 'VALIDATE_DATAWAREHOUSE_DATA',
  VALIDATE_PLAN_MATERIALIZATION = 'VALIDATE_PLAN_MATERIALIZATION',
  VIEW_VALIDATION = 'VIEW_VALIDATION',
}

export type JwtToken = {
  readonly domain: string;
  readonly email: string;
  readonly expires: number;
  readonly issued: number;
  readonly issuedInitially: number;
  readonly token: string;
  readonly type: JwtTokenType;
  readonly xsrfToken: string | null;
};

export enum JwtTokenType {
  ACTIVATION = 'ACTIVATION',
  API = 'API',
  API_SERVICE_ACCOUNT = 'API_SERVICE_ACCOUNT',
  PASSWORD_RESET = 'PASSWORD_RESET',
  WEB = 'WEB',
}

export type LandingPageDefault = {
  readonly featureFlag: Feature | null;
  readonly functionalAreaRegex: string;
  readonly name: string;
  readonly viewId: number;
};

export type LandingPageDefaults = {
  readonly defaults: ReadonlyArray<LandingPageDefault>;
};

export type LastNDatePeriod = {
  readonly type: 'lastn';
  readonly amount: number;
  readonly unit: CalendarUnit;
};

export type LastReceivedFile = {
  readonly cadence: Cadence;
  readonly contactEmail: string | null;
  readonly contactName: string | null;
  readonly credentialSetName: string | null;
  readonly dataSource: string | null;
  readonly dataSourceType: DataSourceType;
  readonly daysSinceLastReceived: number | null;
  readonly deprecatedAt: string | null;
  readonly expectedTimeReceived: string | null;
  readonly fileType: string | null;
  readonly fileTypeDisplayName: string | null;
  readonly isLatestFileMissing: boolean;
  readonly lastReceived: string | null;
  readonly lastReceivedFileId: number | null;
  readonly status: FileStatusEnum;
  readonly vendorDataSourceFiletypeId: number | null;
  readonly vendorDataSourceId: number | null;
};

export type LayoutParams = {
  readonly h: number;
  readonly w: number;
  readonly x: number;
  readonly y: number;
};

export enum LayoutType {
  FLOW = 'FLOW',
  GRID = 'GRID',
  HORIZONTAL = 'HORIZONTAL',
  LANDING_PAGE = 'LANDING_PAGE',
  VERTICAL = 'VERTICAL',
}

export type LiftAnalysis = {
  readonly dollarSalesChange: MetricValueChange;
  readonly unitSalesChange: MetricValueChange;
};

export type LineItem = {
  readonly calculation: Calculation | null;
  readonly name: string;
  readonly total: number;
};

export type LocalInterval = {
  readonly end: string;
  readonly start: string;
};

export type LocationMasterQueryWithFilters = {
  readonly attributeFilters: ReadonlyArray<AttributeFilter>;
  readonly locationAttributes: ReadonlyArray<Attribute>;
};

export enum LocationType {
  COMMERCIAL = 'COMMERCIAL',
  DEFAULT_RETAIL = 'DEFAULT_RETAIL',
  DIRECT_SALE = 'DIRECT_SALE',
  DIRECT_TO_COMMERCIAL = 'DIRECT_TO_COMMERCIAL',
  DISTRIBUTION_CENTER = 'DISTRIBUTION_CENTER',
  E_COMMERCE = 'E_COMMERCE',
  INTERNET = 'INTERNET',
  MANUFACTURER = 'MANUFACTURER',
  MANUFACTURING_PLANT = 'MANUFACTURING_PLANT',
  STORE = 'STORE',
  WAREHOUSE = 'WAREHOUSE',
}

export type LoginResponse = {
  readonly clientName: string | null;
  readonly jwt: JwtToken | null;
};

export type MarketingAttribute = {
  readonly description: string | null;
  readonly itemType: string;
  readonly name: string;
};

export type MarketingDataImages = {
  readonly logo: string | null;
  readonly screenshot: string | null;
};

export type MarketingDataImagesResponse = {
  readonly apiVersion: number;
  readonly imagesBySlug: {
    [key: string]: MarketingDataImages;
  };
};

export type MarketingDataIntegration = {
  readonly attributesByLocationType: {
    [key: string]: ReadonlyArray<MarketingAttribute>;
  };
  readonly cadence: string | null;
  readonly countries: ReadonlyArray<string>;
  readonly derivedMetrics: ReadonlyArray<MarketingMetric>;
  readonly description: string | null;
  readonly id: number;
  readonly integrationType: DataIntegrationType;
  readonly intervalType: IntervalType | null;
  readonly marketingName: string;
  readonly metricDataByLocationType: {
    [key: string]: MarketingDataIntegrationMetricsData;
  };
  readonly name: string;
  readonly portalName: string | null;
  readonly portalUrl: string | null;
  readonly restatementDays: number | null;
  readonly slug: string;
};

export type MarketingDataIntegrationMetricsData = {
  readonly categories: ReadonlyArray<string>;
  readonly metrics: ReadonlyArray<MarketingMetric>;
};

export type MarketingDataIntegrationsResponse = {
  readonly apiVersion: number;
  readonly integrations: ReadonlyArray<MarketingDataIntegration>;
};

export type MarketingMetric = {
  readonly description: string | null;
  readonly displayName: string;
  readonly name: string;
};

export type MatchingIdentifiersRequest = {
  readonly attributes: ReadonlyArray<number>;
  readonly force: boolean;
};

export type MaterializationSyncRequest = {
  readonly interval: LocalInterval;
  readonly metrics: ReadonlyArray<string>;
  readonly path: ReadonlyArray<AttributeValue>;
  readonly planId: number;
};

export type MaterializationSyncResult = {
  readonly result: ReadonlyArray<ReadonlyArray<number>>;
};

export type MessageBodyWorkers = any;

export type Metric = {
  readonly categories: ReadonlyArray<MetricCategory>;
  readonly destinationMetric: ThinMetric | null;
  readonly displayName: string;
  readonly displayType: MetricDisplayType;
  readonly features: ReadonlyArray<MetricFeature>;
  readonly forecastType: ForecastType | null;
  readonly forecastedMetrics: ReadonlyArray<Metric>;
  readonly id: number;
  readonly name: string;
  readonly optionalArguments: ReadonlyArray<MetricArgument>;
  readonly originMetric: ThinMetric | null;
  readonly requiredArguments: ReadonlyArray<MetricArgument>;
  readonly targetMetricName: string | null;
  readonly transactionStatuses: ReadonlyArray<TransactionStatus> | null;
  readonly transactionType: TransactionType | null;
  readonly type: MetricType;
};

export enum MetricArgument {
  attributeFilters = 'attributeFilters',
  childMetrics = 'childMetrics',
  comparisonMetrics = 'comparisonMetrics',
  convertCurrency = 'convertCurrency',
  currency = 'currency',
  demandSignalPerspective = 'demandSignalPerspective',
  dollarType = 'dollarType',
  errorMetric = 'errorMetric',
  fillGaps = 'fillGaps',
  forecastComposition = 'forecastComposition',
  forecastType = 'forecastType',
  granularity = 'granularity',
  groupings = 'groupings',
  growthFactor = 'growthFactor',
  historicalPeriod = 'historicalPeriod',
  includeNewProductForecasts = 'includeNewProductForecasts',
  inflowTypes = 'inflowTypes',
  inventoryMeasure = 'inventoryMeasure',
  inventoryTypes = 'inventoryTypes',
  isTimeAgoCalendarPeriodAligned = 'isTimeAgoCalendarPeriodAligned',
  leadTime = 'leadTime',
  locationType = 'locationType',
  needsTimeSeries = 'needsTimeSeries',
  outOfStockBehaviour = 'outOfStockBehaviour',
  outOfStockCalculationMethod = 'outOfStockCalculationMethod',
  outflowTypes = 'outflowTypes',
  period = 'period',
  returnsCountingMethod = 'returnsCountingMethod',
  salesType = 'salesType',
  stockAggregator = 'stockAggregator',
  storeType = 'storeType',
  supplyTargetPeriod = 'supplyTargetPeriod',
  timeAgo = 'timeAgo',
  timeUnit = 'timeUnit',
  unitConversionAttribute = 'unitConversionAttribute',
  versionRecency = 'versionRecency',
}

export type MetricArguments = {
  readonly attributeFilters: ReadonlyArray<AttributeFilter> | null;
  readonly childMetrics: ReadonlyArray<Metric> | null;
  readonly comparisonMetrics: ReadonlyArray<MetricInstance> | null;
  readonly convertCurrency: boolean | null;
  readonly currency: CurrencyCode | null;
  readonly demandSignalPerspective: GraphPerspective | null;
  readonly dollarType: DollarType | null;
  readonly errorMetric: ErrorMetric | null;
  readonly forecastComposition: ForecastComposition | null;
  readonly forecastType: ForecastType | null;
  readonly granularity: CalendarUnit | null;
  readonly groupings: ReadonlyArray<AttributeInstance> | null;
  readonly growthFactor: GrowthFactor | null;
  readonly historicalPeriod: SimplePeriod | null;
  readonly inflowTypes: ReadonlyArray<InflowType> | null;
  readonly inventoryMeasure: InventoryMeasure | null;
  readonly inventoryTypes: ReadonlyArray<InventoryType> | null;
  readonly isTimeAgoCalendarPeriodAligned: boolean | null;
  readonly leadTime: SimplePeriod | null;
  readonly locationType: LocationType | null;
  readonly outOfStockBehaviour: OutOfStockBehaviour | null;
  readonly outOfStockCalculationMethod: OutOfStockCalculationMethod | null;
  readonly outflowTypes: ReadonlyArray<OutflowType> | null;
  readonly period: DatePeriod;
  readonly returnsCountingMethod: ReturnsCountingMethod | null;
  readonly salesType: SalesType | null;
  readonly stockAggregator: StockAggregator | null;
  readonly storeType: StoreType | null;
  readonly supplyTargetPeriod: SimplePeriod | null;
  readonly timeAgo: SimplePeriod | null;
  readonly timeUnit: CalendarUnit | null;
  readonly unitConversionAttribute: Attribute | null;
  readonly versionRecency: VersionRecency | null;
};

export enum MetricCategory {
  ACTIVE_ITEMS_LOCATIONS = 'ACTIVE_ITEMS_LOCATIONS',
  DATA_CENTER_INVENTORY = 'DATA_CENTER_INVENTORY',
  DATA_CENTER_POS = 'DATA_CENTER_POS',
  EVENTS = 'EVENTS',
  FORECASTS = 'FORECASTS',
  INVENTORY = 'INVENTORY',
  ORDERS_SHIPMENTS = 'ORDERS_SHIPMENTS',
  PLANNING = 'PLANNING',
  PROMOTIONAL_CLEARANCE = 'PROMOTIONAL_CLEARANCE',
  RETAIL_PRICING_COST_MARGINS = 'RETAIL_PRICING_COST_MARGINS',
  SALES_DEMAND = 'SALES_DEMAND',
  SUPPLY_CHAIN = 'SUPPLY_CHAIN',
}

export type MetricDependencyGraphEntry = {
  readonly dependencies: ReadonlyArray<MetricInstance>;
  readonly metricInstance: MetricInstance;
};

export type MetricDescription = {
  readonly description: string;
  readonly name: string;
};

export enum MetricDisplayType {
  CHILD = 'CHILD',
  ERROR_METRIC = 'ERROR_METRIC',
  FLOAT = 'FLOAT',
  INTEGER = 'INTEGER',
  INVENTORY_MEASURE = 'INVENTORY_MEASURE',
  MONEY = 'MONEY',
  PERCENT = 'PERCENT',
  PRECISE_CHILD = 'PRECISE_CHILD',
  PRICE = 'PRICE',
  TIME_UNIT = 'TIME_UNIT',
}

export enum MetricFeature {
  ALWAYS_POSITIVE = 'ALWAYS_POSITIVE',
  DERIVED_FROM_TRANSACTION_EVENTS = 'DERIVED_FROM_TRANSACTION_EVENTS',
  HAS_ACTUAL_PAST_DATA = 'HAS_ACTUAL_PAST_DATA',
  HAS_MANUFACTURING_COST_METRIC = 'HAS_MANUFACTURING_COST_METRIC',
  HAS_OPTIONAL_GRANULARITY = 'HAS_OPTIONAL_GRANULARITY',
  HAS_RETAIL_PRICE_METRIC = 'HAS_RETAIL_PRICE_METRIC',
  HAS_SELL_IN_PRICE_METRIC = 'HAS_SELL_IN_PRICE_METRIC',
  HAS_TARGET = 'HAS_TARGET',
  IS_BOOLEAN = 'IS_BOOLEAN',
  IS_COMPS = 'IS_COMPS',
  IS_DEMAND_PLAN_METRIC = 'IS_DEMAND_PLAN_METRIC',
  IS_DIRECTION = 'IS_DIRECTION',
  IS_DISAGGREGABLE_MERGE_EQUAL_METRIC = 'IS_DISAGGREGABLE_MERGE_EQUAL_METRIC',
  IS_EDGE = 'IS_EDGE',
  IS_EDGE_ONLY = 'IS_EDGE_ONLY',
  IS_EDITABLE = 'IS_EDITABLE',
  IS_EXPORTED_SNAPSHOT = 'IS_EXPORTED_SNAPSHOT',
  IS_FORECAST = 'IS_FORECAST',
  IS_FUTURE = 'IS_FUTURE',
  IS_HIDDEN = 'IS_HIDDEN',
  IS_HIGHER_ORDER = 'IS_HIGHER_ORDER',
  IS_HIGHLIGHTABLE = 'IS_HIGHLIGHTABLE',
  IS_INVENTORY_PLAN_METRIC = 'IS_INVENTORY_PLAN_METRIC',
  IS_LOCATION_COUNT = 'IS_LOCATION_COUNT',
  IS_NEGATIVE = 'IS_NEGATIVE',
  IS_ORDER_METRIC = 'IS_ORDER_METRIC',
  IS_PERSPECTIVE = 'IS_PERSPECTIVE',
  IS_PRODUCT_LOCATION_SNAPSHOT = 'IS_PRODUCT_LOCATION_SNAPSHOT',
  IS_PUBLISHED_BY_ALLOY = 'IS_PUBLISHED_BY_ALLOY',
  IS_REPLACING_SNAPSHOT = 'IS_REPLACING_SNAPSHOT',
  IS_RUNTIME_DISAGGREGATED_FORECAST_METRIC = 'IS_RUNTIME_DISAGGREGATED_FORECAST_METRIC',
  IS_SHIPMENT_METRIC = 'IS_SHIPMENT_METRIC',
  IS_SNAPSHOT = 'IS_SNAPSHOT',
  IS_STATISTICAL_AGGREGATOR = 'IS_STATISTICAL_AGGREGATOR',
  IS_UNAVAILABLE_FOR_EXPORT = 'IS_UNAVAILABLE_FOR_EXPORT',
  IS_UNIT_METRIC = 'IS_UNIT_METRIC',
  IS_VERSIONED = 'IS_VERSIONED',
  MATERIALIZED_FROM_METRIC_SNAPSHOT = 'MATERIALIZED_FROM_METRIC_SNAPSHOT',
  MATERIALIZED_FROM_PLAN = 'MATERIALIZED_FROM_PLAN',
  SORT_ASCENDING_BY_DEFAULT = 'SORT_ASCENDING_BY_DEFAULT',
  USES_EVENT_LIFT = 'USES_EVENT_LIFT',
  USES_EXTERNAL_DATA = 'USES_EXTERNAL_DATA',
}

export type MetricFilter = {
  readonly metric: MetricInstance;
  readonly passIfMissing: boolean;
  readonly predicate: MetricFilterPredicate;
  readonly value: number;
};

export enum MetricFilterPredicate {
  BOTTOM = 'BOTTOM',
  EQ = 'EQ',
  GE = 'GE',
  GT = 'GT',
  LE = 'LE',
  LT = 'LT',
  NE = 'NE',
  TOP = 'TOP',
}

export type MetricFormattingRules = {
  readonly ranges: ReadonlyArray<Range>;
  readonly renderType: ConditionalMetricFormatRenderType;
};

export type MetricInstance = {
  readonly arguments: MetricArguments;
  readonly metric: Metric;
};

export type MetricInstanceConfig = {
  readonly customColor?: Color | null;
  readonly customDescription?: string | null;
  readonly customName?: string | null;
  readonly formattingRules?: MetricFormattingRules | null;
  readonly isIconized?: boolean | null;
  readonly metricInstance: MetricInstance;
  readonly seriesType?: WidgetSeriesType | null;
  readonly useReverseColors?: boolean | null;
};

export type MetricInstanceMapping = {
  readonly currencyHeader: string | null;
  readonly includeTimePeriod: boolean;
  readonly mappedValue: string;
  readonly metric: MetricInstance;
};

export type MetricResponse = {
  readonly allMetrics: ReadonlyArray<Metric>;
  readonly metricsWithAnyData: ReadonlyArray<string>;
};

export type MetricSnapshot = {
  readonly attributeValueKeys: ReadonlyArray<ValueKey>;
  readonly metric: Metric;
  readonly values: ReadonlyArray<MetricSnapshotValue>;
};

export type MetricSnapshotMaterializationRequest = {
  readonly interval: LocalInterval | null;
  readonly metricId: number;
};

export type MetricSnapshotMetadata = {
  readonly attributes: ReadonlyArray<Attribute>;
  readonly metric: Metric;
};

export type MetricSnapshotQuery = {
  readonly metricIds: ReadonlyArray<number>;
  readonly partnerId: number;
  readonly partnerIdentifierId: number;
};

export type MetricSnapshotValue = {
  readonly interval: LocalInterval;
  readonly value: number;
};

export enum MetricType {
  CALCULATED__BE_CAREFUL = 'CALCULATED__BE_CAREFUL',
  FLOW = 'FLOW',
  RATIO = 'RATIO',
  STOCK = 'STOCK',
}

export type MetricUrlLink = {
  readonly id: number | null;
  readonly sourceViewId: number;
  readonly type: 'METRIC_URL';
  readonly metricName: string;
  readonly url: string;
};

export type MetricValueChange = {
  readonly actuals: number;
  readonly baseline: number;
  readonly incremental: number;
  readonly relativeLift: number;
};

export type MetricValueMetadata =
  | CurrencyMetricValueMetadata
  | DataAsOfMetricValueMetadata
  | OriginalValueMetricValueMetadata
  | SourceMetricMetricValueMetadata
  | TargetMetricValueMetadata
  | TextMetricValueMetadata;

export enum MetricValueSeverity {
  ALERT = 'ALERT',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export type MetricViewLink = {
  readonly id: number | null;
  readonly sourceViewId: number;
  readonly type: 'METRIC';
  readonly metricName: string;
  readonly targetViewId: number;
};

export type MissingDataStatusContent = {
  readonly dataStatus: DataStatusV2;
  readonly endDate: string;
  readonly expectedAt: string;
  readonly partnerIdentifierMetricIntervals: ReadonlyArray<PartnerIdentifierMetricInterval>;
  readonly startDate: string;
  readonly type: DataStatusContentType;
};

export type MultiPart = {
  readonly bodyParts: ReadonlyArray<BodyPart>;
  readonly contentDisposition: ContentDisposition;
  readonly entity: any;
  readonly headers: {
    [key: string]: ReadonlyArray<string>;
  };
  readonly mediaType: {
    readonly parameters: {
      [key: string]: string;
    };
    readonly subtype: string;
    readonly type: string;
    readonly wildcardSubtype: boolean;
    readonly wildcardType: boolean;
  };
  readonly messageBodyWorkers: MessageBodyWorkers;
  readonly parameterizedHeaders: {
    [key: string]: ReadonlyArray<ParameterizedHeader>;
  };
  readonly parent: MultiPart;
  readonly providers: any;
};

export type NamedInterval = {
  readonly interval: LocalInterval;
  readonly name: string | null;
};

export type NetworkStatsRequest = {
  readonly filters: ReadonlyArray<AttributeFilter>;
};

export enum NewProductCurveEnum {
  BASS_AVERAGE = 'BASS_AVERAGE',
  BASS_FAST = 'BASS_FAST',
  BASS_SLOW = 'BASS_SLOW',
  CUSTOM = 'CUSTOM',
  LINEAR = 'LINEAR',
}

export type NewProductIntroduction = {
  readonly archiveDate: string | null;
  readonly attributeValues: ReadonlyArray<AttributeValue>;
  readonly comparableAttributeValues: ReadonlyArray<AttributeValue>;
  readonly curve: NewProductCurveEnum;
  readonly customCurveValues: ReadonlyArray<number> | null;
  readonly endDate: string;
  readonly id: number | null;
  readonly latestPlanDateUsedForMaterialization: string | null;
  readonly newProductType: NewProductType;
  readonly scaleFactor: number;
  readonly startDate: string;
  readonly versionDate: string;
};

export type NewProductIntroductionConfig = {
  readonly metricSnapshotValues: {
    [key: string]: number;
  };
  readonly newProductIntroduction: NewProductIntroduction;
};

export type NewProductSettings = {
  readonly adoptionPeriodWeeks: number;
  readonly customCurve: ReadonlyArray<number> | null;
};

export enum NewProductType {
  COMPARABLE_PRODUCT = 'COMPARABLE_PRODUCT',
  COMPARABLE_TRANSITION_PRODUCT = 'COMPARABLE_TRANSITION_PRODUCT',
  NEW_PRODUCT = 'NEW_PRODUCT',
  TRANSITION_PRODUCT = 'TRANSITION_PRODUCT',
}

export type NextNDatePeriod = {
  readonly type: 'nextn';
  readonly amount: number;
  readonly unit: CalendarUnit;
};

export type NodeDataDeletionRequest = {
  readonly interval: LocalInterval | null;
  readonly metricIds: ReadonlyArray<number> | null;
  readonly partnerId: number | null;
  readonly vendorId: number;
};

export type NodeDataRange = {
  readonly original: boolean;
  readonly type: 'node';
  readonly dataInterval: LocalInterval;
  readonly intervalType: IntervalType;
  readonly lastUpdated: string | null;
  readonly metric: Metric;
  readonly metricId: number;
  readonly partnerId: number;
  readonly vendorId: number;
};

export type NodeRequest = {
  readonly interval: LocalInterval | null;
  readonly partnerId: number | null;
  readonly scheduledTime: string | null;
};

export type Notification = {
  readonly affectedVendors: ReadonlyArray<number>;
  readonly allowFollowing: boolean;
  readonly id: number | null;
  readonly keys: {
    [key: string]: ReadonlyArray<any>;
  };
  readonly type: NotificationType;
  readonly versions: ReadonlyArray<NotificationVersion>;
};

export type NotificationDeliveryChannel =
  | EmailNotificationDeliveryChannel
  | InAppNotificationDeliveryChannel;

export enum NotificationDeliveryChannelType {
  EMAIL = 'EMAIL',
  IN_APP = 'IN_APP',
}

export type NotificationDeliveryData = NotificationEmailDeliveryData;

export enum NotificationDeliveryStatus {
  DELIVERABLE = 'DELIVERABLE',
  DELIVERED = 'DELIVERED',
  UNDELIVERED = 'UNDELIVERED',
}

export type NotificationEmailDeliveryData = {
  readonly type: 'email';
  readonly issuedAt: string;
  readonly sendGridCode: number | null;
  readonly sendGridResponse: string | null;
};

export type NotificationSubscription = {
  readonly deliveryChannels: ReadonlyArray<NotificationDeliveryChannel>;
  readonly id: number | null;
  readonly isFollowingByDefault: boolean;
  readonly keys: {
    [key: string]: ReadonlyArray<any>;
  };
  readonly subscribedAt: string;
  readonly subscribedBy: number;
  readonly type: NotificationType;
  readonly userId: UserId;
};

export enum NotificationType {
  DATA_ISSUE = 'DATA_ISSUE',
  NOOP = 'NOOP',
}

export type NotificationVersion = {
  readonly authorId: number;
  readonly body: string;
  readonly createdAt: string;
  readonly deliveredAt: string | null;
  readonly endSendingDate: string | null;
  readonly isBroadcast: boolean;
  readonly isPublished: boolean;
  readonly isResolved: boolean;
  readonly startSendingDate: string | null;
  readonly title: string;
  readonly version: number | null;
};

export type NotificationsQuery = {
  readonly deliveryStatus: ReadonlyArray<NotificationDeliveryStatus> | null;
  readonly includeUnpublished: boolean;
  readonly isResolved: boolean | null;
  readonly keys: {
    [key: string]: ReadonlyArray<any>;
  } | null;
  readonly limit: number | null;
  readonly notificationIds: ReadonlyArray<number> | null;
  readonly types: ReadonlyArray<NotificationType> | null;
  readonly vendorIds: ReadonlyArray<number> | null;
};

export type NowToFixedDatePeriod = {
  readonly type: 'now_to_fixed';
  readonly end: string;
};

export type NullDatePeriod =
  // This type is not user-facing.
  never;

export enum Operation {
  APPLY_FORECAST_TYPE_OVERRIDE = 'APPLY_FORECAST_TYPE_OVERRIDE',
  APPLY_MANUAL_ADJUSTMENTS = 'APPLY_MANUAL_ADJUSTMENTS',
  REBUILD = 'REBUILD',
  SYNC_DEMAND_PLAN = 'SYNC_DEMAND_PLAN',
  UPDATE_DEMAND_PLAN = 'UPDATE_DEMAND_PLAN',
  UPDATE_DEPENDENCY_METRICS = 'UPDATE_DEPENDENCY_METRICS',
  UPDATE_DISCONTINUED_SKUS = 'UPDATE_DISCONTINUED_SKUS',
  UPDATE_EVALUATION_DATE = 'UPDATE_EVALUATION_DATE',
  UPDATE_EVENTS = 'UPDATE_EVENTS',
  UPDATE_NPI = 'UPDATE_NPI',
  UPDATE_ORDER_SCHEDULE = 'UPDATE_ORDER_SCHEDULE',
  UPDATE_PLAN_DEMAND_SOURCE = 'UPDATE_PLAN_DEMAND_SOURCE',
  UPDATE_SETTINGS = 'UPDATE_SETTINGS',
}

export type OrderSchedule = {
  readonly archiveDate: string | null;
  readonly attributeValues: ReadonlyArray<AttributeValue>;
  readonly everyOtherWeek: boolean;
  readonly id: number | null;
  readonly schedule: ReadonlyArray<boolean>;
  readonly startDate: string | null;
};

export type OrdersPreviewRequest = {
  readonly attributeInstances: ReadonlyArray<AttributeInstance>;
  readonly calendar: RetailCalendarEnum;
  readonly devOptions: ComputeDevOptions | null;
  readonly evaluationDate: string;
  readonly filters: ReadonlyArray<AttributeFilter>;
  readonly locationAttribute: Attribute | null;
  readonly metrics: ReadonlyArray<MetricInstance>;
  readonly productAttribute: Attribute | null;
  readonly requestId: string | null;
};

export type OriginDestinationPartner = {
  readonly destinationPartnerId: number | null;
  readonly originPartnerId: number | null;
};

export type OriginalValueMetricValueMetadata = {
  readonly type: 'ORIGINAL_VALUE';
  readonly severity: MetricValueSeverity;
  readonly value: number | null;
};

export enum OutOfStockBehaviour {
  ACCRUED_DEMAND = 'ACCRUED_DEMAND',
  LOST_DEMAND = 'LOST_DEMAND',
}

export enum OutOfStockCalculationMethod {
  BELOW_PRESMIN = 'BELOW_PRESMIN',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
}

export enum OutflowType {
  ACTUAL_TOTAL_SHIPMENTS = 'ACTUAL_TOTAL_SHIPMENTS',
  DOWNSTREAM_FORECASTED_SHIPMENTS = 'DOWNSTREAM_FORECASTED_SHIPMENTS',
  DOWNSTREAM_PLANNED_SHIPMENTS = 'DOWNSTREAM_PLANNED_SHIPMENTS',
  FORECASTED_SHIPMENTS = 'FORECASTED_SHIPMENTS',
  FORECASTED_SHIPMENTS_BLENDED = 'FORECASTED_SHIPMENTS_BLENDED',
  INTERNAL_SCHEDULED_SHIPMENTS = 'INTERNAL_SCHEDULED_SHIPMENTS',
  LATE_INTERNAL_SCHEDULED_SHIPMENTS = 'LATE_INTERNAL_SCHEDULED_SHIPMENTS',
  LATE_REQUESTED_SHIPMENTS = 'LATE_REQUESTED_SHIPMENTS',
  LATE_SCHEDULED_SHIPMENTS = 'LATE_SCHEDULED_SHIPMENTS',
  PLANNED_SHIPMENTS = 'PLANNED_SHIPMENTS',
  REQUESTED_SHIPMENTS = 'REQUESTED_SHIPMENTS',
  SCHEDULED_SHIPMENTS = 'SCHEDULED_SHIPMENTS',
  UNIT_SALES = 'UNIT_SALES',
}

export type ParameterizedHeader = {
  readonly parameters: {
    [key: string]: string;
  };
  readonly value: string;
};

export type Partner = {
  readonly defaultCalendar: RetailCalendarEnum | null;
  readonly id: number | null;
  readonly isSyndicatedDataSource: boolean;
  readonly name: string;
  readonly type: PartnerType;
  readonly vendorId: number | null;
};

export type PartnerEdgeMetric = {
  readonly destinationPartnerId: number | null;
  readonly metricId: number;
  readonly originPartnerId: number | null;
};

export type PartnerIdentifier = {
  readonly destinationPartnerId: number | null;
  readonly isPair: boolean;
  readonly originPartnerId: number | null;
  readonly partnerId: number | null;
};

export type PartnerIdentifierMetric = {
  readonly metricId: number;
  readonly partnerIdentifier: PartnerIdentifier;
};

export type PartnerIdentifierMetricInterval = {
  readonly interval: LocalInterval;
  readonly metricId: number;
  readonly partnerIdentifier: PartnerIdentifier;
};

export type PartnerLocationMatchingAttributeSet = {
  readonly partnerId: number;
  readonly setId: number;
};

export type PartnerMatchingConfiguration = {
  readonly configuration: ReadonlyArray<Attribute>;
  readonly partnerId: number;
};

export type PartnerMetadata = {
  readonly partnerId: number | null;
};

export type PartnerMetric = {
  readonly metricId: number;
  readonly partnerId: number;
};

export enum PartnerType {
  DISTRIBUTOR = 'DISTRIBUTOR',
  DTC = 'DTC',
  RETAILER = 'RETAILER',
  SUPPLIER = 'SUPPLIER',
  UNKNOWN = 'UNKNOWN',
  VENDOR = 'VENDOR',
}

export type PendingEdgeDataRange = {
  readonly original: boolean;
  readonly type: 'pendingEdge';
  readonly dataInterval: LocalInterval;
  readonly dataSourceType: EdgeDataSourceType;
  readonly destinationPartnerId: number | null;
  readonly fileId: number | null;
  readonly fileVersion: number | null;
  readonly intervalType: IntervalType;
  readonly lastUpdated: string | null;
  readonly metric: Metric;
  readonly metricId: number;
  readonly originPartnerId: number | null;
  readonly vendorId: number;
};

export type PendingEmailAttachment = {
  readonly blockedAt: string;
  readonly email: string | null;
  readonly filenames: ReadonlyArray<string>;
  readonly historyId: number;
  readonly messageId: string;
  readonly subject: string | null;
  readonly vendorId: number | null;
};

export type PendingNodeDataRange = {
  readonly original: boolean;
  readonly type: 'pendingNode';
  readonly dataInterval: LocalInterval;
  readonly fileId: number | null;
  readonly fileVersion: number | null;
  readonly intervalType: IntervalType;
  readonly lastUpdated: string | null;
  readonly metric: Metric;
  readonly metricId: number;
  readonly partnerId: number;
  readonly vendorId: number;
};

export type PendingTransactionAugmentationRequest = {
  readonly interval: LocalInterval;
  readonly sourcePartnerId: number | null;
  readonly type: TransactionType | null;
};

export type PerformanceAnalysis = {
  readonly performanceVsTargetDollars: MetricValueChange | null;
  readonly performanceVsTargetUnits: MetricValueChange | null;
};

export type Permission = {
  readonly description: string;
  readonly id: number;
  readonly isObsolete: boolean;
  readonly key: string;
  readonly name: string;
};

export enum PermissionKey {
  ATTRIBUTE_CREATE_LOCATION_ATTRIBUTE = 'attribute/create_location_attribute',
  ATTRIBUTE_CREATE_PRODUCT_ATTRIBUTE = 'attribute/create_product_attribute',
  ATTRIBUTE_CREATE_SEGMENT_ATTRIBUTE = 'attribute/create_segment_attribute',
  ATTRIBUTE_CREATE_TRANSACTION_ATTRIBUTE = 'attribute/create_transaction_attribute',
  ATTRIBUTE_CREATE_VIRTUAL_ATTRIBUTE = 'attribute/create_virtual_attribute',
  ATTRIBUTE_DELETE_LOCATION_ATTRIBUTE = 'attribute/delete_location_attribute',
  ATTRIBUTE_DELETE_PRODUCT_ATTRIBUTE = 'attribute/delete_product_attribute',
  ATTRIBUTE_DELETE_SEGMENT_ATTRIBUTE = 'attribute/delete_segment_attribute',
  ATTRIBUTE_DELETE_TRANSACTION_ATTRIBUTE = 'attribute/delete_transaction_attribute',
  ATTRIBUTE_DELETE_VIRTUAL_ATTRIBUTE = 'attribute/delete_virtual_attribute',
  ATTRIBUTE_UPDATE_LOCATION_ATTRIBUTE = 'attribute/update_location_attribute',
  ATTRIBUTE_UPDATE_PRODUCT_ATTRIBUTE = 'attribute/update_product_attribute',
  ATTRIBUTE_UPDATE_SEGMENT_ATTRIBUTE = 'attribute/update_segment_attribute',
  ATTRIBUTE_UPDATE_TRANSACTION_ATTRIBUTE = 'attribute/update_transaction_attribute',
  ATTRIBUTE_UPDATE_VIRTUAL_ATTRIBUTE = 'attribute/update_virtual_attribute',
  COMPUTE_REMOVE_DOUBLE_COUNTING = 'compute/remove_double_counting',
  COMPUTE_USE_CURRENCY_CONVERSION = 'compute/use_currency_conversion',
  COMPUTE_USE_FORECAST_ERROR = 'compute/use_forecast_error',
  COMPUTE_USE_GRAPH_PERSPECTIVE = 'compute/use_graph_perspective',
  COMPUTE_USE_METRIC_FILTERS = 'compute/use_metric_filters',
  COMPUTE_USE_STDDEV_AGGREGATOR = 'compute/use_stddev_aggregator',
  COMPUTE_USE_UNIT_CONVERSION = 'compute/use_unit_conversion',
  COMPUTE_USE_VERSIONING = 'compute/use_versioning',
  COMPUTE_USE_VIRTUAL_ATTRIBUTES = 'compute/use_virtual_attributes',
  DASHBOARD_ARCHIVE_DASHBOARD = 'dashboard/archive_dashboard',
  DASHBOARD_CONFIGURE_SIDEBAR = 'dashboard/configure_sidebar',
  DASHBOARD_CREATE_DASHBOARD = 'dashboard/create_dashboard',
  DASHBOARD_DELETE_DASHBOARD = 'dashboard/delete_dashboard',
  DASHBOARD_MANAGE_LINKS = 'dashboard/manage_links',
  DASHBOARD_UPDATE_DASHBOARD = 'dashboard/update_dashboard',
  DASHBOARD_USE_MANUAL_UPDATE_MODE = 'dashboard/use_manual_update_mode',
  DASHBOARD_VIEW_DASHBOARD = 'dashboard/view_dashboard',
  DATA_FORWARD_FILE = 'data/forward_file',
  DATA_MANAGE_EXCHANGE_RATES = 'data/manage_exchange_rates',
  DESTINATIONS_CREATE_DESTINATIONS = 'destinations/create_destinations',
  DESTINATIONS_DELETE_DESTINATIONS = 'destinations/delete_destinations',
  DESTINATIONS_MANAGE_EMAIL_SUBSCRIPTIONS = 'destinations/manage_email_subscriptions',
  DESTINATIONS_MANAGE_EXPORT_FORMATS = 'destinations/manage_export_formats',
  DESTINATIONS_UPDATE_DESTINATIONS = 'destinations/update_destinations',
  DESTINATIONS_VIEW_DESTINATIONS = 'destinations/view_destinations',
  EVENTS_CREATE_EVENT = 'events/create_event',
  EVENTS_DELETE_EVENT = 'events/delete_event',
  EVENTS_MANAGE_EVENTS = 'events/manage_events',
  EVENTS_UPDATE_EVENT = 'events/update_event',
  EXPERIMENT_CREATE_EXPERIMENT = 'experiment/create_experiment',
  EXPERIMENT_DELETE_EXPERIMENT = 'experiment/delete_experiment',
  EXPERIMENT_UPDATE_EXPERIMENT = 'experiment/update_experiment',
  EXPERIMENT_VIEW_EXPERIMENT = 'experiment/view_experiment',
  EXTRACTOR_DEFINITIONS_CREATE_EXTRACTOR_DEFINITION = 'extractor/definitions_create_extractor_definition',
  EXTRACTOR_DEFINITIONS_CREATE_EXTRACTOR_DEFINITION_VERSION = 'extractor/definitions_create_extractor_definition_version',
  EXTRACTOR_DEFINITIONS_DELETE_EXTRACTOR_DEFINITION = 'extractor/definitions_delete_extractor_definition',
  EXTRACTOR_DEFINITIONS_RELEASE_EXTRACTOR_DEFINITION = 'extractor/definitions_release_extractor_definition',
  EXTRACTOR_DEFINITIONS_VIEW_EXTRACTOR_DEFINITIONS = 'extractor/definitions_view_extractor_definitions',
  FORECAST_CONFIGURE_FORECAST = 'forecast/configure_forecast',
  FORECAST_QUERY_ALLOY_FORECAST = 'forecast/query_alloy_forecast',
  GRAPH_UPDATE_SHIPPING_LANE_RULES = 'graph/update_shipping_lane_rules',
  GRAPH_VIEW_SHIPPING_LANE_RULES = 'graph/view_shipping_lane_rules',
  GROUP_MANAGE_GROUPS = 'group/manage_groups',
  IMPORT_LOAD_LOCATION_DEFAULTS = 'import/load_location_defaults',
  IMPORT_LOAD_LOCATION_MASTER = 'import/load_location_master',
  IMPORT_LOAD_METRIC_SNAPSHOT = 'import/load_metric_snapshot',
  IMPORT_LOAD_SEGMENT_DEFAULTS = 'import/load_segment_defaults',
  IMPORT_LOAD_SHIPPING_LANES = 'import/load_shipping_lanes',
  IMPORT_UPLOAD_FILE = 'import/upload_file',
  METRIC_QUERY_EXPECTED_METRICS = 'metric/query_expected_metrics',
  METRIC_QUERY_LOST_SALES = 'metric/query_lost_sales',
  METRIC_QUERY_PHANTOM_INVENTORY = 'metric/query_phantom_inventory',
  METRIC_QUERY_PLANNING_METRICS = 'metric/query_planning_metrics',
  METRIC_QUERY_REPLENISHMENT_METRICS = 'metric/query_replenishment_metrics',
  METRIC_QUERY_SALES_TARGETS = 'metric/query_sales_targets',
  METRIC_QUERY_SIMULATION_METRICS = 'metric/query_simulation_metrics',
  METRIC_QUERY_SUPPLY_CHAIN_METRICS = 'metric/query_supply_chain_metrics',
  METRIC_QUERY_UNCONSTRAINED_DEMAND = 'metric/query_unconstrained_demand',
  METRICSNAPSHOT_DELETE_METRIC_SNAPSHOT = 'metricsnapshot/delete_metric_snapshot',
  METRICSNAPSHOT_VIEW_METRIC_SNAPSHOT = 'metricsnapshot/view_metric_snapshot',
  NOTIFICATIONS_SUBSCRIBE = 'notifications/subscribe',
  NOTIFICATIONS_VIEW = 'notifications/view',
  PLANNING_USE_DEMAND_PLANNING = 'planning/use_demand_planning',
  PLANNING_USE_INVENTORY_PLANNING = 'planning/use_inventory_planning',
  POLICY_MANAGE_USER_FILTERS = 'policy/manage_user_filters',
  SUBSCRIPTION_MANAGE_SUBSCRIPTION_ONLY_USERS = 'subscription/manage_subscription_only_users',
  TRANSACTION_MANAGE_DATA = 'transaction/manage_data',
  TRANSACTION_MANAGE_IMPLICIT_EVENTS = 'transaction/manage_implicit_events',
  TRANSACTION_VIEW_ORDERS = 'transaction/view_orders',
  WIDGET_USE_CALENDAR_WIDGETS = 'widget/use_calendar_widgets',
  WIDGET_USE_DOUGHNUT_CHART = 'widget/use_doughnut_chart',
  WIDGET_USE_FILTER_SCOPE_CONTROL = 'widget/use_filter_scope_control',
  WIDGET_USE_GLOBE = 'widget/use_globe',
  WIDGET_USE_GROUPED_TABLE = 'widget/use_grouped_table',
  WIDGET_USE_PIVOT_TABLE = 'widget/use_pivot_table',
  WIDGET_USE_SUMMARY_HEADER = 'widget/use_summary_header',
  WORKFLOW_USE_PHANTOM_INVENTORY = 'workflow/use_phantom_inventory',
  WORKFLOW_USE_PROMOTION_ANALYSIS = 'workflow/use_promotion_analysis',
  WORKFLOW_USE_WALMART_NOVA_RECOMMENDATIONS = 'workflow/use_walmart_nova_recommendations',
}

export type Plan = {
  readonly attributes: ReadonlyArray<Attribute>;
  readonly calendar: RetailCalendarEnum;
  readonly date: string;
  readonly granularity: CalendarUnit;
  readonly id: number | null;
  readonly planDemandSourceMap: {
    [key: string]: PlanDemandSource;
  };
  readonly type: PlanType;
  readonly visibilityPeriod: DatePeriod;
};

export enum PlanAdjustmentMode {
  BASELINE = 'BASELINE',
  TOTAL = 'TOTAL',
}

export type PlanChange = {
  readonly calculatedAt: string | null;
  readonly comment: string | null;
  readonly createdAt: string;
  readonly createdBy: number | null;
  readonly id: number | null;
  readonly materializationStartedAt: string | null;
  readonly materializedAt: string | null;
  readonly metricIds: ReadonlyArray<number> | null;
  readonly operation: Operation;
  readonly path: ReadonlyArray<ValueKey>;
  readonly planId: number;
  readonly startedAt: string | null;
  readonly status: Status;
  readonly vendorId: number;
};

export type PlanComputeRequest = {
  readonly computeRequest: ComputeRequest;
  readonly planDiff: PlanDiff | null;
  readonly planType: PlanType;
};

export enum PlanDataStatus {
  INCOMPLETE_IN_PROGRESS = 'INCOMPLETE_IN_PROGRESS',
  INCOMPLETE_PENDING = 'INCOMPLETE_PENDING',
  UP_TO_DATE = 'UP_TO_DATE',
}

export enum PlanDemandSource {
  DIRECT_TO_CONSUMER = 'DIRECT_TO_CONSUMER',
  POS = 'POS',
  SHIPMENT = 'SHIPMENT',
}

export type PlanDemandSourceMapping = {
  readonly attributeValueId: number;
  readonly planDemandSource: PlanDemandSource;
};

export type PlanDiff = {
  readonly addedForecastOverrides: ReadonlyArray<ThinUserForecastOverride>;
  readonly addedManualAdjustments: ReadonlyArray<ThinUserManualAdjustment>;
  readonly newProductIntroduction: NewProductIntroduction | null;
  readonly removedForecastOverrides: ReadonlyArray<ThinUserForecastOverride>;
  readonly removedManualAdjustments: ReadonlyArray<ThinUserManualAdjustment>;
};

export type PlanPathChangeRequest = {
  readonly fromPath: ReadonlyArray<string>;
  readonly toPath: ReadonlyArray<string>;
};

export type PlanReportRow = {
  readonly path: ReadonlyArray<AttributeValue>;
  readonly values: ReadonlyArray<DateValues>;
};

export type PlanSettings = {
  readonly cadencePeriod: SimplePeriod;
  readonly demandPlanSettings: DemandPlanSettings | null;
  readonly firstDate: string;
  readonly forecastConfig: ForecastConfig | null;
  readonly inventoryPlanSettings: InventoryPlanSettings | null;
  readonly vendorId: number;
};

export enum PlanStatusType {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum PlanType {
  DEMAND = 'DEMAND',
  INVENTORY = 'INVENTORY',
}

export type PlanUpdateStatus = {
  readonly dataLastUpdatedAt: string | null;
  readonly dataStatus: PlanDataStatus | null;
  readonly lastModifiedAt: string | null;
  readonly lastModifiedBy: number | null;
  readonly materializationStartedAt: string | null;
  readonly materializedAt: string | null;
  readonly status: PlanStatusType;
};

export type PlanVersion = {
  readonly basePlan: Plan;
  readonly comment: string | null;
  readonly forecastOverrides: ReadonlyArray<ThinUserForecastOverride>;
  readonly forecastType: ForecastType;
  readonly growthFactor: GrowthFactor;
  readonly historicalPeriod: SimplePeriod;
  readonly id: number | null;
  readonly lastModifiedAt: string | null;
  readonly lastModifiedBy: number | null;
  readonly manualAdjustments: ReadonlyArray<ThinUserManualAdjustment>;
  readonly status: PlanStatusType;
};

export type PrecacheRequest = {
  readonly applicableViewIds: ReadonlyArray<number> | null;
  readonly includeSuperAdminDashboards: boolean;
  readonly includeUserPolicies: boolean;
  readonly maxDashboardLastVisitedTimeDays: number;
  readonly vendorId: number | null;
};

export type PredictionResult = {
  readonly lastExpectedDataEnd: string | null;
  readonly lastExpectedReceived: string | null;
  readonly nextExpectedDataEnd: string | null;
  readonly nextExpectedReceived: string | null;
  readonly nextNextExpectedDataEnd: string | null;
  readonly nextNextExpectedReceived: string | null;
  readonly partnerIdentifierMetric: PartnerIdentifierMetric;
};

export type PreviousDatePeriod = {
  readonly type: 'previous';
  readonly amount: number;
  readonly unit: CalendarUnit;
};

export type ProcessedFileDetails = {
  readonly fileAndVersion: FileAndVersion;
  readonly importedRowCount: number;
  readonly isFilePartiallyExtracted: boolean;
  readonly totalAlloyRowCount: number;
};

export type Product = {
  readonly id: number;
  readonly identifier: AttributeValue | null;
  readonly partnerId: number | null;
  readonly unitOfMeasure: string | null;
  readonly vendorId: number;
};

export type ProductDerivationConfigurationResult = {
  readonly errorMessage: string | null;
  readonly matchedVendorProductIdsByPartnerProductId: {
    [key: string]: ReadonlyArray<number>;
  };
  readonly overriddenAttributes: ReadonlyArray<Attribute>;
  readonly partnerId: number;
  readonly percentMatching: number;
  readonly percentMultiple: number;
  readonly percentUnmatched: number;
  readonly weightByProductId: {
    [key: string]: number;
  } | null;
  readonly weightMetricInstance: MetricInstance | null;
};

export type ProductIdentifierAttributes = {
  readonly identifierAttr: Attribute;
  readonly unitOfMeasureAttr: Attribute | null;
};

export type ProductIdentifierSpec = {
  readonly primary: ReadonlyArray<ProductIdentifierAttributes>;
  readonly secondary: ReadonlyArray<ProductIdentifierAttributes>;
};

export type ProductLocation = {
  readonly locationId: number;
  readonly productId: number;
};

export type ProductLocationAugmentationInputData = {
  readonly historicalData: {
    [key: string]: {
      [key: string]: TimeSeries;
    };
  };
  readonly key: ProductLocation;
  readonly metricSnapshots: {
    [key: string]: MetricSnapshot;
  };
  readonly rawData: {
    [key: string]: {
      [key: string]: TimeSeries;
    };
  };
};

export type ProductLocationAugmentationOutputData = {
  readonly augmentedData: {
    [key: string]: {
      [key: string]: TimeSeriesVersions;
    };
  };
  readonly inputData: ProductLocationAugmentationInputData;
};

export type ProductMapping = {
  readonly attributeValueOne: AttributeValue;
  readonly attributeValueTwo: AttributeValue;
  readonly vendorId: number;
};

export type ProductMappingQuery = {
  readonly partnerIdentifier: Attribute;
  readonly productIds: ReadonlyArray<number>;
  readonly vendorIdentifier: Attribute;
};

export type ProductMatchQueryResult = {
  readonly clashingAttributes: ReadonlyArray<Attribute>;
  readonly identifierProductIds: ReadonlyArray<number>;
  readonly willMatch: boolean;
};

export type PurchaseRecommendationsRequest = {
  readonly additionalAttributes: ReadonlyArray<Attribute>;
  readonly computeMetrics: ReadonlyArray<MetricInstance>;
  readonly granularity: CalendarUnit;
  readonly period: DatePeriod;
  readonly planId: number;
  readonly spreadsheetMetrics: ReadonlyArray<MetricInstance>;
};

export type PurchaseRecommendationsRow = {
  readonly additionalAttributes: ReadonlyArray<ThinAttributeValue>;
  readonly computeMetricValues: ReadonlyArray<number>;
  readonly path: ReadonlyArray<AttributeValue>;
  readonly values: ReadonlyArray<DateValues>;
};

export type QueryJobInfo = {
  readonly bytesProcessed: number | null;
  readonly cost: number | null;
  readonly jobId: string | null;
  readonly message: string | null;
  readonly tableName: string | null;
};

export type Range = {
  readonly color: Color;
  readonly lowerBound: number | null;
  readonly name: string | null;
  readonly upperBound: number | null;
};

export type RangeDayOfWeek = {
  readonly empty: boolean;
};

export type RangeLocalTime = {
  readonly empty: boolean;
};

export type RbacRole = {
  readonly archivedAt: string | null;
  readonly description: string;
  readonly id: number | null;
  readonly includedPermissions: ReadonlyArray<Permission>;
  readonly includedRoles: ReadonlyArray<RbacRole>;
  readonly name: string;
};

export type RecommendedFilterTreeRequest = {
  readonly attributes: ReadonlyArray<Attribute>;
  readonly filters: ReadonlyArray<AttributeFilter>;
  readonly removeDoubleCountingPartners: boolean;
};

export enum RecordType {
  CALENDAR_EVENT = 'CALENDAR_EVENT',
  CALENDAR_EVENT_DETAIL = 'CALENDAR_EVENT_DETAIL',
  CONVERSION_RATE = 'CONVERSION_RATE',
  EDGE_HISTORY = 'EDGE_HISTORY',
  FORECAST_OVERRIDE = 'FORECAST_OVERRIDE',
  HISTORY = 'HISTORY',
  ITEM_EXCLUSION = 'ITEM_EXCLUSION',
  LOCATION = 'LOCATION',
  LOCATION_DEFAULT = 'LOCATION_DEFAULT',
  METRIC_SNAPSHOT = 'METRIC_SNAPSHOT',
  ORDER_SCHEDULE = 'ORDER_SCHEDULE',
  PRODUCT = 'PRODUCT',
  PRODUCT_LOCATION_METRIC_SNAPSHOT = 'PRODUCT_LOCATION_METRIC_SNAPSHOT',
  SEGMENT = 'SEGMENT',
  SEGMENT_DEFAULT = 'SEGMENT_DEFAULT',
  SHIPPING_LANE = 'SHIPPING_LANE',
  TRANSACTION_EVENT = 'TRANSACTION_EVENT',
  VIRTUAL_ATTRIBUTE = 'VIRTUAL_ATTRIBUTE',
}

export enum RedshiftAWSRegion {
  AF_SOUTH_1 = 'AF_SOUTH_1',
  AP_EAST_1 = 'AP_EAST_1',
  AP_NORTHEAST_1 = 'AP_NORTHEAST_1',
  AP_NORTHEAST_2 = 'AP_NORTHEAST_2',
  AP_NORTHEAST_3 = 'AP_NORTHEAST_3',
  AP_SOUTHEAST_1 = 'AP_SOUTHEAST_1',
  AP_SOUTHEAST_2 = 'AP_SOUTHEAST_2',
  AP_SOUTHEAST_3 = 'AP_SOUTHEAST_3',
  AP_SOUTHEAST_4 = 'AP_SOUTHEAST_4',
  AP_SOUTHEAST_5 = 'AP_SOUTHEAST_5',
  AP_SOUTH_1 = 'AP_SOUTH_1',
  AP_SOUTH_2 = 'AP_SOUTH_2',
  CA_CENTRAL_1 = 'CA_CENTRAL_1',
  CA_WEST_1 = 'CA_WEST_1',
  CN_NORTHWEST_1 = 'CN_NORTHWEST_1',
  CN_NORTH_1 = 'CN_NORTH_1',
  EU_CENTRAL_1 = 'EU_CENTRAL_1',
  EU_CENTRAL_2 = 'EU_CENTRAL_2',
  EU_NORTH_1 = 'EU_NORTH_1',
  EU_SOUTH_1 = 'EU_SOUTH_1',
  EU_SOUTH_2 = 'EU_SOUTH_2',
  EU_WEST_1 = 'EU_WEST_1',
  EU_WEST_2 = 'EU_WEST_2',
  EU_WEST_3 = 'EU_WEST_3',
  IL_CENTRAL_1 = 'IL_CENTRAL_1',
  ME_CENTRAL_1 = 'ME_CENTRAL_1',
  ME_SOUTH_1 = 'ME_SOUTH_1',
  SA_EAST_1 = 'SA_EAST_1',
  US_EAST_1 = 'US_EAST_1',
  US_EAST_2 = 'US_EAST_2',
  US_GOV_EAST_1 = 'US_GOV_EAST_1',
  US_GOV_WEST_1 = 'US_GOV_WEST_1',
  US_WEST_1 = 'US_WEST_1',
  US_WEST_2 = 'US_WEST_2',
}

export type RedshiftDataWarehouseDestination = {
  readonly deliveryChannel: DeliveryChannel;
  readonly id: number | null;
  readonly isActive: boolean;
  readonly modifierId: number;
  readonly name: string;
  readonly ownerId: number;
  readonly shareLevel: ShareLevel;
  readonly syncFrequencyConfig: SyncFrequencyConfig;
  readonly vendorId: number;
  readonly type: 'REDSHIFT';
  readonly awsAccountId: string;
  readonly awsRegion: RedshiftAWSRegion;
};

export type ReportsRequest = {
  readonly granularity: CalendarUnit;
  readonly metrics: ReadonlyArray<MetricInstance>;
  readonly pathIds: ReadonlyArray<number>;
  readonly period: DatePeriod;
  readonly planId: number;
};

export type RestrictedFileType = {
  readonly createdAt: string;
  readonly createdBy: number;
  readonly filetype: string;
  readonly informationType: InformationType;
  readonly notes: string | null;
  readonly updatedAt: string;
  readonly updatedBy: number;
};

export enum RetailCalendarEnum {
  ADVANCE_AUTO = 'ADVANCE_AUTO',
  APRIL_445 = 'APRIL_445',
  APRIL_445_APR_FIRST = 'APRIL_445_APR_FIRST',
  APRIL_445_FIRST_SUN = 'APRIL_445_FIRST_SUN',
  AUTOZONE = 'AUTOZONE',
  AUTOZONE_PROMO = 'AUTOZONE_PROMO',
  COSTCO = 'COSTCO',
  CVS = 'CVS',
  FEBRUARY_454_MON = 'FEBRUARY_454_MON',
  FEBRUARY_454_SAT = 'FEBRUARY_454_SAT',
  GSBO_445 = 'GSBO_445',
  HOME_DEPOT = 'HOME_DEPOT',
  ISO8601 = 'ISO8601',
  JANUARY_445 = 'JANUARY_445',
  JANUARY_445_MON = 'JANUARY_445_MON',
  JANUARY_445_NEAREST_DEC31 = 'JANUARY_445_NEAREST_DEC31',
  JANUARY_445_SAT = 'JANUARY_445_SAT',
  JANUARY_445_SUN = 'JANUARY_445_SUN',
  JANUARY_454 = 'JANUARY_454',
  JANUARY_454_MON = 'JANUARY_454_MON',
  JANUARY_544 = 'JANUARY_544',
  JANUARY_544_MON_5_WEEK_AUG = 'JANUARY_544_MON_5_WEEK_AUG',
  JANUARY_544_SAT = 'JANUARY_544_SAT',
  JANUARY_NRF = 'JANUARY_NRF',
  JULY_445_SUN = 'JULY_445_SUN',
  KROGER = 'KROGER',
  MCLANE = 'MCLANE',
  NRF = 'NRF',
  OCTOBER_544 = 'OCTOBER_544',
  PETCO = 'PETCO',
  PETSMART = 'PETSMART',
  STANDARD_APR = 'STANDARD_APR',
  STANDARD_AUG = 'STANDARD_AUG',
  STANDARD_JAN_SAT = 'STANDARD_JAN_SAT',
  STANDARD_JAN_SUN = 'STANDARD_JAN_SUN',
  STANDARD_JAN_THU = 'STANDARD_JAN_THU',
  STANDARD_JAN_TUE = 'STANDARD_JAN_TUE',
  STANDARD_JUL = 'STANDARD_JUL',
  STANDARD_OCT = 'STANDARD_OCT',
  STANDARD_SEP = 'STANDARD_SEP',
  TARGET_RETAIL = 'TARGET_RETAIL',
  THU_WED_PROMO = 'THU_WED_PROMO',
  TOY_INDUSTRY_CALENDAR = 'TOY_INDUSTRY_CALENDAR',
  TRACTOR_SUPPLY = 'TRACTOR_SUPPLY',
  WALMART = 'WALMART',
  WED_TUE_PROMO = 'WED_TUE_PROMO',
}

export enum ReturnsCountingMethod {
  GROSS = 'GROSS',
  NET = 'NET',
  ONLY = 'ONLY',
}

export enum Role {
  ADMIN = 'ADMIN',
  ROOT = 'ROOT',
  SUPER_ADMIN = 'SUPER_ADMIN',
  USER = 'USER',
}

export type RowLimit = {
  readonly limit: number;
  readonly sortMetricIndex: number;
};

export type S3BucketInfo = {
  readonly bucketName: string;
  readonly exists: boolean;
  readonly url: string;
};

export type SalesTargetValue = {
  readonly measure: InventoryMeasure;
  readonly value: number;
};

export enum SalesType {
  CLEARANCE = 'CLEARANCE',
  PROMO = 'PROMO',
  REGULAR = 'REGULAR',
  TOTAL = 'TOTAL',
}

export type Schedule = {
  readonly components: ReadonlyArray<ScheduleComponent>;
};

export type ScheduleComponent = {
  readonly dayRange: RangeDayOfWeek;
  readonly interval: {
    readonly nano: number;
    readonly negative: boolean;
    readonly seconds: number;
    readonly units: ReadonlyArray<{
      readonly dateBased: boolean;
      readonly duration: {
        readonly nano: number;
        readonly negative: boolean;
        readonly seconds: number;
        readonly zero: boolean;
      };
      readonly durationEstimated: boolean;
      readonly timeBased: boolean;
    }>;
    readonly zero: boolean;
  };
  readonly timeRange: RangeLocalTime;
  readonly zoneIdName: string;
};

export type ScheduledFeed = {
  readonly active: boolean;
  readonly credentialSetName: string | null;
  readonly id: number | null;
  readonly scraperInstanceId: number;
  readonly scraperParams: string | null;
  readonly vendorId: number;
};

export type Scraper = {
  readonly active: boolean;
  readonly availableDaysOfHistory: number | null;
  readonly credentialGroupId: number;
  readonly hasHistoryMode: boolean;
  readonly id: number | null;
  readonly name: string;
  readonly reports: ReadonlyArray<string> | null;
  readonly restatementWindowDays: number | null;
};

export enum ScraperExceptionCode {
  DATA_NOT_READY = 'DATA_NOT_READY',
  FILESIZE_TOO_SMALL = 'FILESIZE_TOO_SMALL',
  INVALID_CONFIGURATION = 'INVALID_CONFIGURATION',
  INVALID_CREDENTIAL = 'INVALID_CREDENTIAL',
  MALFORMED_REPORT = 'MALFORMED_REPORT',
  MISSING_ELEMENT = 'MISSING_ELEMENT',
  MISSING_REPORT = 'MISSING_REPORT',
  MULTIPLE_NON_ACTIONABLE = 'MULTIPLE_NON_ACTIONABLE',
  NO_DATA = 'NO_DATA',
  OTHER_LOGIN_ERROR = 'OTHER_LOGIN_ERROR',
  PORTAL_ERROR = 'PORTAL_ERROR',
  REPORT_CREATION_FAILURE = 'REPORT_CREATION_FAILURE',
  REPORT_LIMIT = 'REPORT_LIMIT',
  TRANSIENT_ERROR = 'TRANSIENT_ERROR',
}

export enum ScraperFrequency {
  DAILY = 'DAILY',
  FRIDAY = 'FRIDAY',
  HOURLY = 'HOURLY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

export type ScraperInstance = {
  readonly active: boolean;
  readonly defaultScheduledTime: string | null;
  readonly displayName: string | null;
  readonly frequency: ScraperFrequency;
  readonly id: number | null;
  readonly isDefaultFeed: boolean;
  readonly name: string;
  readonly scraperId: number;
  readonly scraperParams: string | null;
};

export type ScraperRun = {
  readonly completedTasks: number;
  readonly feedId: number;
  readonly id: number | null;
  readonly parentId: number | null;
  readonly retryParentId: number | null;
  readonly scheduledTime: string | null;
  readonly scraperParams: string | null;
  readonly statuses: ReadonlyArray<ScraperStatus>;
  readonly targetEnd: string | null;
  readonly targetStart: string | null;
  readonly totalTasks: number;
  readonly type: ScraperRunType;
};

export enum ScraperRunType {
  BACKFILL = 'BACKFILL',
  CREDENTIAL_VERIFICATION = 'CREDENTIAL_VERIFICATION',
  MANUAL = 'MANUAL',
  MANUAL_BATCH = 'MANUAL_BATCH',
  PERIODIC = 'PERIODIC',
}

export type ScraperRunWithRetry = {
  readonly nextRetryRun: ScraperRun | null;
  readonly partnerIdentifierMetricIntervals: ReadonlyArray<PartnerIdentifierMetricInterval>;
  readonly scraperRun: ScraperRun;
};

export type ScraperStatus = {
  readonly dataIssueId: number | null;
  readonly exceptionCode: ScraperExceptionCode | null;
  readonly message: string | null;
  readonly runId: number;
  readonly status: ScraperStatusEnum;
  readonly transitionedAt: string | null;
};

export enum ScraperStatusEnum {
  ABORTED = 'ABORTED',
  ABORTING = 'ABORTING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  MANUAL = 'MANUAL',
  PLEASE_WAIT = 'PLEASE_WAIT',
  SCHEDULED = 'SCHEDULED',
  TO_BE_SCHEDULED = 'TO_BE_SCHEDULED',
  UNKNOWN = 'UNKNOWN',
}

export type ScraperTask = {
  readonly id: number;
  readonly params: string;
  readonly run_id: number;
  readonly statuses: ReadonlyArray<ScraperTaskStatus>;
};

export type ScraperTaskStatus = {
  readonly exceptionCode: ScraperExceptionCode | null;
  readonly message: string | null;
  readonly status: ScraperStatusEnum;
  readonly taskId: number;
  readonly transitionedAt: string | null;
};

export type SendSubscriptionsRequest = {
  readonly deliveryChannels: ReadonlyArray<DeliveryChannel> | null;
  readonly evaluationDateTime: string | null;
  readonly forceSend: boolean | null;
  readonly sendSubscriptions: boolean;
};

export type SendUserAcceptanceEmailRequest = {
  readonly ccUsers: ReadonlyArray<number>;
  readonly fromUser: number;
  readonly notes: string;
  readonly toUsers: ReadonlyArray<number>;
  readonly vendorDataSourceId: number;
};

export type SeriesMetadata = {
  readonly interval: LocalInterval;
  readonly intervalType: IntervalType;
};

export type SetProductMatchesRequest = {
  readonly temporaryMatchesToCreate: {
    [key: string]: ReadonlyArray<number>;
  };
  readonly temporaryMatchesToRemove: {
    [key: string]: ReadonlyArray<number>;
  };
  readonly valuesToSet: {
    [key: string]: ReadonlyArray<AttributeValue>;
  };
};

export type SetVendorDataSourceIdRequest = {
  readonly fileIds: ReadonlyArray<number>;
  readonly vendorDataSourceId: number | null;
};

export type SetVendorRequest = {
  readonly fileIds: ReadonlyArray<number>;
  readonly vendorId: number;
};

export enum ShareLevel {
  PRIVATE = 'PRIVATE',
  SHARED_READ_ONLY = 'SHARED_READ_ONLY',
  SHARED_READ_WRITE = 'SHARED_READ_WRITE',
}

export type ShareScreenshotRequest = {
  readonly ccRecipients: ReadonlyArray<UserId>;
  readonly comment: string;
  readonly evaluationDate: string;
  readonly imageDataUrl: string;
  readonly pageTitle: string;
  readonly pageUrl: string;
  readonly toRecipients: ReadonlyArray<UserId>;
  readonly view: View | null;
};

export type ShareViewRequest = {
  readonly ccRecipients: ReadonlyArray<UserId>;
  readonly comment: string;
  readonly evaluationDate: string;
  readonly exportFormat: ExportFormat;
  readonly imageDataUrl: string;
  readonly pageTitle: string;
  readonly pageUrl: string;
  readonly toRecipients: ReadonlyArray<UserId>;
  readonly widgetIds: ReadonlyArray<number>;
};

export type ShippingLaneRule = {
  readonly destinationFilters: ReadonlyArray<AttributeFilter>;
  readonly id: number | null;
  readonly origin: AttributeValue;
  readonly productFilters: ReadonlyArray<AttributeFilter>;
  readonly vendorId: number;
};

export type SimpleDatePeriod =
  | FixedDatePeriod
  | FixedToNowDatePeriod
  | FutureDatePeriod
  | LastNDatePeriod
  | NextNDatePeriod
  | NowToFixedDatePeriod
  | PreviousDatePeriod
  | ToGoDatePeriod
  | ToDateDatePeriod
  | ToDateWeekendDatePeriod
  | TrailingDatePeriod;

export type SimplePeriod = {
  readonly amount: number;
  readonly unit: CalendarUnit;
};

export type SingleAttributeQuery = {
  readonly attribute: Attribute;
  readonly filters: ReadonlyArray<AttributeFilter>;
};

export type SingleAttributeQueryResult = {
  readonly attributeValues: ReadonlyArray<ThinAttributeValue>;
  readonly attributes: ReadonlyArray<Attribute>;
  readonly itemId: number;
  readonly type: AttributeType;
};

export type SliceInfo = {
  readonly categoryValue: AttributeValue | null;
  readonly channelValue: AttributeValue | null;
  readonly partnerValue: AttributeValue | null;
  readonly productValue: AttributeValue | null;
};

export enum SnowflakeCloudProviderRegion {
  AWS__AP_NORTHEAST_1 = 'AWS__AP_NORTHEAST_1',
  AWS__AP_NORTHEAST_2 = 'AWS__AP_NORTHEAST_2',
  AWS__AP_NORTHEAST_3 = 'AWS__AP_NORTHEAST_3',
  AWS__AP_SOUTHEAST_1 = 'AWS__AP_SOUTHEAST_1',
  AWS__AP_SOUTHEAST_2 = 'AWS__AP_SOUTHEAST_2',
  AWS__AP_SOUTHEAST_3 = 'AWS__AP_SOUTHEAST_3',
  AWS__AP_SOUTH_1 = 'AWS__AP_SOUTH_1',
  AWS__CA_CENTRAL_1 = 'AWS__CA_CENTRAL_1',
  AWS__EU_CENTRAL_1 = 'AWS__EU_CENTRAL_1',
  AWS__EU_CENTRAL_2 = 'AWS__EU_CENTRAL_2',
  AWS__EU_NORTH_1 = 'AWS__EU_NORTH_1',
  AWS__EU_WEST_1 = 'AWS__EU_WEST_1',
  AWS__EU_WEST_2 = 'AWS__EU_WEST_2',
  AWS__EU_WEST_3 = 'AWS__EU_WEST_3',
  AWS__SA_EAST_1 = 'AWS__SA_EAST_1',
  AWS__US_EAST_1 = 'AWS__US_EAST_1',
  AWS__US_EAST_2 = 'AWS__US_EAST_2',
  AWS__US_WEST_2 = 'AWS__US_WEST_2',
  AZURE__AUSTRALIAEAST = 'AZURE__AUSTRALIAEAST',
  AZURE__CANADACENTRAL = 'AZURE__CANADACENTRAL',
  AZURE__CENTRALINDIA = 'AZURE__CENTRALINDIA',
  AZURE__CENTRALUS = 'AZURE__CENTRALUS',
  AZURE__EASTUS2 = 'AZURE__EASTUS2',
  AZURE__JAPANEAST = 'AZURE__JAPANEAST',
  AZURE__NORTHEUROPE = 'AZURE__NORTHEUROPE',
  AZURE__SOUTHCENTRALUS = 'AZURE__SOUTHCENTRALUS',
  AZURE__SOUTHEASTASIA = 'AZURE__SOUTHEASTASIA',
  AZURE__SWITZERLANDNORTH = 'AZURE__SWITZERLANDNORTH',
  AZURE__UAENORTH = 'AZURE__UAENORTH',
  AZURE__UKSOUTH = 'AZURE__UKSOUTH',
  AZURE__WESTEUROPE = 'AZURE__WESTEUROPE',
  AZURE__WESTUS2 = 'AZURE__WESTUS2',
  GOOGLE__EUROPE_WEST2 = 'GOOGLE__EUROPE_WEST2',
  GOOGLE__EUROPE_WEST4 = 'GOOGLE__EUROPE_WEST4',
  GOOGLE__US_CENTRAL1 = 'GOOGLE__US_CENTRAL1',
  GOOGLE__US_EAST4 = 'GOOGLE__US_EAST4',
}

export type SnowflakeDataWarehouseDestination = {
  readonly deliveryChannel: DeliveryChannel;
  readonly id: number | null;
  readonly isActive: boolean;
  readonly modifierId: number;
  readonly name: string;
  readonly ownerId: number;
  readonly shareLevel: ShareLevel;
  readonly syncFrequencyConfig: SyncFrequencyConfig;
  readonly vendorId: number;
  readonly type: 'SNOWFLAKE';
  readonly cloudProviderRegion: SnowflakeCloudProviderRegion;
  readonly customerAccountIdentifier: string;
  readonly isVpsEnabled: boolean;
};

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

export type SortItem = {
  readonly columnId: string;
  readonly direction: SortDirection;
};

export enum SortOrder {
  ALPHABETICAL = 'ALPHABETICAL',
  RECOMMENDED = 'RECOMMENDED',
}

export type SourceMetricMetricValueMetadata = {
  readonly type: 'SOURCE_METRIC';
  readonly severity: MetricValueSeverity;
  readonly value: string | null;
};

export enum Status {
  CALCULATED = 'CALCULATED',
  MATERIALIZED = 'MATERIALIZED',
  MATERIALIZING = 'MATERIALIZING',
  PENDING = 'PENDING',
  STARTED = 'STARTED',
}

export enum StockAggregator {
  AVERAGE = 'AVERAGE',
  ENDING = 'ENDING',
  LAST_KNOWN = 'LAST_KNOWN',
  STARTING = 'STARTING',
}

export type StoreComplianceAnalysis = {
  readonly priceCompliancePercent: number | null;
  readonly storeExecutionPercent: number | null;
};

export type StoreLevelAnalysisRequest = {
  readonly filters: ReadonlyArray<AttributeFilter>;
};

export type StoreLevelWidgetData = {
  readonly eventId: number;
  readonly metricInstanceConfigs: ReadonlyArray<MetricInstanceConfig>;
  readonly request: ComputeRequest;
  readonly result: ComputeResult;
};

export enum StoreType {
  ACTIVE = 'ACTIVE',
  SCANNING = 'SCANNING',
  TRACKED = 'TRACKED',
}

export enum SubscriptionFrequency {
  DAILY = 'DAILY',
  FRIDAY = 'FRIDAY',
  HOURLY = 'HOURLY',
  MONDAY = 'MONDAY',
  MONTH_1 = 'MONTH_1',
  MONTH_10 = 'MONTH_10',
  MONTH_11 = 'MONTH_11',
  MONTH_12 = 'MONTH_12',
  MONTH_13 = 'MONTH_13',
  MONTH_14 = 'MONTH_14',
  MONTH_15 = 'MONTH_15',
  MONTH_16 = 'MONTH_16',
  MONTH_17 = 'MONTH_17',
  MONTH_18 = 'MONTH_18',
  MONTH_19 = 'MONTH_19',
  MONTH_2 = 'MONTH_2',
  MONTH_20 = 'MONTH_20',
  MONTH_21 = 'MONTH_21',
  MONTH_22 = 'MONTH_22',
  MONTH_23 = 'MONTH_23',
  MONTH_24 = 'MONTH_24',
  MONTH_25 = 'MONTH_25',
  MONTH_26 = 'MONTH_26',
  MONTH_27 = 'MONTH_27',
  MONTH_28 = 'MONTH_28',
  MONTH_3 = 'MONTH_3',
  MONTH_4 = 'MONTH_4',
  MONTH_5 = 'MONTH_5',
  MONTH_6 = 'MONTH_6',
  MONTH_7 = 'MONTH_7',
  MONTH_8 = 'MONTH_8',
  MONTH_9 = 'MONTH_9',
  MONTH_LAST = 'MONTH_LAST',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

export type SubscriptionOnlyUser = {
  readonly active: boolean;
  readonly email: string;
  readonly firstName: string | null;
  readonly id: number | null;
  readonly lastName: string | null;
  readonly subscribed: boolean;
  readonly vendorId: number;
};

export type SubscriptionRun = {
  readonly fileStoragePath: string | null;
  readonly id: number;
  readonly isManual: boolean;
  readonly scheduledTime: string;
  readonly statuses: ReadonlyArray<SubscriptionStatus>;
  readonly subscriptionId: number;
};

export type SubscriptionStatus = {
  readonly exportError: ExportError | null;
  readonly runId: number;
  readonly status: SubscriptionStatusEnum;
  readonly traceId: string | null;
  readonly transitionedAt: string;
};

export enum SubscriptionStatusEnum {
  ABORTED = 'ABORTED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  SCHEDULED = 'SCHEDULED',
  SUCCEEDED = 'SUCCEEDED',
}

export type SubscriptionStatusSummary = {
  readonly latestCompletedStatus: SubscriptionStatus | null;
  readonly latestSucceededStatus: SubscriptionStatus | null;
  readonly nextTryStatus: SubscriptionStatusEnum | null;
  readonly nextTryTime: string | null;
  readonly subscriptionId: number;
};

export type SyncFrequencyConfig = {
  readonly debounceTimeMinutes: number;
  readonly maxFrequencyMinutes: number;
  readonly maxSyncDelayMinutes: number;
};

export type Tag = {
  readonly description: string | null;
  readonly id: number | null;
  readonly isSubscription: boolean;
  readonly name: string;
};

export type TargetMetricValueMetadata = {
  readonly type: 'TARGET_VALUE';
  readonly severity: MetricValueSeverity;
  readonly value: number | null;
};

export type TestConnectionRequest = {
  readonly credentialSetName: string | null;
  readonly credentialsToCreate: VendorCredentials | null;
  readonly deliveryChannel: DeliveryChannel;
  readonly path: string;
};

export type TextMetricValueMetadata = {
  readonly type: 'TEXT';
  readonly severity: MetricValueSeverity;
  readonly value: string;
};

export type ThinAttributeValue = {
  readonly displayValue: string | null;
  readonly id: number | null;
  readonly value: any | null;
  readonly valueType: AttributeValueType;
};

export type ThinComputeResultColumn = {
  readonly children: ReadonlyArray<ThinComputeResultColumn>;
  readonly metricMetadata: ReadonlyArray<ReadonlyArray<MetricValueMetadata>>;
  readonly metricValues: ReadonlyArray<number>;
  readonly value: ThinAttributeValue | null;
};

export type ThinComputeResultRow = {
  readonly children: ReadonlyArray<ThinComputeResultRow>;
  readonly columnData: ThinComputeResultColumn;
  readonly value: ThinAttributeValue | null;
};

export type ThinMetric = {
  readonly features: ReadonlyArray<MetricFeature>;
  readonly name: string;
};

export type ThinPendingTransactionDerivation = {
  readonly baseFileId: number;
  readonly runAfter: string;
};

export type ThinUserForecastOverride = {
  readonly attributeValues: ReadonlyArray<ThinAttributeValue>;
  readonly forecastType: ForecastType;
};

export type ThinUserManualAdjustment = {
  readonly attributeValues: ReadonlyArray<ThinAttributeValue>;
  readonly metricValue: UserMetricValue;
};

export type ThinView = {
  readonly archivedAt: string | null;
  readonly description: string | null;
  readonly id: number | null;
  readonly isPublished: boolean;
  readonly lastModifiedAt: string | null;
  readonly lastModifiedBy: number | null;
  readonly name: string;
  readonly ownerId: number | null;
  readonly packageIds: ReadonlyArray<number>;
  readonly shareLevel: ShareLevel;
  readonly slug: string | null;
  readonly tags: ReadonlyArray<string>;
  readonly type: ViewType;
};

export type TimeSeries = {
  readonly intervalType: IntervalType;
  readonly startDate: string;
  readonly values: ReadonlyArray<number>;
};

export type TimeSeriesVersions = {
  readonly timeSeriesVersions: ReadonlyArray<VersionAndSeries>;
};

export type ToDateDatePeriod = {
  readonly type: 'todate';
  readonly unit: CalendarUnit;
};

export type ToDateWeekendDatePeriod = {
  readonly type: 'todate_weekend';
  readonly unit: CalendarUnit;
};

export type ToGoDatePeriod = {
  readonly type: 'to_go';
  readonly unit: CalendarUnit;
};

export type TrailingDatePeriod = {
  readonly type: 'trailing';
  readonly amount: number;
  readonly unit: CalendarUnit;
};

export type TransactionDerivationConfiguration = {
  readonly baseFileType: FileTypeMetadata;
  readonly comparisonFileType: FileTypeMetadata | null;
  readonly delayDays: number | null;
  readonly eventTypes: ReadonlyArray<TransactionDerivationEventTypes>;
  readonly id: number | null;
  readonly vendorId: number;
};

export type TransactionDerivationEventTypes = {
  readonly baseEventType: TransactionType;
  readonly derivedEventType: TransactionType;
};

export type TransactionPartnerEdge = {
  readonly dataSource: string | null;
  readonly destinationPartnerId: number | null;
  readonly filetype: string | null;
  readonly identifiers: ReadonlyArray<string>;
  readonly latestEventDate: string | null;
  readonly locationAttributes: ReadonlyArray<string> | null;
  readonly originPartnerId: number | null;
  readonly productAttributes: ReadonlyArray<string> | null;
  readonly type: TransactionType;
  readonly vendorId: number;
};

export enum TransactionStatus {
  ARRIVED = 'ARRIVED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  CUT = 'CUT',
  NEW = 'NEW',
  PARTIALLY_ARRIVED = 'PARTIALLY_ARRIVED',
  PARTIALLY_CANCELLED = 'PARTIALLY_CANCELLED',
  PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
  PARTIALLY_CONFIRMED = 'PARTIALLY_CONFIRMED',
  PARTIALLY_CUT = 'PARTIALLY_CUT',
  PARTIALLY_RECEIVED = 'PARTIALLY_RECEIVED',
  PARTIALLY_SHIPPED = 'PARTIALLY_SHIPPED',
  RECEIVED = 'RECEIVED',
  SHIPPED = 'SHIPPED',
}

export enum TransactionType {
  ARRIVAL = 'ARRIVAL',
  CANCELLATION = 'CANCELLATION',
  COMPLETION = 'COMPLETION',
  CONFIRMATION = 'CONFIRMATION',
  CUT = 'CUT',
  ORDER = 'ORDER',
  RECEIPT = 'RECEIPT',
  REQUESTED_RECEIPT = 'REQUESTED_RECEIPT',
  REQUESTED_SHIPMENT = 'REQUESTED_SHIPMENT',
  SCHEDULED_RECEIPT = 'SCHEDULED_RECEIPT',
  SCHEDULED_SHIPMENT = 'SCHEDULED_SHIPMENT',
  SHIPMENT = 'SHIPMENT',
}

export type TriggerForecastRunsWithOverridesRequest = {
  readonly metricId: number;
  readonly paramOverrides: string;
};

export type TwilioPhoneNumber = {
  readonly credentialSetName: string | null;
  readonly id: number | null;
  readonly number: string;
};

export enum Type {
  PERCENTAGE = 'PERCENTAGE',
  PER_UNIT = 'PER_UNIT',
}

export type UatEmailContent = {
  readonly buttonCTA: string;
  readonly buttonLink: string;
  readonly historyDescription: string;
  readonly historySummary: string;
  readonly integrationSummaryHeader: string;
  readonly mainContent: string;
  readonly notes: string;
  readonly notesHeader: string;
};

export type UatRecipients = {
  readonly ccUserIds: ReadonlyArray<number>;
  readonly toUserIds: ReadonlyArray<number>;
};

export type UpdateDataSourceContactsRequest = {
  readonly dataSourceOwner: DataSourceContact;
  readonly vendorDataSourceId: number;
};

export type UpdateEmailAllowlistAddressRequest = {
  readonly existingEmailAllowlistAddress: EmailAllowlistAddress;
  readonly newEmailAllowlistAddress: EmailAllowlistAddress;
};

export type UpdateEmailAllowlistHostRequest = {
  readonly existingEmailAllowlistHost: EmailAllowlistHost;
  readonly newEmailAllowlistHost: EmailAllowlistHost;
};

export enum UsageDangerLevel {
  DANGER = 'DANGER',
  SAFE = 'SAFE',
  WARNING = 'WARNING',
}

export type UsageInfo = {
  readonly dangerLevel: UsageDangerLevel;
  readonly empty: boolean;
  readonly usageText: ReadonlyArray<string>;
};

export type UsageSummary = {
  readonly dangerText: string;
  readonly dashboardInfo: UsageInfo;
  readonly extraInfo: ReadonlyArray<UsageInfo>;
  readonly greatestDangerLevel: UsageDangerLevel;
  readonly mainText: string;
};

export type User = {
  readonly active: boolean;
  readonly email: string;
  readonly id: number | null;
  readonly role: Role;
  readonly type: CredentialType;
  readonly vendorId: number;
};

export type UserComputeStats = {
  readonly averageProcessingTimeMsField: number;
  readonly averageSlotMinutesUsed: number;
  readonly cacheMissRate: number;
  readonly lastCreatedAt: string;
  readonly requestCount: number;
  readonly userId: number;
  readonly vendorId: number;
};

export type UserFilters = {
  readonly filters: ReadonlyArray<AttributeFilter>;
  readonly userId: number;
};

export type UserId = {
  readonly id: number;
  readonly type: UserType;
};

export type UserInfo = {
  readonly createdAt: string | null;
  readonly firstName: string;
  readonly functionalArea: string | null;
  readonly lastName: string;
  readonly lastUsedAt: string | null;
  readonly phoneNumber: string | null;
};

export type UserIntercomCredentials = {
  readonly hashedId: string;
  readonly id: string;
};

export type UserMetricValue = {
  readonly interval: LocalInterval;
  readonly metricName: string;
  readonly value: number;
};

export type UserNotification = {
  readonly id: number | null;
  readonly isFollowing: boolean;
  readonly lastDeliveredVersion: number | null;
  readonly notification: Notification;
  readonly status: UserNotificationStatus;
  readonly subscriptionId: number | null;
};

export type UserNotificationStatus = {
  readonly deliveryData: NotificationDeliveryData | null;
  readonly readAt: string | null;
  readonly version: number;
};

export enum UserType {
  SUBSCRIPTION_ONLY_USER = 'SUBSCRIPTION_ONLY_USER',
  USER = 'USER',
}

export type ValidatePlanMaterializationRequest = {
  readonly planTypes: ReadonlyArray<PlanType>;
  readonly rootPathValueIds: ReadonlyArray<number> | null;
  readonly vendorIds: ReadonlyArray<number>;
};

export type ValidationResult = {
  readonly issues: ReadonlyArray<ImportIssue>;
  readonly status: ValidationResultStatus;
};

export enum ValidationResultStatus {
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export type ValueKey = {
  readonly attributeId: number;
  readonly valueId: number;
};

export type ValuesByPartnerRequest = {
  readonly locationAttribute: Attribute;
  readonly partnerIds: ReadonlyArray<number> | null;
};

export type Vendor = {
  readonly active: boolean;
  readonly displayName: string;
  readonly id: number | null;
  readonly isClusteredByProductLocation: boolean;
  readonly isDemandPlanningEnabled: boolean;
  readonly isEcommerceMigrated: boolean;
  readonly isInventoryPlanningEnabled: boolean;
  readonly isPartitionedMonthly: boolean;
  readonly isShippingLaneRulesEnabled: boolean;
  readonly name: string;
  readonly packageId: number | null;
  readonly slackChannelId: string | null;
  readonly slackChannelName: string | null;
  readonly type: VendorType | null;
};

export type VendorAnalysisSettings = {
  readonly additionalVisibleCurrencies: ReadonlyArray<CurrencyCode>;
  readonly allowGlobalMetricSnapshots: boolean;
  readonly archiveAfterMonths: number;
  readonly assumeBinaryLocationCounts: boolean;
  readonly attributeValueColors: ReadonlyArray<AttributeValueColor>;
  readonly calendar: RetailCalendarEnum;
  readonly currency: CurrencyCode;
  readonly dashboardPeriod: SimpleDatePeriod;
  readonly defaultDisaggregationWeightPeriod: DatePeriod;
  readonly defaultDisaggregationWeightTimeShift: SimplePeriod;
  readonly deleteAfterMonths: number;
  readonly demandSignalPerspective: GraphPerspective;
  readonly errorMetric: ErrorMetric;
  readonly evaluationDate: string | null;
  readonly forecastComposition: ForecastComposition;
  readonly forecastDisaggregationWeightPeriod: DatePeriod;
  readonly forecastDisaggregationWeightTimeShift: SimplePeriod;
  readonly forecastErrorGranularity: ReadonlyArray<AttributeInstance>;
  readonly forecastPeriod: SimpleDatePeriod;
  readonly forecastType: ForecastType;
  readonly granularity: CalendarUnit;
  readonly growthFactorConstant: number;
  readonly hasVendorCurrencyRates: boolean;
  readonly historicalPeriod: SimplePeriod;
  readonly inflowType: InflowType | null;
  readonly inventoryMeasure: InventoryMeasure;
  readonly inventoryTypes: ReadonlyArray<InventoryType>;
  readonly isCalendarAutoSwitchingEnabled: boolean;
  readonly leadTime: SimplePeriod;
  readonly minimumOrderQuantity: number;
  readonly orderRoundingValue: number;
  readonly ordersDefaultLocationAttributeId: number | null;
  readonly ordersDefaultProductAttributeId: number | null;
  readonly outOfStockBehaviour: OutOfStockBehaviour;
  readonly outOfStockCalculationMethod: OutOfStockCalculationMethod;
  readonly outflowType: OutflowType;
  readonly removePartnerDoubleCounting: boolean;
  readonly salesType: SalesType;
  readonly stockAggregator: StockAggregator;
  readonly storeType: StoreType;
  readonly supplyTargetPeriod: SimplePeriod;
  readonly timeAgo: SimplePeriod;
  readonly timeUnit: CalendarUnit;
  readonly unitConversionAttributes: ReadonlyArray<Attribute>;
  readonly useUnitsLaunchpad: boolean;
  readonly useWeeklyRepeatingWeightByDefault: boolean;
  readonly weekFormat: WeekFormat;
};

export type VendorCredentials = {
  readonly credentialSetName: string | null;
  readonly credentials: {
    [key: string]: CredentialValue;
  };
  readonly groupName: string;
};

export type VendorDataIntegration = {
  readonly integration: DataIntegration;
  readonly isActive: boolean;
  readonly isRecommended: boolean;
};

export type VendorDataSource = {
  readonly credentialSetName: string | null;
  readonly dataSource: DataSource;
  readonly deprecatedAt: string | null;
  readonly ediPartnerId: number | null;
  readonly id: number | null;
  readonly isSelfServe: boolean;
  readonly owner: DataSourceContact | null;
  readonly qualityCheckViewId: number | null;
  readonly status: VendorDataSourceStatus | null;
  readonly uatViewId: number | null;
  readonly vendorId: number;
};

export type VendorDataSourceFiletype = {
  readonly cadence: Cadence;
  readonly contactEmail: string | null;
  readonly contactName: string | null;
  readonly deprecatedAt: string | null;
  readonly expectedTimeReceived: string | null;
  readonly filetype: string;
  readonly id: number | null;
  readonly vendorDataSourceId: number;
};

export type VendorDataSourceFiletypeWithMetrics = {
  readonly filetypeDisplayName: string;
  readonly filetypeName: string;
  readonly metricIdsByTab: {
    [key: string]: ReadonlyArray<number>;
  };
  readonly vendorDataSource: VendorDataSource;
  readonly vendorDataSourceFiletypeId: number;
};

export type VendorDataSourceStatus = {
  readonly message: string;
  readonly status: VendorDataSourceStatusEnum;
  readonly submittedAt: string | null;
  readonly submittedBy: number | null;
};

export enum VendorDataSourceStatusEnum {
  CREDENTIALS_RECEIVED = 'CREDENTIALS_RECEIVED',
  DATA_SOURCE_CREATED = 'DATA_SOURCE_CREATED',
  EXTERNAL_CREDENTIALS_REQUESTED = 'EXTERNAL_CREDENTIALS_REQUESTED',
  HISTORY_PULL_FINISHED = 'HISTORY_PULL_FINISHED',
  UAT_APPROVED = 'UAT_APPROVED',
  UAT_DASHBOARD_CREATED = 'UAT_DASHBOARD_CREATED',
  UAT_STARTED = 'UAT_STARTED',
}

export type VendorDefaultFilterChangeRequest = {
  readonly filter: AttributeFilter | null;
};

export type VendorFeatureFlag = {
  readonly featureFlagName: string;
  readonly minimumRole: Role | null;
};

export type VendorNetworkStats = {
  readonly partnerCount: number;
  readonly productCount: number;
  readonly retailDCCount: number;
  readonly retailStoreCount: number;
  readonly vendorId: number;
  readonly warehouseCount: number;
};

export type VendorRegistrationRequest = {
  readonly createdAt: string | null;
  readonly externalAccountId: string | null;
  readonly externalAccountRoles: string | null;
  readonly externalAccountState: EntitlementState | null;
  readonly id: number | null;
  readonly requesterEmail: string;
  readonly requesterInfo: UserInfo;
  readonly vendorId: number | null;
  readonly vendorName: string;
};

export enum VendorType {
  DEMO = 'DEMO',
  PROOF_OF_CONCEPT = 'PROOF_OF_CONCEPT',
  REGULAR = 'REGULAR',
}

export type VersionAndSeries = {
  readonly series: TimeSeries;
  readonly version: number;
};

export type VersionRecency = {
  readonly asOfDate: string | null;
  readonly lag: number | null;
  readonly mostRecent: boolean;
  readonly relativePeriod: SimplePeriod | null;
};

export type View = {
  readonly archivedAt: string | null;
  readonly calendar: RetailCalendarEnum;
  readonly currency: CurrencyCode;
  readonly description: string | null;
  readonly enableDemandPlanLinks: boolean;
  readonly enableInventoryPlanLinks: boolean;
  readonly enableOrderPreviewLinks: boolean;
  readonly evaluationPeriod: DatePeriod;
  readonly filters: ReadonlyArray<AttributeFilter>;
  readonly id: number | null;
  readonly isFixedHeight: boolean;
  readonly isPublished: boolean;
  readonly lastModifiedAt: string | null;
  readonly lastModifiedBy: number | null;
  readonly layoutType: LayoutType;
  readonly name: string;
  readonly ownerId: number | null;
  readonly packageIds: ReadonlyArray<number>;
  readonly removeDoubleCounting: boolean;
  readonly requiredPermissions: ReadonlyArray<Permission>;
  readonly shareLevel: ShareLevel;
  readonly slug: string | null;
  readonly tags: ReadonlyArray<string>;
  readonly type: ViewType;
  readonly unitConversionAttribute: Attribute | null;
  readonly updateMode: ViewUpdateMode;
  readonly weekFormat: WeekFormat;
  readonly widgets: ReadonlyArray<Widget>;
};

export type ViewLink = AttributeViewLink | AttributeUrlLink | MetricViewLink | MetricUrlLink;

export type ViewTrackingEvent = {
  readonly navigationTiming: BrowserNavigationTiming | null;
  readonly referrer: string | null;
  readonly timestamp: number;
  readonly url: string;
  readonly viewId: number;
  readonly viewType: ViewType;
  readonly widgets: ReadonlyArray<WidgetTrackingEvent>;
};

export enum ViewType {
  DEFAULT = 'DEFAULT',
  LAUNCHPAD = 'LAUNCHPAD',
  TEMPLATE = 'TEMPLATE',
  WORKFLOW = 'WORKFLOW',
}

export enum ViewUpdateMode {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

export type ViewValidationRequest = {
  readonly includeAll: boolean;
  readonly runComputeRequests: boolean;
};

export type ViewWidgetFilesRequest = {
  readonly evaluationDate: string | null;
  readonly view: View;
  readonly widget: Widget;
};

export type VirtualAttribute = {
  readonly attributeId: number | null;
  readonly groups: ReadonlyArray<VirtualAttributeValueGroup>;
  readonly name: string;
  readonly templateAttributeId: number | null;
  readonly templateAttributeName: string;
};

export type VirtualAttributeSummary = {
  readonly attribute: Attribute;
  readonly groupAttributes: ReadonlyArray<Attribute>;
  readonly groupCount: number;
};

export type VirtualAttributeValueGroup = {
  readonly group: Group;
  readonly value: string;
  readonly valueId: number | null;
};

export type VisitedTimestamp = {
  readonly entityId: number;
  readonly visited: string;
};

export type WalmartNovaFormExportRequest = {
  readonly createReason: string;
  readonly dnsa: string | null;
  readonly dnsb: string | null;
  readonly eventCode: string | null;
  readonly headerComment: string | null;
  readonly lineComment: string | null;
  readonly mabd: string | null;
  readonly recommendationResult: WalmartRecommendationsResult;
  readonly useSuggestedDates: boolean;
};

export type WalmartProductCandidate = {
  readonly inStockPercent: number;
  readonly isDirectImport: boolean;
  readonly pipelineWOS: number;
  readonly salesDollars: number;
  readonly salesPercentOfTotal: number;
  readonly salesVsForecast: number;
  readonly validItemLocations: number;
  readonly walmartAllLinksNumber: string;
  readonly walmartAllLinksNumberValue: ThinAttributeValue;
  readonly walmartDescription: string;
  readonly walmartDescriptionValue: ThinAttributeValue;
};

export type WalmartProductCandidatesResult = {
  readonly products: ReadonlyArray<WalmartProductCandidate>;
  readonly request: ComputeRequest;
};

export type WalmartProductStatsResult = {
  readonly inStockGeoRequest: ComputeRequest;
  readonly inStockGeoResult: ComputeResult;
  readonly inStockRequest: ComputeRequest;
  readonly inStockResult: ComputeResult;
  readonly salesVsForecastRequest: ComputeRequest;
  readonly salesVsForecastResult: ComputeResult;
};

export type WalmartRecommendation = {
  readonly data: ThinComputeResultRow;
  readonly dcNumber: string | null;
  readonly itemPrice: number | null;
  readonly locationNumber: string;
  readonly orderDollars: number | null;
  readonly productType: WalmartRecommendationProductType;
  readonly rawWhpks: number;
  readonly requestedWhpks: number;
  readonly storeNumber: string | null;
  readonly storeUnits: number;
  readonly vendorWarehouseNumber: string | null;
  readonly vnpkPrice: number | null;
  readonly vnpkQuantity: number | null;
  readonly vnpkSizeInWhpks: number;
  readonly vnpks: number;
  readonly walmartAllLinksNumber: string;
  readonly walmartDescription: string;
  readonly whpkSizeInUnits: number;
  readonly whpks: number;
};

export enum WalmartRecommendationProductType {
  CROSSDOCK = 'CROSSDOCK',
  STAPLESTOCK = 'STAPLESTOCK',
}

export enum WalmartRecommendationsDataIssue {
  MULTIPLE_VNPK = 'MULTIPLE_VNPK',
  MULTIPLE_WHPK = 'MULTIPLE_WHPK',
}

export type WalmartRecommendationsProductSummary = {
  readonly summary: WalmartRecommendationsSummary;
  readonly walmartAllLinksNumber: string;
  readonly walmartDescription: string;
  readonly warehouseSummaries: {
    [key: string]: WalmartRecommendationsSummary;
  };
};

export type WalmartRecommendationsRequest = {
  readonly applyVendorInventoryConstraints: boolean;
  readonly crossdockDcFilter: AttributeFilter | null;
  readonly crossdockOnly: boolean;
  readonly crossdockStoreFilter: AttributeFilter | null;
  readonly forecastType: ForecastType;
  readonly maxShelfMultiple: number | null;
  readonly maxWhpkPerStore: number | null;
  readonly productFilters: ReadonlyArray<AttributeFilter>;
  readonly roundDcUnitsToVendorPacks: boolean;
  readonly supplyTargetPeriod: SimplePeriod | null;
  readonly useCache: boolean | null;
};

export type WalmartRecommendationsResult = {
  readonly dataIssuesByWalmartAllLinksNumber: {
    [key: string]: ReadonlyArray<WalmartRecommendationsDataIssue>;
  };
  readonly requestMetrics: ReadonlyArray<MetricInstance>;
  readonly subResults: {
    [key: string]: WalmartRecommendationsSubResult;
  };
};

export type WalmartRecommendationsSettings = {
  readonly crossdockOnly: boolean;
  readonly forecastType: ForecastType;
  readonly lowInStockThreshold: number;
  readonly maxShelfMultiple: number | null;
  readonly maxWhpkPerStore: number | null;
  readonly roundDcUnitsToVendorPacks: boolean;
  readonly salesExceedsForecastThreshold: number;
  readonly vendorInventoryIsFungible: boolean;
  readonly weeksOfForecast: number | null;
};

export type WalmartRecommendationsSubResult = {
  readonly productSummaries: ReadonlyArray<WalmartRecommendationsProductSummary>;
  readonly recommendations: ReadonlyArray<WalmartRecommendation>;
  readonly summary: WalmartRecommendationsSummary;
};

export type WalmartRecommendationsSummary = {
  readonly availableVnpks: number | null;
  readonly availableWhpks: number | null;
  readonly locationCount: number;
  readonly orderDollars: number | null;
  readonly rawWhpks: number;
  readonly requestedVnpks: number;
  readonly requestedWhpks: number;
  readonly vnpkSizeInWhpks: number;
  readonly vnpks: number;
  readonly whpks: number;
};

export type WalmartVendorInventoryResult = {
  readonly request: ComputeRequest;
  readonly vendorInventoryByWalmartAllLinksNumber: {
    [key: string]: number;
  };
};

export enum WeekFormat {
  CALENDAR_FORMAT = 'CALENDAR_FORMAT',
  END_OF_WEEK = 'END_OF_WEEK',
  START_OF_WEEK = 'START_OF_WEEK',
}

export type Widget = {
  readonly childCount: number | null;
  readonly columnGroupings: ReadonlyArray<GroupingAttribute>;
  readonly customName: string | null;
  readonly devOptions: ComputeDevOptions | null;
  readonly filterAttributesToIgnore: ReadonlyArray<Attribute> | null;
  readonly filterFulfillmentMethods: boolean;
  readonly filters: ReadonlyArray<AttributeFilter>;
  readonly hasSummaryHeader: boolean;
  readonly headerInsight: WidgetHeaderInsight | null;
  readonly helpText: string;
  readonly id: number | null;
  readonly ignoreGraphContextInFilters: boolean;
  readonly ignoresViewFilters: boolean;
  readonly insightPillMetrics: ReadonlyArray<InsightMetricInstanceConfig> | null;
  readonly isGrouped: boolean | null;
  readonly isTransposed: boolean | null;
  readonly layoutParams: LayoutParams | null;
  readonly metricFilters: ReadonlyArray<ReadonlyArray<MetricFilter>>;
  readonly metrics: ReadonlyArray<MetricInstanceConfig>;
  readonly metricsSplitIndex: number | null;
  readonly options: ComputeOptions | null;
  readonly postComputeFilters: ReadonlyArray<AttributeFilter>;
  readonly rowGroupings: ReadonlyArray<GroupingAttribute>;
  readonly rowLimit: RowLimit | null;
  readonly sortOrder: ReadonlyArray<SortItem> | null;
  readonly type: WidgetType;
  readonly viewLinkIds: ReadonlyArray<number> | null;
};

export type WidgetComputeStats = {
  readonly averageProcessingTimeMsField: number;
  readonly averageSlotMinutesUsed: number;
  readonly cacheMissRate: number;
  readonly contextUrl: string;
  readonly lastCreatedAt: string;
  readonly requestCount: number;
  readonly viewId: number | null;
  readonly widgetId: number;
};

export type WidgetFileExport = {
  readonly customMetricNames: ReadonlyArray<string> | null;
  readonly name: string;
  readonly requestId: string;
  readonly widgetIndex: number;
};

export type WidgetFileExportsRequest = {
  readonly exportFormat: ExportFormat;
  readonly fileName: string;
  readonly includeMetricTimePeriodInHeader: boolean;
  readonly includeTotalsRow: boolean;
  readonly weekFormat: WeekFormat;
  readonly widgetFileExports: ReadonlyArray<WidgetFileExport>;
};

export enum WidgetHeaderInsight {
  AVERAGE_IN_STOCK = 'AVERAGE_IN_STOCK',
  INFER = 'INFER',
  INVENTORY = 'INVENTORY',
  INVENTORY_VISIBILITY = 'INVENTORY_VISIBILITY',
  IN_STOCK_PERCENT_WITH_PRESMIN = 'IN_STOCK_PERCENT_WITH_PRESMIN',
  LOST_SALES = 'LOST_SALES',
  RECOMMENDED_ORDERS = 'RECOMMENDED_ORDERS',
  REPLENISHMENT_RECOMMENDATIONS = 'REPLENISHMENT_RECOMMENDATIONS',
  RETAIL_REPLENISHMENT_IN_STOCK_PERCENT = 'RETAIL_REPLENISHMENT_IN_STOCK_PERCENT',
  SALES = 'SALES',
  SIMULATED_INVENTORY = 'SIMULATED_INVENTORY',
  TARGET_LAUNCHPAD_ORDER_FORECAST = 'TARGET_LAUNCHPAD_ORDER_FORECAST',
  TARGET_LAUNCHPAD_POS_FORECAST = 'TARGET_LAUNCHPAD_POS_FORECAST',
}

export enum WidgetSeriesType {
  BAR = 'BAR',
  LINE = 'LINE',
  STACKED_BAR = 'STACKED_BAR',
}

export type WidgetSubscription = EmailWidgetSubscription | ExternalStorageWidgetSubscription;

export type WidgetTrackingEvent = {
  readonly cacheHit: boolean | null;
  readonly customName: string | null;
  readonly loadEnd: number | null;
  readonly loadStart: number | null;
  readonly widgetId: number;
  readonly widgetType: WidgetType;
};

export enum WidgetType {
  ADDITIONAL_TEMPLATES_LIST = 'ADDITIONAL_TEMPLATES_LIST',
  CALENDAR = 'CALENDAR',
  CAROUSEL = 'CAROUSEL',
  CHART = 'CHART',
  DIAGNOSTICS_TABLE = 'DIAGNOSTICS_TABLE',
  DOUGHNUT_CHART = 'DOUGHNUT_CHART',
  EVENT_LIST = 'EVENT_LIST',
  EXPERIMENT_CHART = 'EXPERIMENT_CHART',
  EXPERIMENT_SIDEBAR = 'EXPERIMENT_SIDEBAR',
  EXPERIMENT_TABLE = 'EXPERIMENT_TABLE',
  FAVORITES_LIST = 'FAVORITES_LIST',
  FILTER_BAR = 'FILTER_BAR',
  GLOBE = 'GLOBE',
  HEAT_MAP = 'HEAT_MAP',
  LOCATION_MAP = 'LOCATION_MAP',
  NETWORK_PARTNERS = 'NETWORK_PARTNERS',
  NETWORK_PRODUCTS = 'NETWORK_PRODUCTS',
  NETWORK_RETAIL_DCS = 'NETWORK_RETAIL_DCS',
  NETWORK_RETAIL_STORES = 'NETWORK_RETAIL_STORES',
  NETWORK_WAREHOUSES = 'NETWORK_WAREHOUSES',
  NETWORK_WIDGET = 'NETWORK_WIDGET',
  PERFORMANCE_VS_PLAN = 'PERFORMANCE_VS_PLAN',
  PIVOT_TABLE = 'PIVOT_TABLE',
  PLANNING_CHART = 'PLANNING_CHART',
  PLANNING_TABLE = 'PLANNING_TABLE',
  PLAN_FILTER_BAR = 'PLAN_FILTER_BAR',
  RECENTLY_VIEWED_LIST = 'RECENTLY_VIEWED_LIST',
  RECENT_FUTURE_EVENTS_LIST = 'RECENT_FUTURE_EVENTS_LIST',
  SINGLE_VALUE = 'SINGLE_VALUE',
  SPLIT_CHART = 'SPLIT_CHART',
  STACKED_CHART = 'STACKED_CHART',
  STACKED_WIDGET = 'STACKED_WIDGET',
  TABLE = 'TABLE',
  TAB_WIDGET = 'TAB_WIDGET',
  TREE_FILTER_BAR = 'TREE_FILTER_BAR',
  YEARLY_CALENDAR = 'YEARLY_CALENDAR',
}

export type WillProductsMatchQuery = {
  readonly identifier: AttributeValue;
  readonly products: ReadonlyArray<Product>;
};

export enum Workflow {
  DIAGNOSTICS_INSTOCK_PERCENT_CHANGE = 'DIAGNOSTICS_INSTOCK_PERCENT_CHANGE',
  DIAGNOSTICS_LOCATION_COUNT_CHANGE = 'DIAGNOSTICS_LOCATION_COUNT_CHANGE',
  DIAGNOSTICS_MIX_SHIFT = 'DIAGNOSTICS_MIX_SHIFT',
  DIAGNOSTICS_PRICE_CHANGE = 'DIAGNOSTICS_PRICE_CHANGE',
  DIAGNOSTICS_PROMOTIONS = 'DIAGNOSTICS_PROMOTIONS',
  DIAGNOSTICS_SALES_PERFORMANCE = 'DIAGNOSTICS_SALES_PERFORMANCE',
  PROMOTION_ANALYSIS = 'PROMOTION_ANALYSIS',
  WALMART_NOVA_RECOMMENDATIONS = 'WALMART_NOVA_RECOMMENDATIONS',
}

export type ZendeskAuthentication = {
  readonly zendeskToken: string;
  readonly zendeskWidgetChatToken: string;
  readonly zendeskWidgetToken: string;
};

export namespace Extractors {
  export type Abs = {
    readonly type: 'abs';
  };
  export type AttributeColumn = {
    readonly attribute_name: string;
    readonly attribute_type: AttributeType;
    readonly identifier: boolean;
    readonly type: 'attribute';
  };
  export enum AttributeType {
    LOCATION = 'LOCATION',
    PRODUCT = 'PRODUCT',
  }
  export type CellCoordinates = {
    readonly column: number;
    readonly row: number;
  };
  export type ColumnMapping = {
    readonly column_name: string;
    readonly output: OutputColumn;
  };
  export type ColumnRef =
    | RandomAccessCell
    | NamedColumn
    | ConstColumn
    | FileDateColumn
    | FilenameColumn;
  export type ColumnSelection = {
    readonly input_column: ColumnRef;
    readonly output_column_name: string;
    readonly transforms: ReadonlyArray<ColumnTransform>;
  };
  export type ColumnTransform =
    | Abs
    | ExtractBefore
    | IsEmpty
    | ParseCountry
    | ParseCurrency
    | ParseDate
    | ParseEAN
    | ParseFloat
    | ParseGTIN14
    | ParsePostalArea
    | ParsePostalCode
    | ParseRegex
    | ParseUPC
    | Strip;
  export type ConstColumn = {
    readonly type: 'const';
    readonly value: string | number;
  };
  export enum DateFormatType {
    DMY = 'DMY',
    MDY = 'MDY',
    YDM = 'YDM',
    YMD = 'YMD',
  }
  export enum DateGranularity {
    AS_REPORTED = 'AS_REPORTED',
    DAILY = 'DAILY',
    FRIDAY = 'FRIDAY',
    MONDAY = 'MONDAY',
    NO_DATES = 'NO_DATES',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
    THURSDAY = 'THURSDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
  }
  export type ExtractBefore = {
    readonly separator: string;
    readonly type: 'extract_before';
  };
  export type ExtractionRequest = {
    readonly extractor: ExtractorConfig;
    readonly files: ReadonlyArray<InputFile>;
  };
  export type ExtractionResponse = {
    readonly results: ReadonlyArray<ExtractionResult>;
  };
  export type ExtractionResult = FailedExtractionResult | SuccessfulExtractionResult;
  export type ExtractorConfig = ExtractorDefinition | NamedExtractor;
  export type ExtractorDefinition = {
    readonly date_granularity: DateGranularity;
    readonly name: string;
    readonly spec_version: string;
    readonly stages: ReadonlyArray<Stage>;
    readonly type: 'inline';
  };
  export type ExtractorError = {
    readonly detail: string;
  };
  export type FailedExtractionResult = {
    readonly errors: ReadonlyArray<ExtractorError>;
    readonly input_file_size: FileSize | null;
    readonly input_file_url: string;
    readonly success: boolean;
  };
  export type FileDateColumn = {
    readonly type: 'filedate';
  };
  export type FileSize = {
    readonly bytes: number;
    readonly rows: number | null;
  };
  export type FilenameColumn = {
    readonly type: 'filename';
  };
  export type FilterStage = {
    readonly conditions: ReadonlyArray<PredicateCondition>;
    readonly connective: LogicalConnective;
    readonly negate: boolean;
    readonly type: 'filter';
  };
  export type HTTPValidationError = {
    readonly detail: ReadonlyArray<ValidationError>;
  };
  export type InputFile = {
    readonly url: string;
  };
  export type IsEmpty = {
    readonly type: 'is_empty';
  };
  export enum LogicalConnective {
    and = 'and',
    or = 'or',
  }
  export type MapStage = {
    readonly aggregate_values: boolean;
    readonly mapping: ReadonlyArray<ColumnMapping>;
    readonly type: 'map';
  };
  export type MetricColumn = {
    readonly metric_name: string;
    readonly type: 'metric';
  };
  export type NamedColumn = {
    readonly name: string;
    readonly type: 'col';
  };
  export type NamedExtractor = {
    readonly name: string;
    readonly type: 'named';
  };
  export type OutputColumn = AttributeColumn | MetricColumn | StandardColumn;
  export type ParseCountry = {
    readonly type: 'parse_country';
  };
  export type ParseCurrency = {
    readonly type: 'parse_currency';
  };
  export type ParseDate = {
    readonly format: DateFormatType;
    readonly type: 'parse_date';
  };
  export type ParseEAN = {
    readonly skip_invalid_gtin: boolean;
    readonly type: 'parse_ean';
  };
  export type ParseFloat = {
    readonly decimal_separator: string;
    readonly default: number | null;
    readonly ignore_junk: boolean;
    readonly invert_parentheses: boolean;
    readonly positive_values_only: boolean;
    readonly thousands_separator: string | null;
    readonly type: 'parse_float';
  };
  export type ParseGTIN14 = {
    readonly skip_invalid_gtin: boolean;
    readonly type: 'parse_gtin14';
  };
  export type ParsePostalArea = {
    readonly country: ColumnRef;
    readonly type: 'parse_postal_area';
  };
  export type ParsePostalCode = {
    readonly country: ColumnRef;
    readonly type: 'parse_postal_code';
  };
  export type ParseRegex = {
    readonly regex: string;
    readonly type: 'parse_regex';
  };
  export type ParseUPC = {
    readonly skip_invalid_gtin: boolean;
    readonly type: 'parse_upc';
  };
  export type PredicateCondition = {
    readonly input_column: ColumnRef;
    readonly transforms: ReadonlyArray<ColumnTransform>;
    readonly type: 'predicate';
  };
  export type RandomAccessCell = {
    readonly offset: CellCoordinates;
    readonly pattern: string;
    readonly type: 'cell';
  };
  export type SelectStage = {
    readonly selections: ReadonlyArray<ColumnSelection>;
    readonly type: 'select';
  };
  export type Stage = FilterStage | MapStage | SelectStage;
  export type StandardColumn = {
    readonly field_name: string;
    readonly type: 'standard';
  };
  export type Strip = {
    readonly left: boolean;
    readonly right: boolean;
    readonly type: 'strip';
    readonly value: string;
  };
  export type SuccessfulExtractionResult = {
    readonly errors: ReadonlyArray<ExtractorError>;
    readonly input_file_size: FileSize;
    readonly input_file_url: string;
    readonly output_file_size: FileSize;
    readonly output_file_url: string;
    readonly success: boolean;
  };
  export type ValidationError = {
    readonly loc: ReadonlyArray<string | number>;
    readonly msg: string;
    readonly type: string;
  };
}
