import React, {MutableRefObject, RefCallback} from 'react';

export function mergeRefs<T>(
  ...refs: ReadonlyArray<RefCallback<T> | MutableRefObject<T> | null | undefined>
): React.RefCallback<T> {
  return (val: T) => {
    setRef(val, ...refs);
  };
}

export function setRef<T>(
  val: T,
  ...refs: ReadonlyArray<RefCallback<T> | MutableRefObject<T> | null | undefined>
): void {
  refs.forEach(ref => {
    if (typeof ref === 'function') {
      ref(val);
    } else if (ref) {
      ref.current = val;
    }
  });
}
