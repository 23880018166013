import './Dropdown.scss';

import classNames from 'classnames';
import React, {Component, useCallback, useEffect, useRef, useState} from 'react';
import TetherComponent from 'react-tether';

import {swallowEvents} from 'toolkit/utils/events';
import {useRootClosePortalAware} from 'toolkit/utils/react-bootstrap';
import useForceUpdate from 'toolkit/utils/useForceUpdate';

type TetherProps = TetherComponent extends Component<infer P> ? P : never;
type Constraints = TetherProps['constraints'];

const DEFAULT_OFFSET = '-2px 0';

const defaultTetherComponentConstraints: Constraints = [
  {to: 'window', attachment: 'together', pin: true},
];

const dropdownBorderWidthPx = 1;

const DropdownContent: React.FC<DropdownContentProps> = ({
  children,
  className,
  contentRef,
  id,
  onRootClose,
  rootCloseDisabled,
  minWidth,
  width,
}) => {
  useRootClosePortalAware(contentRef, onRootClose, {disabled: !!rootCloseDisabled});
  return (
    <div
      ref={contentRef}
      className={classNames('dropdown-content', className)}
      id={id}
      style={{minWidth, width}}
    >
      {children}
    </div>
  );
};

interface DropdownContentProps {
  children: React.ReactNode;
  className?: string;
  contentRef: React.RefObject<HTMLDivElement>;
  id?: string;
  rootCloseDisabled?: boolean;
  onRootClose: (event: Event) => void;
  minWidth: number | undefined;
  width?: number | string;
}

function getOffset(
  attachment: string,
  targetAttachment: string,
  isFloating: boolean,
  floatAmount: number
) {
  if (!isFloating) {
    return DEFAULT_OFFSET;
  }

  const [verticalAttachment, horizontalAttachment] = attachment.split(' ');
  const [verticalTargetAttachment, horizontalTargetAttachment] = targetAttachment.split(' ');

  if (
    verticalAttachment === verticalTargetAttachment &&
    ['left', 'right'].includes(horizontalTargetAttachment) &&
    horizontalAttachment !== horizontalTargetAttachment
  ) {
    return horizontalTargetAttachment === 'left' ? `0 ${floatAmount}px` : `0 -${floatAmount}px`;
  } else if (
    horizontalAttachment === horizontalTargetAttachment &&
    ['top', 'bottom'].includes(verticalTargetAttachment) &&
    verticalAttachment !== verticalTargetAttachment
  ) {
    return verticalTargetAttachment === 'top' ? `${floatAmount}px 0` : `-${floatAmount}px 0`;
  } else {
    return DEFAULT_OFFSET;
  }
}

function Dropdown<R extends HTMLElement>({
  attachment = 'top left',
  constraints,
  className,
  dropdownContentClassName,
  dropdownContentId,
  matchWidth,
  width,
  renderDropdownContent,
  renderTarget,
  isOpen,
  onClose,
  onRootClose,
  targetAttachment = 'bottom left',
  rootCloseDisabled,
  isFloating,
  floatOffsetPx = 8,
}: Props<R>) {
  const handleRootClose = useCallback(
    (e: Event) => {
      if (!onRootClose || onRootClose(e)) {
        swallowEvents(e);
        onClose();
      }
    },
    [onClose, onRootClose]
  );

  const [isVisible, setIsVisible] = useState(false);
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsVisible(true);
        // force async update as height of the dropdown may initially have the wrong dimension (e.g. DatePicker)
        // hide dropdown before to avoid flashes
        forceUpdate();
        // note: relying on dropdown-open is not safe as it gives incorrect results for nested dropdowns or multiple dropdowns open
        // it's only used for hotkeys
        document.body.classList.add('dropdown-open');
      }, 0);
    } else {
      setIsVisible(false);
      document.body.classList.remove('dropdown-open');
    }
    return () => {
      setIsVisible(false);
      document.body.classList.remove('dropdown-open');
    };
  }, [forceUpdate, isOpen]);

  const targetRef = useRef<R>();
  const targetRenderer = (ref: React.RefObject<R>) => {
    const renderedTarget = renderTarget(ref);
    targetRef.current = ref.current ?? undefined;
    return renderedTarget;
  };
  const tetherRef = useRef<TetherComponent>(null);
  const reposition = useCallback(() => setTimeout(() => tetherRef.current?.position(), 0), []);

  const renderTetherContent = (ref: React.RefObject<any>) => {
    return (
      isOpen && (
        <DropdownContent
          className={dropdownContentClassName}
          contentRef={ref}
          id={dropdownContentId}
          minWidth={
            matchWidth && targetRef.current
              ? targetRef.current.clientWidth - 1 * dropdownBorderWidthPx
              : undefined
          }
          rootCloseDisabled={rootCloseDisabled}
          width={width}
          onRootClose={handleRootClose}
        >
          {renderDropdownContent(reposition)}
        </DropdownContent>
      )
    );
  };

  return (
    <TetherComponent
      ref={tetherRef}
      attachment={attachment!}
      className={classNames('tether-component-dropdown-wrapper', className, {
        'is-visible': isVisible,
      })}
      constraints={constraints || defaultTetherComponentConstraints}
      offset={getOffset(attachment!, targetAttachment!, !!isFloating, floatOffsetPx!)}
      renderElement={renderTetherContent}
      // TS doesn't infer that <R extends HtmlElement> is a subtype of <Element> for some reason;
      // it says that 'R' could be instantiated with a different subtype of constraint HTMLElement.
      // Do an unsafe cast here to isolate this problem to Dropdown instead of having callers deal
      // with it.
      renderTarget={targetRenderer as (ref: React.RefObject<Element>) => React.ReactNode}
      targetAttachment={targetAttachment}
    />
  );
}

Dropdown.displayName = 'Dropdown';
export interface AttachmentProps {
  attachment?: string;
  targetAttachment?: string;
}
interface Props<R extends HTMLElement> extends AttachmentProps {
  className?: string;
  dropdownContentClassName?: string;
  dropdownContentId?: string;
  isOpen: boolean;
  renderDropdownContent: (onResize?: () => void) => React.ReactNode;
  renderTarget: (ref: React.RefObject<R> | undefined) => React.ReactNode;
  onClose: () => void;
  onRootClose?: (e: Event) => boolean;
  rootCloseDisabled?: boolean;
  matchWidth?: boolean;
  width?: number | string;
  constraints?: Constraints;
  isFloating?: boolean;
  floatOffsetPx?: number;
}
export default Dropdown;
