import React from 'react';

export function stopPropagation(event: React.SyntheticEvent<EventTarget>) {
  event.stopPropagation();
}

export function withStopPropagation(callback: () => void) {
  return (event: React.SyntheticEvent) => {
    stopPropagation(event);
    callback();
  };
}

export function swallowEvents(event: React.SyntheticEvent<EventTarget> | Event) {
  event.preventDefault();
  event.stopPropagation();
  const nativeEvent = 'nativeEvent' in event ? event.nativeEvent : event;
  nativeEvent.stopImmediatePropagation();
}

export function forceResize() {
  window.dispatchEvent(new Event('resize'));
}

export function isPrevItemEvent(event: React.KeyboardEvent<HTMLElement>) {
  return event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey);
}

export function isNextItemEvent(event: React.KeyboardEvent<HTMLElement>) {
  return event.key === 'ArrowDown' || (event.key === 'Tab' && !event.shiftKey);
}

function isListNavigationEvent(event: React.KeyboardEvent<HTMLElement>) {
  return isPrevItemEvent(event) || isNextItemEvent(event);
}

function focusItemByPredicate(
  event: React.KeyboardEvent<HTMLElement>,
  getElementToFocus: (event: React.KeyboardEvent<HTMLElement>) => Element | null
): boolean {
  if (!isListNavigationEvent(event)) {
    return false;
  }

  const element = getElementToFocus(event);
  if (element) {
    (element as HTMLElement).focus();
    swallowEvents(event);
    return true;
  }
  return false;
}

function focusElementInContainerWithClassName(
  event: React.KeyboardEvent<HTMLElement>,
  elementContainer: HTMLElement | null,
  elementClassName: string
) {
  if (!elementContainer) {
    return false;
  }

  return focusItemByPredicate(event, event => {
    const matchingElements = elementContainer.querySelectorAll(`.${elementClassName}`);
    const element = isNextItemEvent(event)
      ? matchingElements[0]
      : matchingElements[matchingElements.length - 1];
    return element;
  });
}

export function focusAdjacentItemWithClassName(
  event: React.KeyboardEvent<HTMLElement>,
  elementClassName: string,
  getContainerElement?: () => HTMLElement | null | undefined
): boolean {
  return focusItemByPredicate(event, event => {
    const element = isNextItemEvent(event)
      ? event.currentTarget.nextElementSibling
      : event.currentTarget.previousElementSibling;

    const sibling = element?.className?.includes(elementClassName) ? element : null;
    if (sibling) {
      return sibling;
    }

    // the sibling did not exist or was not of the correct class. This could happen with
    // e.g. settings list items and section titles. Try to find the nearest matching one.
    const containerElement = getContainerElement
      ? getContainerElement()
      : event.currentTarget.parentElement;

    if (!containerElement) {
      return null;
    }

    const matchingElemsFromParent = containerElement.querySelectorAll(`.${elementClassName}`);

    // eslint-disable-next-line fp/no-let, @typescript-eslint/prefer-for-of
    for (let i = 0; i < matchingElemsFromParent.length; ++i) {
      if (matchingElemsFromParent[i] === event.currentTarget) {
        if (isNextItemEvent(event)) {
          return i < matchingElemsFromParent.length - 1 ? matchingElemsFromParent[i + 1] : null;
        } else {
          return i > 0 ? matchingElemsFromParent[i - 1] : null;
        }
      }
    }
    return null;
  });
}

export function handleSearchInputKeyNavigation(
  event: React.KeyboardEvent<HTMLElement>,
  elementContainer: HTMLElement | undefined | null,
  elementClassName: string
) {
  if (!elementContainer) {
    return false;
  }

  if (focusElementInContainerWithClassName(event, elementContainer, elementClassName)) {
    return true;
  } else if (event.key === 'Enter') {
    // select the topmost item
    const matchingElements = elementContainer.querySelectorAll(`.${elementClassName}`);
    if (matchingElements.length > 0 && matchingElements[0] instanceof HTMLElement) {
      matchingElements[0].dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          metaKey: event.metaKey,
          shiftKey: event.shiftKey,
          ctrlKey: event.ctrlKey,
        })
      );
      return true;
    }
  }

  return false;
}

export function handleItemKeyNavigation(
  event: React.KeyboardEvent<HTMLElement>,
  elementClassName: string,
  onUnhandledKeyDown: (event: React.KeyboardEvent<HTMLElement>) => void,
  getContainerElement?: () => HTMLElement | null | undefined
) {
  const hasSpecialKeyDown = event.ctrlKey || event.metaKey; // alt + shift can be used for typing
  if (focusAdjacentItemWithClassName(event, elementClassName, getContainerElement)) {
    return true;
  } else if ((event.key === 'Enter' || event.key === ' ') && !hasSpecialKeyDown) {
    event.currentTarget.click();
    return true;
  } else if (hasSpecialKeyDown) {
    return false;
  }

  onUnhandledKeyDown?.(event);

  return false;
}
