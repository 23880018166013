import {useState, useCallback} from 'react';

export default function useBoolean(
  initialValue = false
): [value: boolean, setTrue: () => void, setFalse: () => void, toggle: () => void] {
  const [state, setState] = useState(initialValue);
  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);
  const toggle = useCallback(() => setState(state => !state), []);

  return [state, setTrue, setFalse, toggle];
}
